import * as React from 'react'
import { css } from 'glamor'

import InstrumentCell from './InstrumentCell'

import { State as MarketDataState } from 'reducers/market-data'
import { Instrument, instrumentTypeName} from 'types/classes-instrument'
import { Colors } from 'types/colors'

interface Props {
  instruments: Dictionary<Instrument[]>
  positionsData: AccountReportDetailedType | null
  marketData: MarketDataState,
  selectedInstrument: Instrument | null
  onClickInstrument: (nstrument: Instrument) => void
}

export default class NormalInstrumentList extends React.PureComponent<Props> {

  positionForInstrument = (instrument: Instrument) => {
    const { positionsData } = this.props
    if (positionsData) {
      return positionsData.positionFor(instrument.ticker)
    }
    return undefined
  }

  section = (instrumentType: any, instruments: Instrument[], marketData: MarketDataState, key: number) => {
    const { selectedInstrument, onClickInstrument } = this.props
    return (
      <div className="section" key={key}>
        <div className="row header">
          {instrumentTypeName(instrumentType)}
        </div>
        {instruments.map((instrument, i) => (
          <InstrumentCell
            instrument={instrument}
            marketData={marketData.all[instrument.symbol]}
            selected={selectedInstrument !== null && selectedInstrument!.symbol === instrument.symbol}
            position={this.positionForInstrument(instrument)}
            key={i}
            onClick={onClickInstrument}
          />))
        }
      </div>
    )
  }

  render() {
    const { instruments, marketData } = this.props
    return (
      <div className="list" {...style}>
        {Object.keys(instruments).map((instrumentType, i) => 
          this.section(instrumentType, instruments[instrumentType], marketData, i))
        }
      </div>
    )
  }
}

const style = css({
  '& > .section > .header': {
    color: Colors.lightGray,
    backgroundColor: Colors.darkViolet,
    padding: '6px 12px',
    borderStyle: 'none none solid none',
    borderBottomWidth: '1pt',
    borderColor: Colors.darkLineViolet,
  }
})