import * as React from 'react';

export class HomeIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path d="m15.6437333 7-1.7770666-5h-1.0666667v-2h-9.6v2h-1.06666667l-2.13333333 6h1.06666667v4 1 3h13.86666663v-3-1-4h1.0666667zm-11.37706663-5.999h7.46666663v1h-3.19999997-1.06666666-3.2zm5.5776 2 1.38773333 3.999h-2.69866667v-3.999zm-2.3776 0v3.999h-2.69653334l1.3856-3.999zm-4.62186667-.001h.3552v.001h1.63093333l-1.38666666 3.999h-2.0224zm11.0218667 12h-11.73333337v-2h11.73333337zm0-3h-11.73333337v-4h5.33333334v.001h1.06666666v-.001h5.33333337zm-1.3109334-5-1.3866666-3.999h1.6309333v-.001h.3562667l1.4208 4z" fill="#fff" fillRule="evenodd"/>
      </svg>
    )
  }
}

export class AccountIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path d="m15.1405083 11.97c-.4502354-.402-4.5386288-1.271-5.32387351-1.437 1.84468481-.951 2.98627691-3.257 2.98627691-5.429 0-2.819-.8727311-5.104-4.80428902-5.104-3.93049096 0-4.80428903 2.285-4.80428903 5.104 0 2.18 1.15012737 4.493 3.00654819 5.438-.90047079.186-4.87150428 1.019-5.33347566 1.428-.82792101.732-1.24508224 2.564-.41716123 3.663 0 0 1.00609473.367 7.58145189.367 6.57642406 0 7.51957116-.367 7.51957116-.367.8236534-1.099.4107598-2.931-.4107597-3.663zm-.7393676 3.029-6.47400089-.015s-6.32676748.015-6.3289013.015c-1.16933173-.423-.5761306-2.145.20377953-2.499.64227892-.292 3.62535512-1.078 4.78721849-1.38l.21338171-.986v-.42c-1.57795769-.713-2.53710845-2.745-2.53710845-4.592 0-2.278.62200767-4.126 3.73204598-4.126 3.11110523 0 3.73204603 1.848 3.73204603 4.126 0 1.847-.9559501 3.879-2.53604158 4.592v.42l.22938533 1.05c1.43605885.337 4.37859255 1.043 4.97819515 1.316.7777763.353 1.0252991 2.134 0 2.499z" fill="#fff" fillRule="evenodd"/>
      </svg>
    )
  }
}

export class OrdersIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
        <path d="m6.45454545 15.6853147c-.75164247 0-1.36713286-.6018128-1.36713286-1.3519814 0-.7501685.61549039-1.3519813 1.36713286-1.3519813.75164248 0 1.36713287.6018128 1.36713287 1.3519813 0 .7501686-.61549039 1.3519814-1.36713287 1.3519814zm7.50000005 0c-.7516425 0-1.3671329-.6018128-1.3671329-1.3519814 0-.7501685.6154904-1.3519813 1.3671329-1.3519813.7516424 0 1.3671328.6018128 1.3671328 1.3519813 0 .7501686-.6154904 1.3519814-1.3671328 1.3519814zm-10.71689526-14.0853147h-2.23765024c-.33137085 0-.6-.26862915-.6-.6s.26862915-.6.6-.6h2.72727273c.28499096 0 .53065927.20047454.58781132.47967607l.58413833 2.85365726h11.10077762c.3774593 0 .6611609.34437962.5889037.71485824l-1.0918217 5.59791743c-.1867841.9195019-1.0151824 1.5715434-1.9516275 1.553891l-6.61603189-.0001053c-.94768585.0177577-1.77608412-.6342838-1.96268848-1.5529041l-1.1394001-5.5662435c-.00440423-.01743511-.00804519-.03517333-.01088538-.05317769zm1.9072099 3.33333333.99767642 4.87389105c.07058444.34747342.39125024.59987302.77564526.59277562l6.63851348.0001053c.3731543.0069921.6938201-.2454075.7634919-.58829687l.951485-4.8784751z" fill="#fff"/>
      </svg>
    )
  }
}

export class HelpIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
        <path d="m3.86406797 17.0551708c1.64208772 1.4293468 3.78793775 2.2948292 6.13593203 2.2948292 2.3479943 0 4.4938443-.8654824 6.135932-2.2948292l-3.339597-3.339597c-.7782468.586634-1.7466753.9344262-2.796335.9344262-1.04965971 0-2.01808818-.3477922-2.79633495-.9344262zm-.91923882-.9192388 3.33959708-3.339597c-.58663399-.7782468-.93442623-1.7466753-.93442623-2.796335 0-1.04965971.34779224-2.01808818.93442623-2.79633495l-3.33959708-3.33959708c-1.42934674 1.64208772-2.29482915 3.78793775-2.29482915 6.13593203 0 2.3479943.86548241 4.4938443 2.29482915 6.135932zm10.77074465-8.93226695c.586634.77824677.9344262 1.74667524.9344262 2.79633495 0 1.0496597-.3477922 2.0180882-.9344262 2.796335l3.339597 3.339597c1.4293468-1.6420877 2.2948292-3.7879377 2.2948292-6.135932 0-2.34799428-.8654824-4.49384431-2.2948292-6.13593203l-.2355514.23555144zm-.9192388-.91923882 3.1040456-3.10404564.2355514-.23555144c-1.6420877-1.42934674-3.7879377-2.29482915-6.135932-2.29482915-2.34799428 0-4.49384431.86548241-6.13593203 2.29482915l3.33959708 3.33959708c.77824677-.58663399 1.74667524-.93442623 2.79633495-.93442623 1.0496597 0 2.0180882.34779224 2.796335.93442623zm-2.796335 14.36557377c-5.88183259 0-10.65-4.7681674-10.65-10.65 0-5.88183259 4.76816741-10.65 10.65-10.65 5.8818326 0 10.65 4.76816741 10.65 10.65 0 5.8818326-4.7681674 10.65-10.65 10.65zm0-7.3c1.8501539 0 3.35-1.4998461 3.35-3.35 0-1.85015391-1.4998461-3.35-3.35-3.35-1.85015391 0-3.35 1.49984609-3.35 3.35 0 1.8501539 1.49984609 3.35 3.35 3.35z" fill="#fff" transform="translate(1 1)"/>
      </svg>
    )
  }
}

export class SearchIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
        <path d="m13.3616883 12.1046096 3.2668511 3.266851c.3471327.3471327.3471327.9099461 0 1.2570788s-.9099461.3471327-1.2570788 0l-3.266851-3.2668511c-1.3685963 1.0947803-3.10459741 1.7494228-4.99349849 1.7494228-4.418278 0-8-3.581722-8-7.99999999 0-4.418278 3.581722-8 8-8 4.41827799 0 7.99999999 3.581722 7.99999999 8 0 1.88890108-.6546425 3.62490219-1.7494228 4.99349849zm-1.7693452-.6767064c1.078036-1.1188411 1.7409902-2.64039595 1.7409902-4.31679209 0-3.43643844-2.7857837-6.22222222-6.22222219-6.22222222-3.43643844 0-6.22222222 2.78578378-6.22222222 6.22222222 0 3.43643849 2.78578378 6.22222219 6.22222222 6.22222219 1.67639614 0 3.19795099-.6629542 4.31679209-1.7409902.0233931-.0304243.0490233-.0596817.0768908-.0875491.0278674-.0278675.0571248-.0534977.0875491-.0768908z" fill="#fff" transform="translate(1 1)"/>
      </svg>
    )
  }
}

export class OpenWatchlistIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="25" viewBox="0 0 31 25" width="31" xmlns="http://www.w3.org/2000/svg">
        <path d="m219.038957 217.599485c-.047801-.040247-.091314-.087169-.129129-.140526l-2.521739-3.558236c-.239506-.337949-.159703-.806069.178247-1.045575.337949-.239507.806069-.159703 1.045575.178246l2.387403 3.368684c1.137657-1.278336 2.524603-2.41235 4.031241-3.32112l-1.725496-3.838768c-.169819-.377802-.001215-.821736.376587-.991555s.821736-.001216.991556.376586l1.676999 3.730876c1.648895-.814947 3.389551-1.353982 5.070254-1.522372v-3.835725c0-.414214.335786-.75.75-.75.414213 0 .75.335786.75.75v3.786782c1.887745.069686 3.87443.630337 5.742844 1.546199l1.663548-3.699396c.169878-.377776.613839-.54631.991614-.376431.377776.169878.54631.613839.376431.991614l-1.710107 3.802938c1.510217.9053 2.900379 2.037932 4.042017 3.317602l2.361253-3.332336c.23948-.337968.707594-.417809 1.045562-.178329s.417808.707594.178328 1.045562l-2.521739 3.558823c-.028788.040627-.060879.077524-.095651.110585.598169.832057 1.088959 1.709918 1.443052 2.615261.05155.131802.063259.269355.040947.399361.022312.130007.010603.26756-.040947.399362-2.013939 5.149222-8.449848 9.409462-13.91365 9.409462-5.488721 0-12.015761-4.349791-13.964028-9.541996-.000458-.001202-.000913-.002405-.001364-.00361-.066704-.178006-.061398-.365361.001364-.530045.353473-.942019.857664-1.856309 1.479028-2.721923zm24.942694 2.98875c-1.853051-4.502182-7.655336-8.308823-12.457694-8.308823-4.781909 0-10.609816 3.823639-12.457258 8.308823 1.847442 4.485185 7.675349 8.308824 12.457258 8.308824 4.802358 0 10.604643-3.806642 12.457694-8.308824zm-8.579477 0c0 2.196437-1.742898 3.985294-3.902174 3.985294s-3.902174-1.788857-3.902174-3.985294 1.742898-3.985294 3.902174-3.985294 3.902174 1.788857 3.902174 3.985294z" fill="#fff" transform="translate(-216.005 -206)"/>
      </svg>
    )
  }
}

export class CloseWatchlistIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="16" viewBox="0 0 31 16" width="31" xmlns="http://www.w3.org/2000/svg">
        <path d="m3.62044508 5.21462373-2.00850019 2.83451117c-.2394799.33796783-.70759378.41780807-1.0455616.17832817-.33796783-.2394799-.41780808-.70759377-.17832818-1.0455616l2.29644228-3.24087164c-.46127373-.71057899-.84253828-1.44995012-1.12635947-2.20722728-.14536925-.38786681.05121386-.82014003.43908066-.96550929.38786681-.14536925.82014004.05121386.96550929.43908066 1.72256868 4.5960697 7.68280293 8.56890056 12.55619863 8.56890056 1.9546486 0 4.0750149-.63085908 6.0271357-1.68264362.0690945-.07959292.1561521-.14581593.2585969-.1918834.060468-.02719131.1226318-.0457124.1850958-.05609297 2.7065966-1.56781956 5.0300241-3.93651747 6.0412919-6.52211841.1508759-.38575815.5859037-.57616785.9716618-.42529198.3857582.15087587.5761679.58590369.425292.97166184-.2829873.72353969-.6533113 1.42952094-1.0959971 2.10858467l2.269898 3.20341086c.2394799.33796783.1596396.8060817-.1783282 1.0455616s-.8060817.15963966-1.0455616-.17832817l-1.9835246-2.79926427c-1.17834 1.42606205-2.6676004 2.68900639-4.3026338 3.68561724l1.5950699 3.54711623c.1698783.3777754.0013444.8217364-.376431.9916148-.3777753.1698783-.8217364.0013444-.9916147-.376431l-1.5438993-3.43332311c-1.9005066.94946211-3.9288929 1.53226051-5.8545237 1.60425011v3.4619853c0 .4142136-.3357864.75-.75.75-.4142135 0-.75-.3357864-.75-.75v-3.510253c-1.8974028-.1893388-3.8714274-.8513881-5.70359968-1.84973047l-1.67599219 3.72707117c-.16987831.3777754-.61383935.5463093-.99161472.376431-.37777538-.1698784-.54630924-.6138394-.37643093-.9916148l1.74890773-3.88922074c-1.43869535-.94665611-2.74616684-2.09812194-3.80127963-3.37875963z" fill="#fff" transform="translate(-.005)"/>
      </svg>
    )
  }
}

export class BookUpIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
        <path d="m1386 855.25 3-3.75h-6zm4.5-8.25h-9c-.828 0-1.5.672-1.5 1.5v9c0 .828.672 1.5 1.5 1.5h9c.82875 0 1.5-.672 1.5-1.5v-9c0-.828-.67125-1.5-1.5-1.5zm.75 10.5c0 .41475-.33525.75-.75.75h-9c-.414 0-.75-.33525-.75-.75v-9c0-.414.336-.75.75-.75h9c.41475 0 .75.336.75.75z" fill="#fff" fillRule="evenodd" transform="matrix(-1 0 0 -1 1392 859)"/>
      </svg>
    )
  }
}

export class ArrowRightIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="13" viewBox="0 0 10 13" width="10" xmlns="http://www.w3.org/2000/svg">
        <path d="m9.67310821 7.1542421-8.44437319 5.6808431c-.3613547.2430967-.85135938.1472297-1.09445609-.214125-.08752815-.1301075-.13427893-.2833563-.13427893-.4401656v-11.36168616c0-.4355149.35305459-.78856949.78856949-.78856949.15680923 0 .31005803.04675079.44016553.13427893l8.44437319 5.6808431c.36135469.24309671.45722169.73310139.21412502 1.09445609-.05687172.08453782-.12958721.1572533-.21412502.21412503z" fill="#fff" fillRule="evenodd"/>
      </svg>
    )
  }
}

export class ArrowLeftIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="13" viewBox="0 0 10 13" width="10" xmlns="http://www.w3.org/2000/svg">
        <path d="m597.673108 132.154242-8.444373 5.680843c-.361355.243097-.851359.14723-1.094456-.214125-.087528-.130107-.134279-.283356-.134279-.440165v-11.361687c0-.435514.353055-.788569.788569-.788569.15681 0 .310059.046751.440166.134279l8.444373 5.680843c.361355.243097.457222.733101.214125 1.094456-.056871.084538-.129587.157253-.214125.214125z" fill="#fff" fillRule="evenodd" transform="matrix(-1 0 0 1 598 -125)"/>
      </svg>
    )
  }
}

export class TrashIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="28" viewBox="0 0 22 28" width="22" xmlns="http://www.w3.org/2000/svg">
        <path d="m25 11c0-.5522847.4477153-1 1-1s1 .4477153 1 1v18.1858169c0 .010969 0 .010969-.0049628.1126943-.1548312 1.5483123-1.2680537 2.7014888-2.8050123 2.7014888h-12.1900249c-1.5417353 0-2.8417218-1.1683349-3-2.8009926v-18.1990074c0-.5522847.44771525-1 1-1 .5522847 0 1 .4477153 1 1l-.0049628 18.0995037c.0507768.5077686.4877583.9004963 1.0049628.9004963h12.1900249c.4466658 0 .7570396-.3215133.8099751-.8009926zm-10-5c0-1.1045695.8954305-2 2-2h2c1.1045695 0 2 .8954305 2 2h7c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-20c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1zm7 10c.5522847 0 1 .4477153 1 1v6c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-6c0-.5522847.4477153-1 1-1zm-8 0c.5522847 0 1 .4477153 1 1v6c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-6c0-.5522847.4477153-1 1-1zm4 0c.5522847 0 1 .4477153 1 1v6c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-6c0-.5522847.4477153-1 1-1z" fill="#fff" transform="translate(-7 -4)"/>
      </svg>
    )
  }
}

export class UnlockIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="38" viewBox="0 0 35 38" width="35" xmlns="http://www.w3.org/2000/svg">
        <path d="m830.1746 159.428571.000004-5.141126c-.005337-5.286161 4.011816-9.713523 9.288489-10.244064 5.275542-.530427 10.098983 3.006353 11.155488 8.185397.189241.927668-.409373 1.833102-1.337041 2.022343-.927669.189241-1.833103-.409373-2.022343-1.337042-.704198-3.452017-3.925473-5.814011-7.453109-5.459326-3.526507.354571-6.206469 3.308196-6.202913 6.830962l-.000004 5.142856h18.968258c2.840321 0 5.142857 2.302536 5.142857 5.142858v12c0 2.840321-2.302536 5.142857-5.142857 5.142857h-24.142858c-2.840321 0-5.142857-2.302536-5.142857-5.142857v-12c0-2.840322 2.302536-5.142858 5.142857-5.142858zm-1.746029 3.428572c-.946773 0-1.714285.767512-1.714285 1.714286v12c0 .946773.767512 1.714285 1.714285 1.714285h24.142858c.946773 0 1.714285-.767512 1.714285-1.714285v-12c0-.946774-.767512-1.714286-1.714285-1.714286z" fill="#fff" transform="translate(-823 -144)"/>
      </svg>
    )
  }
}

export class LogOutIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
        <path d="m1414.31005 35.7h-8.97672c-.3866 0-.7-.3134007-.7-.7s.3134-.7.7-.7h8.97672l-3.24947-3.2494697c-.27337-.273367-.27337-.7165825 0-.9899495s.71658-.273367.98995 0l4.44444 4.4444445c.27337.273367.27337.7165824 0 .9899494l-4.44444 4.4444445c-.27337.273367-.71658.273367-.98995 0s-.27337-.7165825 0-.9899495zm-8.97672 6.6c.3866 0 .7.3134007.7.7s-.3134.7-.7.7h-3.55555c-1.36844 0-2.47778-1.1093389-2.47778-2.4777778v-12.4444444c0-1.3684389 1.10934-2.4777778 2.47778-2.4777778h3.55555c.3866 0 .7.3134007.7.7s-.3134.7-.7.7h-3.55555c-.59524 0-1.07778.4825375-1.07778 1.0777778v12.4444444c0 .5952403.48254 1.0777778 1.07778 1.0777778z" fill="#fff" transform="translate(-1399 -26)"/>
      </svg>
    )
  }
}

export class ExpandIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
        <path d="m560.414214 44h3.585786c.552285 0 1 .4477153 1 1s-.447715 1-1 1h-6c-.552285 0-1-.4477153-1-1v-6c0-.5522847.447715-1 1-1s1 .4477153 1 1v3.5857864l5.292893-5.2928932c.390525-.3905243 1.023689-.3905243 1.414214 0 .390524.3905243.390524 1.0236893 0 1.4142136zm13.171572-16h-3.585786c-.552285 0-1-.4477153-1-1s.447715-1 1-1h6c.552285 0 1 .4477153 1 1v6c0 .5522847-.447715 1-1 1s-1-.4477153-1-1v-3.5857864l-5.292893 5.2928932c-.390525.3905243-1.023689.3905243-1.414214 0-.390524-.3905243-.390524-1.0236893 0-1.4142136z" fill="#fff" transform="translate(-557 -26)"/>
      </svg>
    )
  }
}

export class CompactIcon extends React.PureComponent<React.HTMLAttributes<HTMLDivElement>> {
  render() {
    const { className } = this.props
    return (
      <svg className={className} height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
        <path d="m1402.58579 39h-3.58579c-.55228 0-1-.4477153-1-1s.44772-1 1-1h6c.55228 0 1 .4477153 1 1v6c0 .5522847-.44772 1-1 1s-1-.4477153-1-1v-3.5857864l-5.29289 5.2928932c-.39053.3905243-1.02369.3905243-1.41422 0-.39052-.3905243-.39052-1.0236893 0-1.4142136zm8.82842-6h3.58579c.55228 0 1 .4477153 1 1s-.44772 1-1 1h-6c-.55228 0-1-.4477153-1-1v-6c0-.5522847.44772-1 1-1s1 .4477153 1 1v3.5857864l5.29289-5.2928932c.39053-.3905243 1.02369-.3905243 1.41422 0 .39052.3905243.39052 1.0236893 0 1.4142136z" fill="#fff" transform="translate(-1397 -26)"/>
      </svg>
    )
  }
}