import * as React from 'react'
import { css } from 'glamor'

import { Colors } from 'types/colors'

import { Instrument } from 'types/classes-instrument'

interface Props {
  instrument: Instrument
  marketData?: MarketData
  onClick: (instrument: Instrument) => void
  isSelected: boolean
}

export default class IndexCell extends React.PureComponent<Props> {

  get returnColor() {
    return this.props.instrument.returnColor(this.props.marketData)
  }

  selectInstrument = () => {
    this.props.onClick(this.props.instrument)
  }
  
  render() {
    const selectedClass = this.props.isSelected ? "selected" : ""
    return (
      <div {...style} onClick={this.selectInstrument} className={`${this.returnColor} ${selectedClass}`}>
        <div className="name">{this.props.instrument.ticker}</div>
        <div className="price-data">
          <div className="price">
            {this.props.instrument.lastPriceStr(this.props.marketData)}
          </div>
          <div className={this.returnColor}>
            {this.props.instrument.dailyReturnStr(this.props.marketData)}
          </div>
        </div>
      </div>
    );
  }
}

const style = css({
  margin: '0 6px',
  display: 'flex',
  padding: '8px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minWidth: '100px',
  height: '100px',
  border: '1pt solid white',
  borderRadius: '4pt',
  color: Colors.white,
  background: Colors.darkViolet,

  '&.selected': {
    background: Colors.lightViolet,
  },

  '&.positive': {
    border: `1pt solid ${Colors.green}`
  },

  '&.negative': {
    border: `1pt solid ${Colors.red}`
  },

  '&:hover': {
    cursor: 'pointer',
    background: Colors.lightViolet
  },

  '& > .name': {
    color: Colors.lightGray,
    textAlign: 'left'
  },

  '& > .price-data > .price': {
    fontSize: '115%',
  },

  '& > .price-data > .positive': {
    color: Colors.green
  },

  '& > .price-data > .negative': {
    color: Colors.red
  }
})
