import * as React from 'react'
import { FocusEvent, ChangeEvent } from 'react'
import { css, keyframes } from 'glamor'

import { Loading } from 'components/Loading'
import { SearchIcon, CompactIcon, ExpandIcon } from 'components/Icons'
import Cell from './Cell'

import { Instrument } from 'types/classes-instrument'
import { Colors } from 'types/colors'
import { State as InstrumentsState } from 'reducers/instruments'
import { State as WatchlistState } from 'reducers/watchlist'
import { SortByArray } from 'utils/helpers/primary-helpers';

interface Props {
  instrumentsState: InstrumentsState
  watchlist: WatchlistState
  compact?: boolean
  height?: string
}

interface ActionCreators {
  selectInstrument: (instrument: Instrument) => any
  watchInstrument: (instrument: Instrument) => any
  onClickCompact?: () => any
}

interface State {
  text: string
  instruments: Instrument[]
  notFound: boolean
  onFocus: boolean
}

const levensteinDistance = (aString: string, bString: string) => {
  var a = aString, b = bString + "", m = [], i, j, min = Math.min;

  if (!(a && b)) return (b || a).length;

  for (i = 0; i <= b.length; m[i] = [i++]);
  for (j = 0; j <= a.length; m[0][j] = j++);

  for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
          m[i][j] = b.charAt(i - 1) == a.charAt(j - 1)
              ? m[i - 1][j - 1]
              : m[i][j] = min(
                  m[i - 1][j - 1] + 1, 
                  min(m[i][j - 1] + 1, m[i - 1 ][j]))
      }
  }
  return m[b.length][a.length];
}

const sortByLevensteinDistance = (values: Instrument[], query: string) => {
  return values.sort((a, b) => {
    if (levensteinDistance(query, a.ticker) < levensteinDistance(query, b.ticker) ) {
      return -1
    }
    return 1
  })
}

export default class Search extends React.PureComponent<Props & ActionCreators, State> {
  state: State = {
    text: '',
    instruments: [],
    notFound: false,
    onFocus: false,
  }

  emptyStateInstruments = () => {
    const { instrumentsState } = this.props
    if (instrumentsState.status === 'succeeded' && instrumentsState.data !== null) {
      const dolar = instrumentsState.data.futureInstruments.filter(i => i.segmentSubtype?.subtype === 'dolar')[0]
      const indiceRofex20 = instrumentsState.data.futureInstruments.filter(i => i.segmentSubtype?.subtype === 'indice-rofex-20')[0]
      const merval = instrumentsState.data.indexInstruments?.find(i => i.ticker === 'MERVAL')!
      const rfx20 = instrumentsState.data.indexInstruments?.find(i => i.ticker === 'RFX20')!
      const wti = instrumentsState.data.futureInstruments.filter(i => i.segmentSubtype?.subtype === 'wti')[0]	
      const soja = instrumentsState.data.futureInstruments.filter(i => i.segmentSubtype?.subtype === 'soja')[0]
      const oro = instrumentsState.data.futureInstruments.filter(i => i.segmentSubtype?.subtype === 'oro')[0]
      const note = instrumentsState.data.noteInstruments[0]
      return [rfx20, merval, indiceRofex20, dolar, wti, soja, oro, note].filter(i => i !== undefined)
    }
    return []
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { instrumentsState } = this.props
    const { value } = event.target
    if (value.length <= 0) {
      return this.setState({
        ...this.state,
        instruments: [],
        notFound: false
      })
    }
    var instruments: Instrument[] = []
    var optionInstruments: Instrument[] = []
    if (instrumentsState.status === 'succeeded' && instrumentsState.data !== null) {
      for (let key in instrumentsState.data.all) {
        if (key.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
          instruments.push(instrumentsState.data.all[key])
        }
      }
      if (value.length > 2 && instruments.length < 10) {
        for (let key in instrumentsState.data.optionInstruments) {
          if (key.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
            optionInstruments.push(instrumentsState.data.optionInstruments[key])
          }
        }
      }
    }

    sortByLevensteinDistance(instruments, value.toUpperCase())
    SortByArray(optionInstruments, 'maturityDate')

    instruments = instruments.slice(0, 10)
    instruments = instruments.concat(optionInstruments.slice(0, 10))
    this.setState({
      ...this.state,
      text: value,
      instruments: instruments.slice(0, 10),
      notFound: instruments.length === 0
    })
  }

  handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      onFocus: true
    })
  }

  handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      notFound: false,
      // onFocus: false
    })
  }

  onSelectInstrument = (instrument: Instrument) => {
    this.props.selectInstrument(instrument)
    this.setState({
      ...this.state,
      onFocus: false
    })
  }

  onWatchInstrument = (instrument: Instrument) => {
    this.props.watchInstrument(instrument)
  }

  blur = () => {
    document.getElementById("search-input")!.blur()
    this.setState({ onFocus: false })
  }

  emptyList = (notFound: boolean, value: string) => {

    return (
      <li className="empty-state">
        {notFound &&
          <div>{`No se encontraron resultados con el ticker '${value}'. Probá otros instrumentos como los del listado`}</div>}
        {!notFound && 
          <div>Podés encontrar todos los futuros, acciones, bonos, letra y opciones, por ejemplo...</div>}
      </li>
    )
  }

  render() {
    const { onFocus, instruments, notFound, text } = this.state
    const { instrumentsState, compact, height } = this.props
    const isLoading = instrumentsState.status === 'pending'
    const isEmpty = instruments.length === 0
    const emptyInstruments = this.emptyStateInstruments()
    const listOfInstruments = instruments.length > 0 ? instruments : emptyInstruments
    const className = onFocus ? "form-autocomplete focus" : "form-autocomplete"
    const compactMessage = compact ? "Volver al modo normal" : "Modo expandido"
    return (
      <div {...formAutocompleteStyle(height)} className={className}>
        {onFocus && <div className="overlay" onClick={this.blur}/>}
        <div className="form-autocomplete-input form-input">
          <SearchIcon className="icon"/>
          <input
            id="search-input"
            className="form-input"
            type="text"
            name="instrument"
            autoComplete="off"
            placeholder="Buscar instrumentos..."
            onChange={this.handleInputChange}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
          />
          {compact !== undefined && 
            <div className="compact-btn tooltip tooltip-left" 
              onClick={this.props.onClickCompact} 
              data-tooltip={compactMessage}>
              {compact === true && <CompactIcon className="icon" />}
              {compact === false && <ExpandIcon className="icon" />}
            </div>}
        </div>
        <ul className="menu">
          {onFocus && isLoading && <Loading />}
          {onFocus && !isLoading && isEmpty && this.emptyList(notFound, text)}
          {onFocus && !isLoading &&
            listOfInstruments.map((instrument, i) => (
              <Cell
                key={i}
                instrument={instrument}
                watched={this.props.watchlist.all.indexOf(instrument.ticker) >= 0}
                onWatchClick={this.onWatchInstrument}
                onClick={this.onSelectInstrument}
              />
            ))
          }
        </ul>
      </div>
    )
  }
}

// Maybe this could be extracted onto a module.
const fadeIn = keyframes('fadein', {
  from: { opacity: 0 },
  to: { opacity: 1 }
})

const formAutocompleteStyle = (height: string = '65px') => css({
  height: height,
  width: '100%',
  padding: '0',
  borderRadius: '0',
  backgroundColor: Colors.middleViolet,

  '& > .overlay': {
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100%',
    height: '100%',
    zIndex: 450,
    top: 0,
    left: 0,
    cursor: 'pointer',
    animation: `${fadeIn} 0.3s`
  },

  '& > .form-autocomplete-input': {
    zIndex: 500,
    borderRadius: '0',
    backgroundColor: Colors.middleViolet,
    height: `${height} !important`,
    display: 'flex',
    justifyContent: 'center !important',
    alignContent: 'center !important',
    alignItems: 'center !important',
    borderWidth: 0,
  },

  '&.focus > .overlay': {
    zIndex: 750,
  },

  '&.focus > .form-autocomplete-input': {
    zIndex: 1000,
  },

  '& > .form-autocomplete-input:hover > .form-input': {
    '::placeholder': {
      color: Colors.white
    }
  },

  '& > .form-autocomplete-input > .form-input': {
    borderRadius: '0',
    fontSize: '16px',
    backgroundColor: Colors.middleViolet,
    color: Colors.white,
    height: '100%',

    '::placeholder': {
      color: Colors.lightGray
    }
  },

  '& > .form-autocomplete-input > .icon > path': {
    fill: Colors.lightGray
  },

  '& > .form-autocomplete-input:hover > .icon > path': {
    fill: Colors.white,
  },

  '& > .form-autocomplete-input > .icon': {
    width: '20px !important',
    height: '18px !important',
    margin: '0 6px 0 12px !important',
  },

  '& > .form-autocomplete-input > .compact-btn': {
    padding: '12px 24px',
    height: '100%',
    borderColor: Colors.darkLineViolet,
    borderStyle: 'none none none solid',
    borderWidth: '0 0 0 1pt',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },

  '& > .form-autocomplete-input > .compact-btn > .icon > path': {
    fill: Colors.lightGray
  },

  '& > .form-autocomplete-input > .compact-btn:hover': {
    backgroundColor: Colors.lightViolet
  },

  '& > .form-autocomplete-input > .compact-btn:hover > .icon > path': {
    fill: Colors.white
  },

  '& > .menu': {
    zIndex: 1000,
    padding: '0',
    borderRadius: '0',
    backgroundColor: Colors.middleViolet,
    boxShadow: '0 0 4px 1px rgba(0, 0, 0, 0.01), 0 3px 24px rgba(0, 0, 0, 0.6)',
  },

  '& > .menu > .empty-state': {
    color: Colors.lightGray,
    margin: '0 !important',
    padding: '20px 60px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    borderColor: Colors.darkLineViolet,
    borderStyle: 'none none solid none',
    borderWidth: '0 0 1pt 0',
  }
})
