import * as fetch from 'isomorphic-fetch'

export type ApiRequest = {
  method: string
  url: string
  credentials?: 'include' | 'omit' | 'same-origin'
  cors?: 'cors' | 'no-cors'
  accept?: string
  contentType?: string
  headers?: { [key: string]: string }
  body?: { [key: string]: string }
}

export const apiPromiseClient = <T>(request: ApiRequest): Promise<T> => {
  const fetchConfig: RequestInit = {
    credentials: request.credentials || 'include',
    method: request.method,
    mode: request.cors || 'cors',
    redirect: 'error',
    headers: {
      'Content-Type': request.contentType || 'text/plain',
      ...(request.headers || {})
    }
  }
  if (request.method === 'POST') {
    fetchConfig.body = JSON.stringify(request.body || {})
  }
  return fetch(request.url, fetchConfig)
    .then<T>(response => {
      if (!response.ok) { throw response }
      return response.json()
    })
}