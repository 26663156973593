import * as React from 'react'
import { css } from 'glamor'

import { DetailsPanel } from '../DetailsPanel'
import { TTextField } from '../TTextField'
import { Button } from '../Button'
import { ContactThankYou } from 'components/EmptyState'

import { trackEvent } from 'trackers'


interface State {
  name: string
  email: string
  text: string
  sent: boolean
}

export default class ContactModal extends React.Component<{}, State> {
  state: State = {
    name: '',
    email: '',
    text: '',
    sent: false
  }

  handleInputNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.target.value })
  }

  handleInputEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value })
  }

  handleInputTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ text: event.target.value })
  }

  sendForm = () => {
    if (this.state.name.length > 0 && this.state.email.length > 0 && this.state.text.length > 0) {
      trackEvent('contact-report-message', this.state)
      this.setState({
        ...this.state,
        sent: true
      })
    }
  }

  textFields = () => [
    <TTextField 
      key="1"
      name="Nombre"
      fieldName="name"
      direction={'column'}
      value={this.state.name}
      onChange={this.handleInputNameChange}/>,
    <TTextField 
      key="2"
      name="Email"
      fieldName="email"
      direction={'column'}
      value={this.state.email}
      onChange={this.handleInputEmailChange}/>,
    <TTextField 
      key="3"
      name="Dejanos tu comentario..."
      fieldName="text"
      direction={'column'}
      multiline={true}
      value={this.state.text}
      onChange={this.handleInputTextChange}/>,
    <div key="4" className="button-send"><Button onClick={this.sendForm}>Enviar</Button></div>
  ]

  render() {
    return (
      <DetailsPanel title="Ayuda" >
        <div {...style}>
          {this.state.sent && <ContactThankYou />}
          {!this.state.sent && this.textFields()}
        </div>
      </DetailsPanel>
    )
  }
}

const style = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '400px',
  margin: '20px 0',
  alignSelf: 'center',
  justifyContent: 'center',

  '& > .button-send': {
    width: '100%',
    paddingTop: '30px'
  },

  '& > .button-send > button': {
    width: '100%'
  }
})