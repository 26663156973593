
const amplitudeExists = () => typeof window !== 'undefined' && typeof window.amplitude === 'object'

declare var window: { amplitude: any }

// tslint:disable:no-console
const trackAmplitudeEvent = (key: string, payload: any) => {
  if (amplitudeExists()) {
    console.debug('[Amplitude]', 'logEvent', key, payload)
    window.amplitude.getInstance().logEvent(key, payload)
  }
}

export const trackPurchase = (order: NewOrderJSON, clientId: string, user: string) => {
  if (amplitudeExists()) {
    var revenue = new window.amplitude.Revenue()
      .setProductId(order.instrument.symbol)
      .setPrice(parseFloat(order.price ? order.price : order.rawPrice))
      .setQuantity(parseFloat(order.orderQty))
      .setRevenueType(order.side)
      .setEventProperties({
        ...order,
        user,
        clientId
      })
    console.debug('[Amplitude]', 'revenueV2', revenue)
    window.amplitude.getInstance().logRevenueV2(revenue)
  }
}

export const trackEvent = (key: string, payload?: any) => {
  trackAmplitudeEvent(key, payload)
}

export const setUserProperties = (userProperties: any) => {
  if (amplitudeExists()) {
    console.debug('[Amplitude]', 'setUserProperties', userProperties)
    window.amplitude.getInstance().setUserProperties(userProperties)
  }
}

export const setAmplitudeProperty = (key: string, value: any, callback: any) => {
  if (amplitudeExists()) {
    const identify = new window.amplitude.Identify().set(key, value)
    console.debug('[Amplitude]', 'identify', identify)
    window.amplitude.identify(identify, callback)
  }
}