import { apiPromiseClient, ApiRequest } from './api-client'
import { transformYahooHistoricalData } from './helpers/yahoo-helpers'
import { Instrument, intervalStringDict } from '../types/classes-instrument'

export namespace YahooClient {
  const BASE_URL = 'https://talaris-config.herokuapp.com/'

  const path = (range: GraphRange, instrument: Instrument) => {
    if (range === '1D') {
      return 'intraDay/' + instrument.yahooTicker
    } else if (range === '5D') {
      return 'intraWeek/' + instrument.yahooTicker
    } else {
      return 'historical/' + instrument.yahooTicker + '/' + intervalStringDict[range]
    }
  }

  export const getHistoricalMarketData = (instrument: Instrument, range: GraphRange): Promise<CandleHistoricalOffersData> => {
    const closeTime = (instrument.segmentSubtype) ? instrument.segmentSubtype.closeTime : ''
    const request: ApiRequest = {
      credentials: 'omit',
      cors: 'cors',
      method: 'GET',
      url: BASE_URL + path(range, instrument)
    }
    return apiPromiseClient<YahooResponse>(request)
      .then(r => transformYahooHistoricalData(r, range, instrument.ticker, closeTime))
  }
}
