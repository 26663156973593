import { createStore, compose, applyMiddleware, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createWebsocketMiddleware } from '../websocket/create';
import { default as persistState, mergePersistedState } from 'redux-localstorage';
import { default as filter } from 'redux-localstorage-filter';
import * as adapterCreator from 'redux-localstorage/lib/adapters/localStorage';
import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'

import { merge } from 'lodash';
import createRootReducer from '../reducers'

const localStorageKeys = [
  'user.loggedIn',
  'user.name',
  'user.brokerName',
  'user.broker',
  'user.selectedAccount',
  'user.accounts',
  'watchlist.all',
  'app.indicators',
]
const sessionStorageKeys: string[] = []

const deepMerge = (initialState: any, persistedState: any) => merge({}, initialState, persistedState)

export default (initialState: any, history: History, composeEnhancers = compose) => {
  let middlewares: Middleware[] = [
    thunkMiddleware,
    createWebsocketMiddleware(),
    routerMiddleware(history)
  ]

  if (process.env.NODE_ENV === 'development') {
    middlewares = [...middlewares, createLogger()]
  } else {
  //   const bugsnagInstance = process.env.IS_BROWSER ? require('bugsnag-js') : require('bugsnag')
  //   middlewares = [
  //     ...middlewares,
  //     hotjar(),
  //     bugsnag(bugsnagInstance, { filterProperty: action => !action.ignoreBugsnag && action.error })
  //   ]
  }
  
  const enhancers: any = [applyMiddleware(...middlewares)]
  if (process.env.IS_BROWSER) {
    const storage = compose(filter(localStorageKeys))(adapterCreator(window.localStorage))
    enhancers.push(persistState(storage))

    if ('sessionStorage' in window) {
      const sessionStorage = compose(filter(sessionStorageKeys))(adapterCreator(window.sessionStorage))
      enhancers.push(persistState(sessionStorage))
    }
  }

  const rootReducer = process.env.IS_BROWSER
    ? compose(mergePersistedState(deepMerge))(createRootReducer(history))
    : createRootReducer(history)

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(...enhancers)
  )
};
