import * as React from 'react'
import { css } from 'glamor'
import { Colors } from 'types/colors'
import { assetUrl } from 'utils/assets'

interface Props {
  title: string
  icon: string
  value: string
}

export default class MarketDataInfo extends React.PureComponent<Props> {

  get iconImage() {
    return assetUrl(this.props.icon)
  }
  
  render() {
    return (
      <div {...style} >
        <img alt={this.props.title} className="icon" src={this.iconImage}/>
        <div className="value">{this.props.value}</div>
        <div className="title">{this.props.title}</div>
      </div>
    );
  }
}

const style = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  minWidth: '100px',

  '& > .icon': {
    color: Colors.blue,
    width: '20px',
    height: '20px',
    alignSelf: 'center',
  },

  '& > .value': {
    color: Colors.white,
    textAlign: 'center',
    fontSize: '18px',
    padding: '6px 0'
  },

  '& > .title': {
    color: Colors.lightGray,
    textAlign: 'center',
    fontSize: '11px',
  },
})
