import { createSelector } from 'reselect'
import { Instrument, InstrumentsByCategory } from 'types/classes-instrument'
import { filter } from 'lodash'

const selectedInstrumentSelector = (state: any) => state.selectedInstrument as Instrument | null

const instrumentsDataSelector = (state: any) => state.instruments.data as InstrumentsByCategory | null

const marketDataSelector = (state: any) => state.marketData.all as Dictionary<MarketData>

const instrumentOrderSelector = (state: any) => state.orders.new.instrument as Instrument

const optionsForInstrument = (selectedInstrument: Instrument | null, instrumentsData: InstrumentsByCategory | null) => {
  if (instrumentsData !== null && selectedInstrument !== null && selectedInstrument.type !== "INDEX") {
    return filter(instrumentsData.optionInstruments, o => o.ticker.startsWith(selectedInstrument.optionPrefix))
  }
  return null
}

export const optionsForInstrumentSelector = createSelector(
  selectedInstrumentSelector,
  instrumentsDataSelector,
  optionsForInstrument
)

export const marketDataForInstrumentSelector = createSelector(
  marketDataSelector,
  instrumentOrderSelector,
  (marketData: Dictionary<MarketData>, instrument: Instrument) => {
    return marketData[instrument.symbol]
  }
)
