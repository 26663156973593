import * as React from 'react'
import { Dispatch, bindActionCreators, Action } from 'redux'
import { connect } from 'react-redux'
import { css, keyframes } from 'glamor'

import { Panel } from '../Panel'
import InstrumentList from './InstrumentList'
import InstrumentDetails from './InstrumentDetails'
import { Instrument } from 'types/classes-instrument'
import { clearHistorial } from 'actions/instruments'
import { RootState } from 'reducers'

interface Props {
  compact: boolean
  selectedInstrument: Instrument | null
  clearHistorial: () => any
}

const mapStateToProps = ({ app, selectedInstrument }: RootState) => ({
  compact: app.compactMode,
  selectedInstrument: selectedInstrument
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => 
  bindActionCreators({ clearHistorial }, dispatch)

class Home extends React.Component<Props> {

  clearHistorial = () => {
    this.props.clearHistorial()
  }
  
  render() {
    const { compact, selectedInstrument } = this.props
    return (
      <Panel>
        <InstrumentList />
        {compact && selectedInstrument && 
          <div className="overlay" {...overlayStyle} onClick={this.clearHistorial}/>}
        {compact && selectedInstrument && 
          <InstrumentDetails />}
        {!compact && 
          <InstrumentDetails />}
      </Panel>
    )
  }
}

// Maybe this could be extracted onto a module.
const fadeIn = keyframes('fadein', {
  from: { opacity: 0 },
  to: { opacity: 1 }
})

const overlayStyle = css({
  position: 'fixed',
  backgroundColor: 'rgba(0,0,0,0.4)',
  width: '100%',
  height: '100%',
  zIndex: 550,
  top: 0,
  left: 0,
  cursor: 'pointer',
  animation: `${fadeIn} 0.3s`
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)