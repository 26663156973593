import * as React from 'react'
import { css } from 'glamor'

import { Instrument } from 'types/classes-instrument'
import { Colors } from 'types/colors'

interface Props {
  instrument: Instrument
  marketData?: MarketData
  selected: boolean,
  position?: DetailedPositionType
  onClick: (nstrument: Instrument) => void
}

export default class InstrumentCell extends React.PureComponent<Props> {

  selectInstrument = () => {
    this.props.onClick(this.props.instrument)
  }

  get segmentTitle() {
    const { instrument: { segmentSubtype} } = this.props
    if (segmentSubtype && segmentSubtype.name) {
      return segmentSubtype.name
    }
    return null
  }

  render() {
    const { instrument, marketData, position, selected } = this.props
    return (
      <div {...styles} onClick={this.selectInstrument} className={selected ? 'selected' : ''}>
        <div className="left">
          <p className="ticker-label bold">{instrument.ticker}</p>
          {position && <span>{position.instrumentCurrentSize} {instrument.shareName}{position.settlementSuffix}</span>}
        </div>
        <div className="center" />
        <div className="right">
          <p className="price-label bold">{instrument.lastPriceStr(marketData)}</p>
          <p className={'return-label bold ' + instrument.returnColor(marketData)}>
            {instrument.dailyReturnStr(marketData)}
          </p>
        </div>
      </div>
    )
  }
}

const styles = css({
  width: '100%',
  height: '60px',
  padding: '0 12px',
  borderStyle: 'none none solid none',
  borderBottomWidth: '1pt',
  borderColor: Colors.darkLineViolet,
  backgroundColor: Colors.middleViolet,
  color: Colors.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&.selected': {
    backgroundColor: Colors.lightViolet,
  },

  '&.selected:hover': {
    backgroundColor: Colors.lightViolet,
  },

  '&:hover': {
    backgroundColor: Colors.lightViolet,
    cursor: 'pointer'
  },

  '& > .left': {
    height: '50px',
    float: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  '& > .left > p': {
    margin: 0,
  },

  '& > .left > span': {
    color: Colors.lightGray
  },

  '& > .left > .bold': {
    fontSize: '125%',
  },

  '& > .right': {
    height: '100%',
    float: 'right',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },

  '& > .right > p': {
    margin: 0,
  },

  '& > .right > .bold': {
    fontSize: '110%',
  },

  '& > .right > .price-label': {
    marginRight: '12px',
  },

  '& > .right > .return-label': {
    width: '75px',
    height: '36px',
    color: Colors.white,
    textAlign: 'center',
    borderRadius: '3px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  '& > .right > .positive': {
    background: Colors.green,
    borderColor: Colors.green,
  },

  '& > .right > .negative': {
    background: Colors.red,
    borderColor: Colors.red,
  }
})
