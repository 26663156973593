import { forEach, map, reduce, values, groupBy } from 'lodash'
import * as moment from 'moment'

export const instrumentTypeName = (type: InstrumentType) => {
  switch (type) {
    case 'STOCK': return 'ACCIONES'
    case 'FUTURE': return 'FUTUROS'
    case 'BOND': return 'BONOS'
    case 'LETTER_NOTE': return 'LETRAS'
    case 'INDEX': return 'INDICES'
    case 'CALL_OPTION': 
    case 'PUT_OPTION': 
      return 'OPCIONES'
    default: return 'INSTRUMENTOS'
  }
}

export class Instrument implements HermesInstrumentJSON {
  instrumentId: InstrumentIdJSON
  cficode: string
  minPriceIncrement: number
  contractMultiplier: number
  roundLot: number
  priceConvertionFactor: number
  tickSize: number
  maturityDate?: Date
  currency: string
  type: InstrumentType
  segmentId: number
  yahooName: string
  name: string
  optionPrefix: string

  // lowLimitPrice: number
  // maxTradeVol: number
  // minPriceIncrement: number
  // minTradeVol: number
  // highLimitPrice: number
  // segment: MarketSegmentJSON
  segmentSubtype?: SegmentSubtypeJSON

  options?: OptionInstrument[]

  static new(json: HermesInstrumentJSON): Instrument {
    let type = Instrument.typeForCFICode(json.cficode)
    switch (type) {
      case 'STOCK': return new Equity(json)
      case 'BOND': return new Debt(json)
      case 'FUTURE': return new Future(json)
      case 'CALL_OPTION': return new OptionInstrument(json)
      case 'PUT_OPTION': return new OptionInstrument(json)
      case 'LETTER_NOTE': return new Note(json)
      case 'INDEX': return new Index(json)
      default: return new Equity(json)
    }
  }

  static typeForCFICode(cficode: string): InstrumentType {
    let code = cficode.substring(0, 1)
    let twoCode = cficode.substring(0, 2)
    if (code === 'E') {
      return 'STOCK'
    } else if (code === 'D') {
      if (twoCode === 'DY') {
        return 'LETTER_NOTE'
      }
      return 'BOND'
    } else if (code === 'F') {
      return 'FUTURE'
    } else if (twoCode === 'OC') {
      return 'CALL_OPTION'
    } else if (twoCode === 'OP') {
      return 'PUT_OPTION'
    } else if (twoCode === 'MR') {
      return 'INDEX'
    } else if (twoCode === 'RP') {
      return 'REPURCHASE'
    } else if (twoCode === 'CI') {
      return 'MUTUAL_FUND'
    } else if (twoCode === 'TC') {
      return 'CURRENCY'
    }
    return 'STOCK'
  }

  constructor(json: HermesInstrumentJSON) {
    this.instrumentId = json.instrumentId
    this.minPriceIncrement = json.minPriceIncrement || 0.001
    this.contractMultiplier = json.contractMultiplier || 1
    this.roundLot = json.roundLot || 1
    this.priceConvertionFactor = json.priceConvertionFactor || 1
    this.tickSize = json.tickSize || 1
    this.yahooName = json.yahooName || ''
    this.name = json.configName || ''
    this.optionPrefix = json.optionPrefix || 'ESTOTIENEQUESERALGOPARANOFALLAR'
    this.maturityDate = json.maturityDate ? moment(json.maturityDate).toDate() : undefined
    this.currency = json.currency
    this.cficode = json.cficode
    this.segmentId = json.segmentId
    this.type = Instrument.typeForCFICode(json.cficode)
  }

  get segmentStatus(): SegmentStatus {
    if (this.segmentSubtype) {
      
      const closeTime = this.segmentSubtype.closeTime
      const closeHour = parseFloat(closeTime.split(':')[0])
      const closeMinutes = parseFloat(closeTime.split(':')[1]) / 60
      const closeHM = closeHour + closeMinutes

      const openTime = this.segmentSubtype.openTime
      const openHour = parseFloat(openTime.split(':')[0])
      const openMinutes = parseFloat(openTime.split(':')[1]) / 60
      const openHM = openHour + openMinutes
      
      const today = moment()
      const day = today.day()
      const hour = today.hour()
      const minutes = today.minutes() / 60
      const now = hour + minutes

      if (day === 6 || day === 7 || now > closeHM) {
        return 'CLOSED'
      }

      if (now > openHM) {
        return 'OPEN'
      }

      return 'CLOSED'
    }
    
    return 'OPEN'
  }

  get symbol(): string {
    return this.instrumentId.symbol
  }

  get marketId(): string {
    return this.instrumentId.marketId
  }

  get yahooTicker(): string {
    if (this.yahooName && this.yahooName !== '') {
        return this.yahooName
    }
    return this.ticker + '.BA'
  }

  get ticker(): string {
    if (this.type === 'REPURCHASE') {
      return this.symbol.replace('MERV - XMEV - ', '')
    }
    let aTicker: string = this.symbol
    const splittedSymbol = aTicker.split(' - ')
    if (splittedSymbol.length > 2) aTicker = splittedSymbol[2]
    else if (splittedSymbol.length > 1) aTicker = splittedSymbol[1]
    if (this.type === 'INDEX') {
      return aTicker.substring(2, aTicker.length).replace('.', ' ')
    }
    return aTicker
  }

  get settlementDay(): string | undefined {
    let array = this.symbol.split(' - ')
    return (array.length > 3) ? array[3] : undefined
  }

  get settlementOptions(): string[] {
    if (this.settlementDay === '48hs') {
      return ['CI', '24hs', '48hs']
    } else if (this.settlementDay !== undefined) {
      return [this.settlementDay]
    }
    return []
  }


  get defaultOptionPrefix(): string {
    return (this.ticker.length <= 2) ? this.ticker : this.ticker.substring(0, 3)
  }

  get multiplier(): number {
    return this.priceConvertionFactor * this.contractMultiplier * this.roundLot
  }

  get numberOfDecimals(): number {
    if (this.minPriceIncrement >= 1) {
      return 0
    } else if (this.minPriceIncrement >= 0.1) {
      return 1
    } else if (this.minPriceIncrement >= 0.01) {
      return 2
    }
    return 3
  }

  get priceFormatter(): Intl.NumberFormat {
    if (this.type === 'INDEX') {
      return this.indexPriceFormatter
    }
    const formatter = new Intl.NumberFormat('es-AR', {
      'style': 'currency',
      'currency': this.currency,
      'useGrouping': true,
      'maximumFractionDigits': this.numberOfDecimals,
      'minimumFractionDigits': this.numberOfDecimals
    })
    return formatter
  }

  get positionFormatter(): Intl.NumberFormat {
    const formatter = new Intl.NumberFormat('es-AR', {
      'style': 'currency',
      'currency': 'ARS',
      'useGrouping': true,
      'maximumFractionDigits': this.numberOfDecimals,
      'minimumFractionDigits': this.numberOfDecimals
    })
    return formatter
  }

  get indexPriceFormatter(): Intl.NumberFormat {
    const formatter = new Intl.NumberFormat('es-AR', {
      'useGrouping': true,
      'maximumFractionDigits': this.numberOfDecimals,
      'minimumFractionDigits': this.numberOfDecimals
    })
    return formatter
  }

  get returnFormatter(): Intl.NumberFormat {
    return new Intl.NumberFormat('es-AR', {
      'maximumFractionDigits': 2,
      'minimumFractionDigits': 2
    })
  }

  get shareName(): string {
    if (this.type === 'STOCK') {
      return 'ACCIONES'
    } else if (this.type === 'BOND') {
      return 'NOMINALES'
    } else {
      return 'CONTRATOS'
    }
  }

  get primaryChartMarketId(): string {
    return this.instrumentId.marketId
  }

  get externalSource(): string {
    return this.isMerval ? 'external=true' : ''
  }

  get isMerval(): boolean {
    return this.symbol.includes("MERV -")
  }

  get marketOrderType(): OrderType {
    return this.isMerval ? 'MARKET' : 'MARKET_TO_LIMIT'
  }

  get isFuture(): boolean {
    return this.type === 'FUTURE'
  }

  get isDolar(): boolean {
    return this.currency === 'USD' || this.currency === 'USDD' || this.currency === 'USDC' || this.currency === 'EXT'
  }

  formatPrice(price: number): string {
    return this.priceFormatter.format(price)
  }

  disclaimer(): string | undefined {
    if (this.contractMultiplier > 1) {
      return `Cada contrato de ${this.ticker} equivale a ${this.contractMultiplier} unidades`
    } else if (this.roundLot > 1) {
      return `Cada lote de ${this.ticker} equivale a ${this.roundLot} unidades`
    } else if (this.priceConvertionFactor < 1) {
      let nominalCount = 1 / this.priceConvertionFactor
      return `Precio por cada ${nominalCount} nominales de ${this.ticker}`
    }
    return undefined
  }

  minPrice(marketData?: MarketData) {
    if (marketData && marketData.LO) {
      return marketData.LO
    }
    return undefined
  }

  maxPrice(marketData?: MarketData) {
    if (marketData && marketData.HI) {
      return marketData.HI
    }
    return undefined
  }

  openPrice(marketData?: MarketData) {
    if (marketData && marketData.OP) {
      return marketData.OP
    }
    return undefined
  }

  lastPrice(marketData?: MarketData) {
    if (this.type === 'INDEX' && marketData && marketData.IV) {
      return marketData.IV
    } else if (marketData && marketData.LA) {
      return marketData.LA.price
    } else if (this.closePrice(marketData)) {
      return this.closePrice(marketData)
    }
    return undefined
  }

  closePrice(marketData?: MarketData) {
    if (marketData && marketData.SE && this.type === 'FUTURE') {
      return marketData.SE.price
    } else if (marketData && marketData.CL) {
      return marketData.CL.price
    }
    return undefined
  }

  dailyDiff(marketData?: MarketData) {
    const lastPrice = this.lastPrice(marketData)
    const closePrice = this.closePrice(marketData)
    if (lastPrice && closePrice) {
      return lastPrice - closePrice
    }
    return undefined
  }

  dailyReturn(marketData?: MarketData) {
    const lastPrice = this.lastPrice(marketData)
    const closePrice = this.closePrice(marketData)
    if (lastPrice && closePrice) {
      return 100 * ((lastPrice / closePrice) - 1)
    }
    return undefined
  }

  size(marketData?: MarketData) {
    if (marketData && marketData.NV) {
      return marketData.NV
    }
    return undefined
  }

  lastTimestamp(marketData?: MarketData) {
    if (marketData && marketData.LA) {
      return marketData.LA.date
    }
    return undefined
  }

  lastPriceStr(marketData?: MarketData) {
    const lastPrice = this.lastPrice(marketData)
    if (lastPrice) {
      return this.formatPrice(lastPrice)
    }
    return '--'
  }

  dailyReturnStr(marketData?: MarketData) {
    const dailyReturn = this.dailyReturn(marketData)
    if (dailyReturn) {
      const mark = dailyReturn > 0 ? '+' : ''
      return mark + this.returnFormatter.format(dailyReturn) + ' %'
    }
    return '--'
  }

  dailyDiffStr(marketData?: MarketData) {
    const dailyDiff = this.dailyDiff(marketData)
    if (dailyDiff) {
      return this.formatPrice(dailyDiff)
    }
    return '--'
  }

  volumeStr(marketData?: MarketData) {
    const size = this.size(marketData)
    if (size && size > 0) {
      return nFormatter(size)
    }
    return '--'
  }

  lastTimestampStr(marketData?: MarketData) {
    const timestamp = this.lastTimestamp(marketData)
    if (timestamp) {
      let date = moment(timestamp)
      return date.format("HH:mm")
    }
    return '--'
  }

  bestBidStr(marketData?: MarketData) {
    const { BI } = marketData || { BI: [] }
    const bidArray = BI || []
    if (bidArray.length > 0) {
      const bestBid = bidArray[0]
      const size = formatSize(bestBid.size)
      const price = this.formatPrice(bestBid.price)
      return size + " x " + price
    }
    return '--'
  }

  bestOfferStr(marketData?: MarketData) {
    const { OF } = marketData || { OF: [] }
    const offerArray = OF || []
    if (offerArray.length > 0) {
      const bestOffer = offerArray[0]
      const size = formatSize(bestOffer.size)
      const price = this.formatPrice(bestOffer.price)
      return size + " x " + price
    }
    return '--'
  }

  returnColor(marketData?: MarketData) {
    const dailyReturn = this.dailyReturn(marketData)
    if (dailyReturn && dailyReturn < 0) {
      return 'negative'
    }
    return 'positive'
  }
}

export class Equity extends Instrument {}

export class Debt extends Instrument {}

export class Future extends Instrument {}

export class Note extends Instrument {}

export class Index extends Instrument {}

export class Repurchase extends Instrument {

  formatPrice(price: number): string {
    return this.returnFormatter.format(price) + ' %'
  }
}

export class OptionInstrument extends Instrument {
  
  get expireMonth() {
    var month = this.ticker.slice(-2)
    switch (month) {
      case 'EN': return 1
      case 'FE': return 2
      case 'MA': return 3
      case 'AB': return 4
      case 'MA': return 5
      case 'JU': return 6
      case 'JU': return 7
      case 'AG': return 8
      case 'SE': return 9
      case 'OC': return 10
      case 'NO': return 11
      case 'DI': return 12
      default: return 0
    }
  }
}

export interface InstrumentsByCategory {
  equityInstruments: Equity[]
  debtInstruments: Debt[]
  futureInstruments: Future[]
  optionInstruments: Dictionary<OptionInstrument>
  noteInstruments: Note[]
  indexInstruments: Index[]
  repurchaseInstruments: Repurchase[]
  all: Dictionary<Instrument>
}

export class DetailedPosition implements DetailedPositionType {
  symbolReference: string
  instrumentMarketValue: number | null
  instrumentInitialSize: number
  instrumentFilledSize: number
  instrumentCurrentSize: number
  detailedPositions: DetailedPositionItemJSON[] // Those items does not have position setted
  itemsBySettlement: Dictionary<DetailedPositionItemJSON>

  // From first DetailedPositionItem
  contractType: ContractType
  currency: string
  exchangeRate: number
  contractSize: number
  contractMultiplier: number
  priceConversionFactor: number
  marketPrice: number

  constructor(json: DetailedPositionJSON, symbolReference: string) {
    this.symbolReference = symbolReference
    this.instrumentMarketValue = json.instrumentMarketValue
    this.instrumentInitialSize = json.instrumentInitialSize
    this.instrumentFilledSize = json.instrumentFilledSize
    this.instrumentCurrentSize = json.instrumentCurrentSize
    this.detailedPositions = json.detailedPositions

    this.itemsBySettlement = {}
    this.detailedPositions.forEach(d => {
      this.itemsBySettlement[d.settlType || "0"] = d
    })

    this.contractType = json.detailedPositions[0].contractType
    this.currency = json.detailedPositions[0].currency
    this.exchangeRate = json.detailedPositions[0].exchangeRate
    this.contractSize = json.detailedPositions[0].contractSize
    this.contractMultiplier = json.detailedPositions[0].contractMultiplier
    this.priceConversionFactor = json.detailedPositions[0].priceConversionFactor
    this.marketPrice = json.detailedPositions[0].marketPrice
  }

  get ticker(): string {
    if (this.firstItem && this.firstItem.position) {
      const splittedSymbol = this.firstItem.position.symbol.split(' - ')
      if (splittedSymbol.length > 2) return splittedSymbol[2]
      if (splittedSymbol.length > 1) return splittedSymbol[1]
      return this.firstItem.position.symbol
    }
    return ""
  }
 
  get hasReturn(): boolean {
    return this.instrumentMarketValue !== null
  }

  get marketValue(): number | null {
    if (this.instrumentMarketValue) { return this.instrumentMarketValue }
    return this.totalDiff
  }

  /*************************/
  /*************************/
  /****  DETAILED ITEM  ****/
  /*************************/
  /*************************/

  item_buySize = (item: DetailedPositionItemJSON) => {
    return item.buyInitialSize + item.buyFilledSize
  }

  item_sellSize = (item: DetailedPositionItemJSON) => {
    return item.sellInitialSize + item.sellFilledSize
  }

  item_buyPrice = (item: DetailedPositionItemJSON) => {
    if (item.position) return item.position.originalBuyPrice
    return null
  }

  item_sellPrice = (item: DetailedPositionItemJSON) => {
    if (item.position) return item.position.originalSellPrice
    return null
  }

  item_currentSize = (item: DetailedPositionItemJSON) => {
    return this.item_buySize(item) - this.item_sellSize(item)
  }

  item_totalDiff = (item: DetailedPositionItemJSON) => {
    if (item.position && (item.position.originalBuyPrice > 0 || item.position.originalSellPrice > 0)) {
      return item.position.totalDiff * item.exchangeRate
    }
    return null
  }

  item_dailyDiff = (item: DetailedPositionItemJSON) => {
    if (item.position) {
      return item.position.totalDailyDiff * item.exchangeRate
    }
    return null
  }

  item_totalReturn = (item: DetailedPositionItemJSON) => {
    const totalDiff = this.item_totalDiff(item)
    const marketValue = item.marketValue
    if (this.hasReturn && totalDiff && marketValue && (marketValue - totalDiff) > 0) {
      return totalDiff / ((marketValue * item.exchangeRate) - totalDiff)
    }
    return null
  }

  item_dailyReturn = (item: DetailedPositionItemJSON) => {
    const dailyDiff = this.item_dailyDiff(item)
    const marketValue = item.marketValue
    if (this.hasReturn && dailyDiff && marketValue && (marketValue - dailyDiff) > 0) {
      return dailyDiff / ((marketValue * item.exchangeRate) - dailyDiff)
    }
    return null
  }

  /*************************/
  /*************************/

  get totalCurrentSize(): number {
    return this.item_currentSize(this.firstItem)
  }

  get totalDiff(): number | null {
    return this.item_totalDiff(this.firstItem)
  }

  get dailyDiff(): number | null {
    return this.item_dailyDiff(this.firstItem)
  }

  get totalReturn(): number | null {
    return this.item_totalReturn(this.firstItem)
  }

  get dailyReturn(): number | null {
    return this.item_dailyReturn(this.firstItem)
  }

  get buyPrice(): number | null {
    return this.item_buyPrice(this.firstItem)
  }

  get sellPrice(): number | null {
    return this.item_sellPrice(this.firstItem)
  }

  get buySize(): number {
    return reduce(this.detailedPositions, (total, item) => total + this.item_buySize(item), 0)
  }

  get sellSize(): number {
    return reduce(this.detailedPositions, (total, item) => total + this.item_sellSize(item), 0)
  }

  get settlementSuffix(): string {
    if (this.lastSettlementType !== "0") {
      return ` - ${this.settlementTitle(this.lastSettlementType)}`
    }
    return ""
  }

  get lastSettlementDate(): number | null {
    if (this.lastSettlementType !== "0") {
      return this.itemsBySettlement[this.lastSettlementType].settlementDate
    }
    return null
  }

  get firstItem(): DetailedPositionItemJSON {
    return this.itemsBySettlement[this.firstSettlementType]
  }

  get firstSettlementType(): Settlement {
    if (this.itemsBySettlement["0"]) { return "0" }
    if (this.itemsBySettlement["1"]) { return "1" }
    return "2"
  }

  get lastSettlementType(): Settlement {
    if (this.itemsBySettlement["2"]) { return "2" }
    if (this.itemsBySettlement["1"]) { return "1" }
    return "0"
  }

  settlementTitle(settlType: Settlement): string {
    if (settlType === 0 || settlType === "0" || settlType === "CASH") return ""
    if (settlType === 1 || settlType === "1" || settlType === "NEXT_DAY") return "24hs"
    if (settlType === 2 || settlType === "2" || settlType === "T_PLUS_2") return "48hs"
    return ""
  }
}

export class AccountReportDetailed implements AccountReportDetailedType {
  account: string
  lastCalculation: number
  totalDailyDiffPlain: number
  totalMarketValue: number
  report: Dictionary<Dictionary<DetailedPositionJSON>>
  positions: Dictionary<DetailedPositionType>
  
  constructor(json: AccountReportDetailedJSON) {
    this.account = ""
    this.lastCalculation = 0
    this.totalDailyDiffPlain = 0
    this.totalMarketValue = 0
    this.positions = {}
    this.report = {}
    if (json !== null) {
      this.account = json.account
      this.lastCalculation = json.lastCalculation
      this.totalDailyDiffPlain = json.totalDailyDiffPlain
      this.totalMarketValue = json.totalMarketValue
      forEach(json.report, (category, keyCategory) => {
        const detailedPositions = map(category, (p, symbolReference) => new DetailedPosition(p, symbolReference))
        forEach(detailedPositions, p => this.positions[p.symbolReference] = p)
      })
    }
  }

  get reportByContractType(): Dictionary<DetailedPositionType[]> {
    return groupBy(values(this.positions), p => p.contractType)
  }

  // tslint:disable:align
  get totalDailyDiff(): number { 
    return reduce(this.positions, (r, p) => r + (p.dailyDiff || 0), 0)
  }

  get marketValue(): number { 
    return reduce(this.positions, (r, p) => r + (p.marketValue || 0), 0)
  }

  positionFor(ticker: string): DetailedPositionType | undefined {
    return values(this.positions).find(p => p.ticker === ticker)
  }

  marketValueFor(category: string): number {
    return reduce(this.reportByContractType[category], (marketValue, b) => {
      return marketValue + (b.marketValue || 0)
    }, 0)
  }

  totalDiffFor(category: string): number {
    return reduce(this.reportByContractType[category], (totalDiff, b) => {
      return totalDiff + (b.totalDiff || 0)
    }, 0)
  }

  totalDailyDiffFor(category: string): number {
    return reduce(this.reportByContractType[category], (totalDailyDiff, b) => {
      return totalDailyDiff + (b.dailyDiff || 0)
    }, 0)
  }

  hasReturn(category: string): boolean {
    return values(this.reportByContractType[category])[0].hasReturn
  }

  totalReturnFor(category: string): number | null {
    const marketValue = this.marketValueFor(category)
    const totalDiff = this.totalDiffFor(category)
    if (this.hasReturn(category) && (marketValue - totalDiff)) {
      return totalDiff / (marketValue - totalDiff) 
    }
    return null
  }

  dailyReturnFor(category: string): number | null {
    const marketValue = this.marketValueFor(category)
    const totalDailyDiff = this.totalDailyDiffFor(category)
    if (this.hasReturn(category) && (marketValue - totalDailyDiff)) {
      return totalDailyDiff / (marketValue - totalDailyDiff) 
    }
    return null
  }
}

export const defaultNumberFormatter = new Intl.NumberFormat('es-AR', {
  'currency': 'ARS',
  'useGrouping': true,
  'maximumFractionDigits': 2,
  'minimumFractionDigits': 2
})

export const integerPriceFormatter = new Intl.NumberFormat('es-AR', {
  'style': 'currency',
  'currency': 'ARS',
  'useGrouping': true,
  'maximumFractionDigits': 0,
  'minimumFractionDigits': 0
})

export const priceFormatter = new Intl.NumberFormat('es-AR', {
  'style': 'currency',
  'currency': 'ARS',
  'useGrouping': true,
  'maximumFractionDigits': 2,
  'minimumFractionDigits': 2
})

export const priceFormatterWith = (currency: string) => new Intl.NumberFormat('es-AR', {
  'style': 'currency',
  'currency': currency,
  'useGrouping': true,
  'maximumFractionDigits': 2,
  'minimumFractionDigits': 2
})

export const defaultPriceFormatter = new Intl.NumberFormat('es-AR', {
  'style': 'currency',
  'currency': 'ARS',
  'useGrouping': true,
  'maximumFractionDigits': 3
})

export const returnFormatter = new Intl.NumberFormat('es-AR', {
  'style': 'percent',
  'useGrouping': true,
  'maximumFractionDigits': 2,
  'minimumFractionDigits': 2
})

export const sizeFormatter = new Intl.NumberFormat('es-AR', {
  'useGrouping': true,
  'maximumFractionDigits': 0,
  'minimumFractionDigits': 0
})

export const formatPrice = (price: number | null) => {
  if (price) {
    return priceFormatter.format(price)
  }
  return "--"
}

export const formatReturn = (value: number | null) => {
  if (value) {
    return returnFormatter.format(value)
  }
  return '--'
}

export const formatSize = (size: number) => {
  if (isNaN(size)) {
    return "--"
  }
  return sizeFormatter.format(size)
}

export const nFormatter = (num?: number) => {
  if (num === undefined || isNaN(num)) {
    return "--"
  }
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'MM';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return `${num}`;
}

export const formatCandleData = (value: CandleDataEntry | number | SizeData, formatter: Intl.NumberFormat) => {
  if (typeof value === 'number') {
    return formatter.format(value)
  } else if ('o' in value) {
    const { o, l, h, c } = value
    const open = formatter.format(o)
    const close = formatter.format(c)
    const high = formatter.format(h)
    const low = formatter.format(l)
    return 'O ' + open + ' ・ H ' + high + ' ・ L ' + low + ' ・ C ' + close
  } else {
    return nFormatter(value.y)
  }
}

export const emptyAccountReport = () => {
  return {
    accountName: '',
    marketMember: '',
    marketMemberIdentity: '',
    availableToCollateral: 0,
    collateral: 0,
    margin: 0,
    ordersMargin: 0,
    uncoveredMargin: 0,
    portfolio: 0,
    currentCash: 0,
    dailyDiff: 0,
    lastCalculation: 0,
    hasError: false,
    detailedAccountReports: {
      '0': {
          settlementDate: '',
          currencyBalance: {},
          availableToOperate: {
            total: 0,
            credit: 0,
            movements: 0,
            pendingMovements: 0,
            cash: { totalCash: 0 }
          }
      },
      '1': {
          settlementDate: '',
          currencyBalance: {},
          availableToOperate: {
            total: 0,
            credit: 0,
            movements: 0,
            pendingMovements: 0,
            cash: { totalCash: 0 }
          }
      },
      '2': {
          settlementDate: '',
          currencyBalance: {},
          availableToOperate: {
            total: 0,
            credit: 0,
            movements: 0,
            pendingMovements: 0,
            cash: { totalCash: 0 }
          }
      }
    }
  } as AccountReportJSON
}

export const intervalStringDict: GraphRangeDictionary = {
  '1D': '1m',
  '5D': '5m',
  '1M': '1mo',
  '3M': '3mo',
  '6M': '6mo',
  '1A': '1y',
}

export const timeframeDict: GraphRangeDictionary = {
  '1D': '1d',
  '5D': '5d',
  '1M': '1mo',
  '3M': '3mo',
  '6M': '6mo',
  '1A': '1y',
}

export const intervalDict: GraphRangeDictionary = {
  '1D': '1m',
  '5D': '5m',
  '1M': '1d',
  '3M': '1d',
  '6M': '1d',
  '1A': '1d',
}