import { ErrorBoundary } from 'utils/bugsnag'

import * as React from 'react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as moment from 'moment'
import { rehydrate } from 'glamor'
rehydrate(window._glam)

// import registerServiceWorker from './registerServiceWorker'
import buildStore from './store'
import Routes from './Routes'

declare var window: { 
  __INITIAL_STATE__: {},
  _glam: string[]
}

const initialState = {
  ...window.__INITIAL_STATE__
}

const history = createBrowserHistory()

const store = buildStore(
  initialState,
  history
  // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
);

// Inject Redux state in all Bugsnag notifications
// Bugsnag.beforeNotify = (payload: any, metaData: any) => {
//   payload.metaData['Redux State'] = store.getState()
//   return true
// }

moment.locale('es')

hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>,
  document.getElementById('app') as HTMLElement
)

// registerServiceWorker()