import * as React from 'react'
import { css } from 'glamor'
import { Colors } from  '../types/colors'

interface Props {
  disabled?: boolean,
  onClick?: () => void
}

export class Button extends React.PureComponent<Props> {
  render() {
    return (
      <button {...style} className="btn" onClick={this.props.onClick} disabled={this.props.disabled}>
        {this.props.children}
      </button>
    );
  }
}

const style = css({
  color: Colors.white,
  height: 'auto',
  padding: '8px 16px',
  background: `${Colors.blue} !important`,
  border: 'none',
  backgroundColor: Colors.blue,
  fontSize: '115%',

  '&:hover': {
    background: `${Colors.lightBlue} !important`,
    backgroundColor: Colors.lightBlue,
    transform: 'translateY(-1px)',
    border: 'none',
  },

  '&:active': {
    backgroundColor: Colors.blue,
    border: 'none',
  },

  '&:focus': {
    backgroundColor: Colors.blue,
    border: 'none',
  },

  '&:visited': {
    backgroundColor: Colors.blue,
    border: 'none',
  }
})
