import * as React from 'react'
import { Colors } from 'types/colors';

function makeRect(x: number, y: number, w: number, h: number) {
  return { x: x, y: y, w: w, h: h }
}

interface Props {
  formatter: Intl.NumberFormat
  returnColor: 'positive' | 'negative'
  min?: number
  max?: number
  close?: number
  open?: number
}

const ClearCanvas = (canvas: HTMLCanvasElement) => {
  canvas = InitializeCanvas(canvas)
  canvas.getContext('2d')!.clearRect(0, 0, canvas.width, canvas.height)
}

const InitializeCanvas = (canvas: any) => {
  if ('paintCodePixelRatio' in canvas) return canvas
  // This function should only be called once on each canvas.
  var context = canvas.getContext('2d')!

  var devicePixelRatio = window.devicePixelRatio || 1
  var backingStorePixelRatio = context.webkitBackingStorePixelRatio
      || context.mozBackingStorePixelRatio
      || context.msBackingStorePixelRatio
      || context.oBackingStorePixelRatio
      || context.backingStorePixelRatio
      || 1

  var pixelRatio = devicePixelRatio / backingStorePixelRatio

  canvas.style.width = canvas.width + 'px'
  canvas.style.height = canvas.height + 'px'
  canvas.width *= pixelRatio
  canvas.height *= pixelRatio
  canvas.paintCodePixelRatio = pixelRatio

  context.scale(pixelRatio, pixelRatio)
  return canvas
}

export class CandleStick extends React.PureComponent<Props> {
  
  componentDidMount() {
    this.updateCanvas()
  }

  componentDidUpdate() {
    this.updateCanvas()
  }

  componentWillReceiveProps() {
    this.clearCanvas()
  }

  clearCanvas() {
    const canvas = this.refs.canvas
    if (canvas instanceof HTMLCanvasElement) {
      ClearCanvas(canvas)
    }
  }

  // shouldComponentUpdate(nextProps: Props) {
  //   const { min, max, close, open } = this.props
  //   return !(min === nextProps.min && 
  //     max === nextProps.max && 
  //     close === nextProps.close && 
  //     open === nextProps.open)
  // }

  updateCanvas() {
    const { min, max, close, open, formatter, returnColor } = this.props
    if (!(min && max && close && open)) {
      return
    }
    const maxText = formatter.format(max)
    const minText = formatter.format(min)

    let canvas = this.refs.canvas
    if (canvas instanceof HTMLCanvasElement) {
      InitializeCanvas(canvas)
      const context = canvas.getContext('2d')!
      const width = canvas.width / (canvas as any).paintCodePixelRatio
      // const height = canvas.height / (canvas as any).paintCodePixelRatio
      
      const diff = max - min
      const openX = (open - min) * width / diff
      const closeX = (close - min) * width / diff
      const openCloseWidth = Math.abs(closeX - openX)
      const start = closeX > openX ? openX : closeX

      // var pixelRatio = canvas.paintCodePixelRatio
      
      //// Resize to Target Frame
      context.save()
      // var resizedFrame = applyResizingBehavior(resizing, makeRect(0, 0, 215, 35), targetFrame)
      // context.translate(resizedFrame.x, resizedFrame.y)
      // context.scale(resizedFrame.w / 215, resizedFrame.h / 35)


      //// Color Declarations
      var color = returnColor === 'positive' ? Colors.green : Colors.red
      var gray = 'rgba(128, 128, 128, 1)'

      //// Group
      //// Rectangle Drawing
      context.beginPath()
      context.rect(0, 16, width, 2)
      context.fillStyle = gray
      context.fill()


      //// Rectangle 2 Drawing
      context.beginPath()
      context.rect(start, 16, openCloseWidth, 2)
      context.fillStyle = color
      context.fill()

      //// Bezier Drawing
      context.save()
      context.translate(closeX, 6)

      context.beginPath();
      context.moveTo(0.47, 6);
      context.bezierCurveTo(2.14, 3.74, 2.97, 2.19, 2.97, 1.33);
      context.bezierCurveTo(2.97, 0.04, 1.85, -1.01, 0.47, -1.01);
      context.bezierCurveTo(-0.91, -1.01, -2.03, 0.04, -2.03, 1.33);
      context.bezierCurveTo(-2.03, 2.19, -1.2, 3.74, 0.47, 6);
      context.closePath();
      context.fillStyle = color
      context.fill();

      context.restore()


      //// Label Drawing
      var labelRect = makeRect(0, 18, width / 2, 19);
      context.fillStyle = Colors.lightGray
      context.font = '12px SourceSansPro-Regular, "Source Sans Pro", sans-serif'
      context.textAlign = 'left'
      var labelTotalHeight = 14 * 1.3
      context.fillText(minText, labelRect.x, labelRect.y + 14 + labelRect.h / 2 - labelTotalHeight / 2)


      //// Label 2 Drawing
      var label2Rect = makeRect(width / 2, 18, width / 2, 19);
      context.fillStyle = Colors.lightGray
      context.font = '12px SourceSansPro-Regular, "Source Sans Pro", sans-serif'
      context.textAlign = 'right'
      var label2TotalHeight = 14 * 1.3
      context.fillText(maxText, label2Rect.x + label2Rect.w, label2Rect.y + 14 + label2Rect.h / 2 - label2TotalHeight / 2)
      
      context.restore()
    }
  }
  render() {
    return <canvas ref="canvas" width={130} height={35}/>
  }
}


//// APIs you can use in your code:
//
// Available methods for drawing into <canvas> elements:
//    StyleKitName.drawCanvas1(canvas, high, low, close, diff, start, targetFrame*, resizing*)
//
// NOTE: 'canvas' parameter can be either a <canvas> element object, or the id of a <canvas> element in your document.
//
// NOTE: Parameters marked with the '*' symbol are optional
//
// NOTE: Possible arguments for 'resizing' parameter in drawing methods are:
//   'aspectfit': The content is proportionally resized to fit into the target rectangle.
//   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
//   'stretch': The content is stretched to match the entire target rectangle.
//   'center': The content is centered in the target rectangle, but it is NOT resized.
//

//     // Possible arguments for 'resizing' parameter are:
//     //   'aspectfit': The content is proportionally resized to fit into the target rectangle.
//     //   'aspectfill': The content is proportionally resized to completely fill the target rectangle.
//     //   'stretch': The content is stretched to match the entire target rectangle.
//     //   'center': The content is centered in the target rectangle, but it is NOT resized.
//     function applyResizingBehavior(resizing: any, rect: any, targetRect: any) {
//         if (targetRect === undefined || equalRects(rect, targetRect) || equalRects(targetRect, makeRect(0, 0, 0, 0))) {
//             return rect
//         }

//         var scales = makeSize(0, 0)
//         scales.w = Math.abs(targetRect.w / rect.w)
//         scales.h = Math.abs(targetRect.h / rect.h)

//         switch (resizing) {
//             case 'aspectfit': {
//                 scales.w = Math.min(scales.w, scales.h)
//                 scales.h = scales.w
//                 break
//             }
//             case 'aspectfill': {
//                 scales.w = Math.max(scales.w, scales.h)
//                 scales.h = scales.w
//                 break
//             }
//             case 'stretch':
//             case undefined:
//                 break
//             case 'center': {
//                 scales.w = 1
//                 scales.h = 1
//                 break
//             }
//             default:
//                 throw 'Unknown resizing behavior "' + resizing + '". Use "aspectfit", "aspectfill", "stretch" or "center" as resizing behavior.'
//         }

//         var result = makeRect(Math.min(rect.x, rect.x + rect.w), Math.min(rect.y, rect.y + rect.h), Math.abs(rect.w), Math.abs(rect.h))
//         result.w *= scales.w
//         result.h *= scales.h
//         result.x = targetRect.x + (targetRect.w - result.w) / 2
//         result.y = targetRect.y + (targetRect.h - result.h) / 2
//         return result
//     }