import * as React from 'react'
import { css } from 'glamor'

import { DetailsPanel } from '../DetailsPanel'

/* tslint:disable:max-line-length */
export default class TermsPanel extends React.PureComponent {

  render() {
    return (
      <DetailsPanel title="Términos y condiciones de uso del sitio">
        <div {...style}>
          <p>Este documento describe los términos y condiciones generales aplicables al uso de los servicios ofrecidos por Primary S.A por medio del sitio web store.primary.ventures (en adelante el Sitio Web). </p>

          <p>CUALQUIER PERSONA (USUARIO) QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE INGRESAR Y/O UTILIZAR EL SITIO Y/O LOS SERVICIOS. </p>

          <p>El Usuario, es decir todo aquel que acceda al Sitio Web, debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones así como en los demás documentos incorporados a los mismos por referencia, previamente a su inscripción como Usuario del Sitio Web. </p>

          <p>Finalidad del Sitio Web. </p>

          <p>El Sitio Web servirá como repositorio central de las aplicaciones (en adelante apps) desarrolladas, ofrecidas y/o comercializadas por PRIMARY S.A. que pueden estar relacionadas con actividades como el ruteo de órdenes, consumo de información de mercado, análisis de información de riesgo, entre otras. </p>

          <p>Capacidad para contratar </p>

          <p>Los Servicios y/o software y/o apps ofrecidas por PRIMARY S.A. en el Sitio Web, sólo están disponibles para personas que tengan capacidad legal para contratar. </p>

          <p>Inscripción del Usuario </p>

          <p>Es obligatorio completar el formulario de inscripción en todos sus campos con datos válidos para poder utilizar los servicios ofrecidos por PRIMARY S.A con información personal de manera exacta y veraz. El Usuario asume el compromiso de actualizar los Datos Personales. PRIMARY S.A no se responsabiliza de ninguna forma de la información brindada por los usuarios, ni tampoco se encuentra obligado a confirmar su veracidad. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales ingresados. PRIMARY S.A se reserva el derecho de solicitar algún comprobante y/o dato adicional a efectos de corroborar los Datos Personales, así como de suspender temporal o definitivamente a aquellos Usuarios respecto de los cuales exista indicio de falsedad o posible suplantación. El Usuario podrá acceder a los servicios de PRIMARY S.A mediante el ingreso de su cuenta y clave de seguridad personal definida por PRIMARY S.A. al momento del registro. La clave será de uso personal e intransferible. PRIMARY S.A no se responsabiliza por su uso indebido ni suplantación. El Usuario será responsable de forma absoluta por el uso que le de a la cuenta. PRIMARY S.A podrá suspender y/o cancelar las cuentas de los Usuarios que no cumplan con los términos y condiciones. El Usuario se compromete a notificar a PRIMARY S.A, en forma inmediata y por medio fehaciente, cualquier uso no autorizado de su cuenta, así como el ingreso por terceros no autorizados a la misma. Se aclara que está prohibida cualquier enajenación de la cuenta. PRIMARY S.A tendrá el derecho discrecional de no aceptar la solicitud de cualquier posible Usuario o revocar la solicitud de todo Usuario existente, por incumplimiento de los términos y condiciones y sin que ello genere derecho a indemnización alguna o resarcimiento a favor del Usuario. </p>

          <p>Modificaciones del Acuerdo </p>

          <p>PRIMARY S.A podrá modificar los Términos y Condiciones en cualquier momento haciendo públicos en el Sitio Web los términos modificados, sin notificación a los Usuarios, quienes deberán en todo momento estar pendientes de toda posible modificación. </p>

          <p>Obligaciones de los Usuarios </p>

          <p>-  Respetar a los Usuarios y administradores del Sitio Web  -  Cumplir los términos y condiciones del Sitio Web. -  Cumplir en todo momento las condiciones de licenciamiento de los software, aplicaciones y/o desarrollos a los cuales acceden por medio del Sitio Web. </p>
          <p>Prohibiciones de los Usuarios </p>
          <p>Los Usuarios no podrán utilizar las marcas, derechos de autor, obras derivadas, imágenes, fotografías, noticias, textos, nombres comerciales de ningún tipo ni signos distintivos de PRIMARY S.A ni de sus empresas del grupo, así como de empresas que realicen publicidad, propaganda o mercadeo en el Sitio Web, sin previa autorización expresa y escrita de PRIMARY S.A, para lo cual podrán enviar un correo a store@primary.com.ar </p>
          <p>Propiedad Intelectual Bases de Datos </p>
          <p>Este Sitio Web y su contenido son de propiedad de PRIMARY S.A. Está prohibida su reproducción total o parcial, su traducción, inclusión, transmisión, almacenamiento o acceso a través de medios analógicos, digitales o de cualquier otro sistema o tecnología creada o por crearse, sin autorización previa y escrita PRIMARY S.A. </p>
          <p>No está permitida ninguna acción o uso de dispositivo, software u otro medio tendiente a interferir tanto en las actividades de PRIMARY S.A, ni de los software, aplicaciones y/o desarrollos a los cuales acceden por medio del Sitio Web. </p>
          <p>Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes sobre derecho de propiedad intelectual e industrial, así como las bases de datos, y/o a las prohibiciones estipuladas en este acuerdo harán pasible a su responsable de las acciones legales pertinentes. </p>
          <p>LICENCIA DE USO APLICACIONES Y/O SOFTWARE DESCARGABLES. </p>

          <p>Salvo estipulación en contrario, toda descarga de software y/o app se ajustará a lo siguiente: </p>

          <p>1. CONDICIONES DE USO. 1.1. LA LICENCIA es de carácter temporal gratuita no exclusiva, revocable y limitada al uso exclusivo del software y/o app. 
          1.2 El software y/o la app, incluyendo su documentación, presentación, estructura, organización, texto incorporado, código fuente, código objeto, encriptados y base de datos asociada, es propiedad exclusiva del PRIMARY S.A. 1.3LA LICENCIA no constituye de forma parcial ni total la compra, venta ni cesión del software y/o app ni de la marca, ni de los derechos de autor correspondientes, ni licenciamiento de derechos diferentes a los establecidos en la LICENCIA. 
          1.4El Usuario podrá instalar y/o desinstalar el Software siguiendo las indicaciones establecidas en el mismo software y/o app o en el Sitio Web o requiriendo los servicios del PRIMARY S.A. o de terceros autorizados por PRIMARY S.A., sufragando el costo de tales servicios. 1.5 El software y/o la app podrá incluir tecnología de activación de productos y otras tecnologías diseñadas para evitar usos y copias no autorizados. 
          1.6 El Usuario se compromete a utilizar el software y/o la app personalmente y para su negocio y/o actividad con prohibición, por si o por terceros, de modificar, alterar, descompilar, aplicarle ingeniería inversa, descompilar desensamblarla, sub-licenciar, vender y/o comercializar, ceder, prestar, copiar, y/o en general, transmitir, por cualquier título, sea en forma parcial o total, transitoria o permanente, gratuitamente u onerosamente, para beneficio propio y/o de terceros el código objeto y/o el código fuente, bases de datos del software y/o app, y/o cualquier otro derecho o activo tangible o intangible objeto de LA LICENCIA sin previa autorización por escrito del PRIMARY SA . 
          1.7 La titularidad de todo cambio u obra derivada de los activos tangibles y/o intangibles objeto de LA LICENCIA, pertenecerán únicamente al PRIMARY SA, sin que este deba otorgar ninguna contraprestación al Usuario ni a terceros. 1.8 PRIMARY S.A. se reserva el derecho de modificar y/o alterar, total o parcialmente, LA LICENCIA, en cualquier momento, sin necesidad de dar aviso, ni solicitar autorización al Usuario y sin responsabilidad por la pérdida total o parcial de los datos y/o contenidos del Usuario. 
          1.9 El Usuario podrá brindar a PRIMARY S.A. de forma gratuita sus comentarios u opiniones sobre el software y/o la app. El Usuario garantiza que (i) los comentarios no contienen información confidencial ni propiedad suya ni de terceros; (ii) PRIMARY S.A. no está sometido a ningún tipo de obligación de confidencialidad, explícita ni implícita, respecto a los comentarios; (iii) PRIMARY S.A. podría estar estudiando o desarrollando ya algo semejante a los comentarios; y (iv) El Usuario no tiene derecho en ninguna circunstancia a ningún tipo de remuneración ni reembolso por parte de PRIMARY S.A. a cambio de los comentarios y si llegará a presentarse será a mera liberalidad de PRIMARY S.A. </p>

          <p>2. RESPONSABILIDAD: El Usuario ENTIENDE Y ACEPTA que: 
          2.1 EL USO SOFTWARE Y/O LA APP SERÁ BAJO SU PROPIO RIESGO Y QUE NI EL LICENCIATANTE NI SUS EMPRESAS RELACIONADAS LE GARANTIZAN DE FORMA ALGUNA RESULTADOS ECONÓMICOS, NI INGRESOS, POR EL USO DEL SOFTWARE Y/O DE LA APP 2.2. NI PRIMARY NI SUS EMPRESAS RELACIONADAS, SERÁN RESPONSABLES POR NINGÚN PERJUICIO INCIDENTAL DIRECTO NI INDIRECTO, INCLUIDO CUALQUIER DAÑO, PERJUICIO, LUCRO CESANTE, RECLAMOS DE TERCEROS DERIVADOS DE: LA INSTACIÓN, EJECUCIÓN DEL SOFTWARE Y/O APP; USO DE LA MARCA PÉRDIDA DE LA INFORMACIÓN; DEFICIENTES RESULTADOS ECONÓMICOS DEL USUARIO ; AFECTACIÓN DEL BUEN NOMBRE COMERCIAL DEL USUARIO Y/O PRIMARY S.A. Y/O DE SUS EMPRESAS RELACIONADAS, QUE SEAN RESULTANTES DEL USO DEL SOFTWARE Y/O APP; NI DERIVADOS DE FACTORES MATERIALES Y/O TÉCNICOS Y/O HUMANOS Y/O DE SUPUESTOS DE CASO FORTUITO O DE FUERZA MAYOR, QUE PROVOCAREN LA INTERRUPCIÓN O SUSPENSIÓN TEMPORAL O TOTAL DEL SOFTWARE Y/O APP, Y/O AFECTAREN ALGUNAS FUNCIONALIDADES DEL SOFTWARE Y/O APP; PRIMARY S.A. NO GARANTIZA LA OPERACIÓN ININTERRUMPIDA NI LIBRE DE TODO ERROR DEL SOFTWARE Y/O APP. 
          2.3 PRIMARY S.A. TAMPOCO SERÁ RESPONSABLE POR LOS HECHOS, ACTOS U OMISIONES DE TERCEROS A LOS QUE EVENTUALMENTE EL USUARIO ENCOMENDARE LA INSTALACIÓN, EJECUCIÓN Y/O REPARACIÓN DEL SOFTWARE Y/O APP. 2.4 EL SOFTWARE Y/O LA APP PUEDE CONTENER MATERIALES DE “CÓDIGO ABIERTO” (POR EJEMPLO, CUALQUIER SOFTWARE SUJETO A CÓDIGO ABIERTO [OPEN SOURCE], COPYLEFT, LICENCIA PÚBLICA GENERAL GNU [GNU GENERAL PUBLIC LICENSE], LICENCIA PÚBLICA PARA BIBLIOTECAS [LIBRARY GENERAL PUBLIC LICENSE], LICENCIA PÚBLICA GENERAL REDUCIDA [LESSER GENERAL PUBLIC LICENSE], LICENCIA DE MOZILLA [MOZILLA LICENSE], LICENCIA DE DISTRIBUCIÓN DE SOFTWARE DE BERKELEY [BERKELEY SOFTWARE DISTRIBUTION LICENSE], LICENCIAS DE INICIATIVAS DE CÓDIGO ABIERTO [OPEN SOURCE INITIATIVE LICENSE], MIT, APACHE, LICENCIAS DE DOMINIO PÚBLICO [PUBLIC DOMAIN LICENSES] O SIMILARES). PRIMARY S.A. EL NO OFRECE GARANTÍA ALGUNA NI ASUME NINGUNA RESPONSABILIDAD DIRECTA NI INDIRECTA DEL TIPO QUE SEA CON RESPECTO A LOS MATERIALES DE CÓDIGO ABIERTO INCLUIDOS EN EL SOFTWARE Y/O LA APP. </p>

          <p>3. INDEMNIDAD:
          3.1 EL Usuario será responsable de proveer información verdadera, precisa, actual y completa en los campos obligatorios del formulario de registro y mantener actualizada dicha información. PRIMARY S.A. podrá revocar la LICENCIA, sin indemnización alguna a favor del Usuario, en caso de existir duda o considerar que la información otorgada no resulta verídica. 3.2 El contenido e información generada en el software y/o app por el Usuario, será responsabilidad absoluta de aquel, así como también el uso asignado a la misma. 3.3. El Usuario defenderá, indemnizará y reembolsará los gastos de defensa en que el haya incurrido al PRIMARY S.A., sus empleados, dependientes y/o contratistas o empresas relacionadas por cualquier reclamo, demanda, daño, lucro cesante y/o perjuicio, afectación de buen nombre comercial, que se encuentre relacionado directa y/o indirectamente con la LICENCIA. </p>

          <p>4. CONFIDENCIALIDAD: El Usuario se obliga a mantener toda la información y/o documentación integrante de LA LICENCIA, bajo estrictas condiciones de confidencialidad, tomando todas las precauciones usuales en la industria para resguardar dicha confidencialidad. </p>

          <p>Responsabilidad por el uso del Sitio Web </p>

          <p>Fallas en el Sitio Web. </p>

          <p>Ni PRIMARY S.A ni sus empresas vinculadas, serán responsables por cualquier daño, perjuicio o pérdida al Usuario causados por fallas en el sistema, en el servidor o en Internet o deficiencias en el servicio del sitio web ni de los software, aplicaciones y/o desarrollos a los cuales acceden por medio del Sitio Web. </p>

          <p>Ni PRIMARY S.A ni sus empresas vinculadas, tampoco serán responsables por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso del sitio web, ni de los Software, aplicaciones y/o desarrollos a los cuales acceden por medio del Sitio Web. Los Usuarios NO podrán imputarle responsabilidad alguna ni exigir pago por lucro cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en el sitio web o en Internet. Ni PRIMARY S.A ni sus empresas vinculadas garantizan de forma alguna el acceso y uso continuado o ininterrumpido de este sitio web. El sitio web puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a PRIMARY S.A </p>

          <p>Tarifas, costos y cargos. </p>

          <p>La inscripción en este Sitio Web es gratuita. No obstante todo Usuario de acuerdo al perfil del mismo, establecido por PRIMARY S.A. podrá solicitar onerosamente licencias temporales, y no exclusivas de los software, aplicaciones y/o desarrollos a los cuales los usuarios pueden acceder por medio del Sitio Web, de acuerdo con las tasas, fees, cargos y gastos de administración fijados por PRIMARY S.A, los cuales podrán ser modificados en todo momento por PRIMARY S.A., siendo efectivas estas modificaciones desde su publicación en el Sitio Web. PRIMARY S.A se reserva el derecho de confirmar el pago y efectiva recepción de los recursos, previamente a la descarga del servicio contratado por los Usuarios. </p>

          <p>Calificación de los Software y/o apps </p>

          <p>Las calificaciones, comentarios y réplicas que sean realizados por los Usuarios respecto los software, apps y/o desarrollos a los cuales acceden por medio del Sitio Web, serán incluidos bajo exclusiva responsabilidad de los Usuarios que los emitan. PRIMARY S.A no tiene obligación de verificar la veracidad o exactitud de los mismos y NO se responsabiliza por los dichos allí mencionados por cualquier Usuario. PRIMARY S.A se reserva el derecho de eliminar aquellos comentarios que sean considerados inadecuados u ofensivos en contra de otros Usuarios, así como de PRIMARY S.A, y/o de los software, aplicaciones y/o desarrollos a los cuales acceden por medio del Sitio Web </p>

          <p>Políticas de Privacidad </p>

          <p>Las políticas de privacidad serán aplicables a los Usuarios desde su acceso y/o apertura de cuenta en el Sitio Web y/o mediante la aceptación de los términos y condiciones del mismo. La privacidad de la información de los usuarios es muy importante para PRIMARY S.A Es por esa razón que se toman las precauciones y recaudos para resguardar su información. La información de los usuarios solo podrá revelarse total o parcialmente con autorización previa de los mismos. PRIMARY S.A recopila información de los usuarios con fines estadísticos internos, compartiéndola con sus empresas relacionadas, así como para evaluar sus preferencias y ofrecerles un mejor servicio como plataforma de encuentro entre los mismos. Los usuarios podrán ejercitar los derechos de conocer, acceder, retirar y actualizar su Información Personal, incluyendo su dirección de e-mail, así como a oponerse al tratamiento de la misma y a ser informado de las cesiones llevadas a cabo, todo ello de conformidad a lo dispuesto en la normativa aplicable. Los usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de la Información Personal facilitada, y se comprometen a mantenerla debidamente actualizada. El usuario podrá revisar, actualizar, y eliminar su información personal accediendo desde su cuenta en PRIMARY S.A. </p>

          <p>PRIMARY S.A. podrá mantener información personal que previamente haya sido objeto de modificación o eliminación de los usuarios, con la finalidad de resolver disputas o reclamaciones, detectar problemas o incidencias y solucionarlos, y dar cumplimiento a lo dispuesto en los Términos y condiciones del sitio web por un período de tiempo determinado por la ley o determinado por la finalidad que se persiga. En cualquier caso, la Información Personal de un usuario no será inmediatamente retirada de nuestros archivos. Por tanto, no se debe esperar que toda la Información Personal sea definitivamente borrada de nuestras bases de datos lo cual es entendido y aceptado por los usuarios al aceptar los Términos y Condiciones y las Políticas de Privacidad. Se acuerda expresamente que en cualquier momento el usuario inscrito en PRIMARY S.A. podrá solicitar la baja o cierre de su solicitud de inscripción, y/o el cierre de su cuenta o eliminación de su cuenta. </p>

          <p>PRIMARY S.A podrá enviar información o mensajes por e-mail sobre nuevos servicios, mostrar publicidad o promociones, banners, de interés para los usuarios, noticias además de la información expresamente autorizada en la sección de preferencias. Si el usuario lo prefiere, puede solicitar que lo excluyan de las listas para el envío de información promocional o publicitaria al correo store@primary.com.ar </p>

          <p>Confidencialidad de la Información </p>

          <p>Una vez inscripto en el Sitio Web, ni PRIMARY S.A. ni sus empresas vinculadas cederán ni compartirán la Información Personal salvo en las formas establecidas en estas políticas, y con excepción de órdenes judiciales o de regulaciones legales vigentes. PRIMARY S.A y/o sus empresas vinculadas no responderán por la información que sea revelada. El usuario autoriza a PRIMARY S.A y a sus empresas vinculadas a compartir su Información Personal con compañías de análisis de riesgo, para usarla con fines estadísticos, de control, de supervisión y/o comerciales así como para reportar el cumplimiento de las obligaciones derivadas de los términos y condiciones así como de las licencias de los software, aplicaciones y/o desarrollos a los cuales acceden por medio del Sitio Web a bancos, asociaciones bancarias y/o financieras del país y a cualquier otra organización que mantenga bases de datos con estos fines. El usuario autoriza a PRIMARY S.A a consultar la información proporcionada por bureaus de crédito o compañías de análisis de riesgo o cualquier otra organización referida a los aspectos antes mencionada. </p>

          <p>Cookies </p>

          <p>El usuario y el visitante del Sitio Web conoce y acepta que PRIMARY S.A. podrá utilizar un sistema de seguimiento mediante la utilización de cookies (las "Cookies"). Las Cookies son pequeños archivos que se instalan en el disco rígido, con una duración limitada en el tiempo que ayudan a personalizar los servicios. Las Cookies se utilizan con el fin de conocer los intereses, el comportamiento y la demografía de quienes visitan o son usuarios del Sitio Web y de esa forma, comprender mejor sus necesidades e intereses y darles un mejor servicio o proveerle información relacionada. También usaremos la información obtenida por intermedio de las Cookies para analizar las páginas navegadas por el visitante o Usuario, las búsquedas realizadas, mejorar nuestras iniciativas comerciales y promocionales, mostrar publicidad o promociones, banners de interés, noticias sobre PRIMARY S.A., perfeccionar la oferta de los software, apps y/o desarrollos a los cuales acceden por medio del Sitio Web y/o comercializados por PRIMARY S.A, así como personalizar dichos contenidos, presentación y servicios; también podremos utilizar Cookies para promover y hacer cumplir las reglas y seguridad del Sitio Web. PRIMARY S.A. podrá agregar Cookies en los e-mails que envíe para medir la efectividad de las promociones. Utilizamos adicionalmente las Cookies para que el usuario no tenga que introducir su clave tan frecuentemente durante una sesión de navegación, también para contabilizar y corroborar las inscripciones, la actividad del usuario y otros conceptos para acuerdos comerciales, siempre teniendo como objetivo de la instalación de las Cookies, el beneficio del usuario que la recibe, y no será usado con otros fines ajenos a PRIMARY S.A. </p>

          <p>Se establece que la instalación, permanencia y existencia de las Cookies en el computador del usuario depende de su exclusiva voluntad y puede ser eliminada de su computador cuando el usuario así lo desee. Se aclara expresamente que estas políticas cubre la utilización de Cookies por este sitio y no la utilización de Cookies por parte de anunciantes. Nosotros no controlamos el uso de Cookies por terceros. PRIMARY S.A. no se hace responsable por interceptaciones ilegales o violación de sus sistemas o bases de datos por parte de personas no autorizadas. PRIMARY S.A. Tampoco se hace responsable por la indebida utilización de la información obtenida por esos medios. </p>

          <p>Responsable del uso de los datos recabados </p>

          <p>Nuestro Responsable de las bases de datos es el Sr Jose Luis Onis, a quien podrás contactar por la dirección de correo electrónico jonis@primary.ar </p>

          <p>Período de vigencia de los datos </p>

          <p>Los datos personales de los usuarios estarán vigentes durante el plazo necesario para cumplir los usos y finalidades descritos en las Políticas de Privacidad o en los Términos y Condiciones. </p>

          <p>Jurisdicción y Ley Aplicable </p>

          <p>Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la República de Argentina y se regirá por el idioma castellano. </p>

          <p>Domicilio </p>

          <p>Se fija como domicilio de PRIMARY S.A el de calle Paraguay 777 piso 15 de Rosario, Pcia. de Santa Fe, Argentina. 
          Ante cualquier duda podrá comunicarse con store@primary.com.ar </p>
        </div>
      </DetailsPanel>
    )
  }
}

const style = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '20px',
  alignSelf: 'center',
  justifyContent: 'center',
  color: "white",

  '& > p': {
    padding: '0 20px',
    marginBottom: '12px'
  }
})
