import { Action } from '../actions/instruments'
import { InstrumentsByCategory } from '../types/classes-instrument';

export type State = RequestStatus<InstrumentsByCategory | null>

const initialState: State = {
  status: 'pending',
  error: null,
  data: null
}

export const instruments = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'REQUEST_INSTRUMENTS':
      return {
        status: 'fetching',
        error: null,
        data: null
      }
    case 'RECEIVE_INSTRUMENTS':
      return {
        status: 'succeeded',
        error: null,
        data: action.instruments
      }
    case 'FAIL_REQUESTING_INSTRUMENTS':
      return {
        status: 'errored',
        error: action.error,
        data: null
      }
    default:
      return state
  }
}
