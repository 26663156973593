import * as React from 'react'
import * as moment from 'moment'
import { css } from 'glamor'
import { Colors } from 'types/colors'

import MarketDataInfo from './MarketDataInfo'
import { Carousel } from 'components/Carousel'
import { nFormatter } from 'types/classes-instrument'

interface Props {
  marketData: MarketData | null
  maturityDate?: Date
  formatter: Intl.NumberFormat
}

export default class MarketDataCarousel extends React.PureComponent<Props> {

  get closeValue() {
    const { marketData, formatter } = this.props
    if (marketData && marketData.CL) {
      return formatter.format(marketData.CL.price)
    }
    return '--'
  }

  get openValue() {
    const { marketData, formatter } = this.props
    if (marketData && marketData.OP) {
      return formatter.format(marketData.OP)
    }
    return '--'
  }

  get maxValue() {
    const { marketData, formatter } = this.props
    if (marketData && marketData.HI) {
      return formatter.format(marketData.HI)
    }
    return '--'
  }

  get minValue() {
    const { marketData, formatter } = this.props
    if (marketData && marketData.LO) {
      return formatter.format(marketData.LO)
    }
    return '--'
  }

  volumenValue = (value: number) => {
    return nFormatter(value)
  }

  render() {
    const { marketData, maturityDate } = this.props
    return (
      <div {...style} >
        <Carousel 
          id={`market-data-carousel`} 
          backgroundColor={Colors.middleViolet}>
          <MarketDataInfo title="Cierre Anterior" icon="sunset.svg" value={this.closeValue}/>
          <MarketDataInfo title="Apertura" icon="sunrise.svg" value={this.openValue}/>
          <MarketDataInfo title="Máximo" icon="trending-up.svg" value={this.maxValue}/>
          <MarketDataInfo title="Mínimo" icon="trending-down.svg" value={this.minValue}/>
          {marketData && marketData.TV !== undefined && marketData.TV > 0 &&
            <MarketDataInfo title="Contratos Operados" icon="volume.svg" value={nFormatter(marketData.TV)}/>}
          {marketData && marketData.NV !== undefined && marketData.NV > 0 &&
            <MarketDataInfo title="Vol. Nominal Operado" icon="volume.svg" value={nFormatter(marketData.NV)}/>}
          {marketData && marketData.OI && marketData.OI.size > 0 &&
            <MarketDataInfo title="Interes Abierto" icon="volume.svg" value={nFormatter(marketData.OI.size)}/>}
          {maturityDate &&
            <MarketDataInfo title="Vencimiento" icon="maturity-date.svg" value={moment(maturityDate).format("DD/MM/YYYY")}/>}
        </Carousel>
      </div>
    );
  }
}

const style = css({
  margin: '20px',
  display: 'flex',
  overflow: 'auto',
  backgroundColor: Colors.middleViolet,
  borderRadius: '4pt',
})
