import * as React from 'react'

import App from './App'
import HomePanel from 'components/HomePanel'
import { trackEvent } from 'trackers'

export default class Home extends React.Component {

  componentDidMount() {
    trackEvent('home-open')
  }

  render() {
    return (
      <App page="home">
        <HomePanel />
      </App>
    )
  }
}