import * as React from 'react'
import * as chartjs from "chart.js";
import { default as ChartComponent }  from 'react-chartjs-2'
import 'chartjs-plugin-annotation'

export interface Props {
  data: any;//ChartData<chartjs.ChartData>;
  getDatasetAtEvent?(e: any): void;
  getElementAtEvent?(e: any): void;
  getElementsAtEvent?(e: any): void;
  height?: number;
  legend?: chartjs.ChartLegendOptions;
  onElementsClick?(e: any): void; // alias for getElementsAtEvent (backward compatibility)
  options?: any;
  plugins?: object[];
  redraw?: boolean;
  width?: number;
  datasetKeyProvider?: (any: any) => any;
}

export class Candlestick extends React.PureComponent<Props> {
  render() {
    return (
      <ChartComponent
        {...this.props} 
        // @ts-ignore
        type="candlestick"
      />
    )
  }
}