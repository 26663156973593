import * as React from 'react'
import { css } from 'glamor'
import { assetUrl } from 'utils/assets'
import { Colors } from 'types/colors'
import { Loading } from '../../Loading'
import Chart from '../../Chart'
import { RangeSelector } from 'components/RangeSelector'

const candleChartIcon = assetUrl('candle-chart.svg')
const lineChartIcon = assetUrl('line-chart.svg')
const expandChartIcon = assetUrl('expand.svg')

interface Props {
  symbol: string
  segmentStatus: SegmentStatus
  priceFormatter: Intl.NumberFormat
  historicalData: CandleHistoricalOffersData | null | 'error'
  selectedRange: GraphRange
  color: string
  closePrice?: number
  selectRange: (range: GraphRange) => void
  openFullScreenChart: () => any
}

interface State {
 type: 'line' | 'candlestick'
}

export default class InstrumentCharts extends React.PureComponent<Props, State> {
  state: State = {
    type: 'line'
  }

  get data(): CandleHistoricalOffersData | null {
    const historicalData = this.props.historicalData
    if (historicalData !== 'error' && historicalData !== null) {
      return historicalData
    }
    return null
  }

  get chartColor() {
    return (this.props.color === 'positive') ? Colors.green : Colors.red
  }

  dataDigested(data: CandleHistoricalOffersData): any {
    let { datetimeData, priceData, candleData, sizeData } = data
    if (this.props.selectedRange === '1D') {
      if (this.props.segmentStatus === 'CLOSED' && priceData.length < datetimeData.length) {
        const priceDataLength = priceData.length
        const diff = datetimeData.length - priceDataLength
        const extraPrices: number[] = Array(diff).fill(priceData[priceDataLength - 1])
        const extraCandles: CandleDataEntry[] = Array(diff).fill(candleData[priceDataLength - 1])
        // const extraVolumen: number[] = Array(diff).fill(0)

        candleData = candleData.concat(extraCandles)
        priceData = priceData.concat(extraPrices)
        // sizeData = sizeData.concat(extraVolumen)
      }
    }
    data.datetimeData = datetimeData
    data.candleData = candleData
    data.sizeData = sizeData
    return data
  }

  onExpandChart = () => {
    this.props.openFullScreenChart()
  }

  onChangeChartType = () => {
    this.setState({ type : this.state.type === 'line' ? 'candlestick' : 'line'})
  }

  selectRange = (range: GraphRange) => {
    return () => {
      this.props.selectRange(range)
    }
  }

  chartComponent(data: CandleHistoricalOffersData | null | 'error') {
    const { type } = this.state
    const { closePrice } = this.props
    if (data === null) {
      return <Loading />
    } else if (data === 'error') {
      return (<div>No hay datos</div>)
    } else if (data.priceData.length > 0) {
      const digestedData = type === 'line' ? this.dataDigested(data) : data.digestedCandleData
      const color = (this.props.color === 'positive') ? Colors.green : Colors.red
      const charts: ChartProps[] = [{
        title: 'Precio',
        data: digestedData.priceData,
        type,
        id: 'price',
        color,
        max: digestedData.maxPrice * 1.03,
        min: digestedData.minPrice * 0.97,
        drawYAxis: true,
        drawRemove: false,
        valueFormatter: this.props.priceFormatter,
      }, {
        title: 'Volumen',
        data: digestedData.sizeData,
        type: 'bar',
        color,
        id: 'size',
        max: digestedData.maxSize * 5,
        min: 0,
        drawYAxis: false,
        drawRemove: false,
        doNotDrawTooltip: true,
      }]
      if (closePrice) {
        charts.push({
          title: '0,00%',
          data: [closePrice],
          type: 'annotation',
          id: 'price',
          color: Colors.blue,
          max: digestedData.maxPrice * 1.03,
          min: digestedData.minPrice * 0.97,
          drawYAxis: false,
          drawRemove: false,
          doNotDrawTooltip: true,
          label: {
            position: 'right',
            content: '0,00%',
            enabled: true,
            fontColor: Colors.blue,
            fontStyle: 'normal',
            yAdjust: -12,
            backgroundColor: 'rgba(0,0,0,0)',
          }
        })
      }
      return (
        <Chart
          charts={charts}
          datetimeData={digestedData.datetimeData}
          maxXTicks={5}
          maxYTicks={5}
          drawGradient={true}
          drawGridLines={false}
          drawXAxis={true}
        />
      )
    } else {
      return (<div>No hay datos</div>)
    }
  }

  render() {
    const { selectedRange } = this.props
    const isCandle = this.state.type === "candlestick"
    const chartIcon = isCandle ? lineChartIcon : candleChartIcon
    return (
      <div {...style}>
        <div className="details-chart">
          {this.chartComponent(this.props.historicalData)}
        </div>
        <div className="bottom-chart">
          <RangeSelector
            color={this.chartColor}
            selectRange={this.selectRange}
            selectedRange={selectedRange}
          />
          <div className="item type" onClick={this.onChangeChartType}>
            <img alt={"chart"} className="icon" src={chartIcon}/>
          </div>
          <div className="item expand" onClick={this.onExpandChart}>
            <img alt={"chart"} className="icon expand" src={expandChartIcon}/>
            <p className='action'>EXPANDIR</p>
          </div>
        </div>
      </div>
    );
  }
}

const style = css({
  padding: '0',
  color: Colors.white,
  width: '100%',
  position: 'relative',

  '& > .details-chart': {
    height: '270px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 0 0 0',
    marginBottom: '12px'
  },

  '& > .bottom-chart': {
    display: 'flex',
    flexDirection: 'initial',
    alignItems: 'center',
  },

  '& > .bottom-chart > .item': {
    display: 'flex',
    borderRadius: '6px',
    flexDirection: 'row',
    cursor: 'pointer',
    margin: '0 0 0 12px',
    padding: '4px',

    '&:hover': {
      backgroundColor: Colors.lightViolet
    },

    '& > .icon': {
      width: '20px',
      height: '20px',
      alignSelf: 'center',
    },

    '& > .action': {
      marginLeft: '8px',
      fontSize: '120%'
    }
  },

  '& > .bottom-chart > .item.expand > .icon.expand': {
    width: '16px',
    height: '16px'
  },
})