import { Action } from '../actions/app'

export type State = {
  compactMode: boolean
  fullScreenChart: boolean
  indicators: Indicator[]
}

const initialState: State = {
  compactMode: false,
  fullScreenChart: false,
  indicators: []
}

export const app = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'COMPACT_MODE':
      return {
        ...state,
        compactMode: !state.compactMode
      }
    case 'FULL_SCREEN_CHART':
      return {
        ...state,
        fullScreenChart: !state.fullScreenChart
      }
    case 'UPDATE_INDICATORS':
      return {
        ...state,
        indicators: action.indicators
      }
    default:
      return state
  }
}
