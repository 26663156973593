import { Instrument } from '../types/classes-instrument';
import { Action as WebsocketAction } from '../websocket/actions'

export type Action =
  { type: 'ADD_TO_WATCHLIST', instrument: Instrument }
  | { type: 'REMOVE_FROM_WATCHLIST', instrumentTicker: string }
  | { type: 'WTFF' }

const addToWatchlist = (instrument: Instrument): Action => ({
  type: 'ADD_TO_WATCHLIST',
  instrument
})

const removeFromWatchlist = (instrumentTicker: string): Action => ({
  type: 'REMOVE_FROM_WATCHLIST',
  instrumentTicker
})

export const listenMarketData = (instrument: Instrument): WebsocketAction => ({
  type: 'WEBSOCKET_SEND',
  payload: websocketMessage(instrument.symbol, instrument.marketId).replace(/[\r\n]/g, '')
})

export const listenInstrument = (symbol: string, marketId: string): WebsocketAction => ({
  type: 'WEBSOCKET_SEND',
  payload: websocketMessage(symbol, marketId).replace(/[\r\n]/g, '')
})

export const listenInstruments = (instruments: Instrument[]): WebsocketAction => {
  const allMessages = instruments.map(i => `{"symbol":"${i.symbol}","marketId":"${i.marketId}"}`)
  const message = allMessages.join(',')
  return {
    type: 'WEBSOCKET_SEND',
    payload: `{"type":"smd","level":1,
      "entries":["BI","OF","LA","OP","CL","SE","OI","LO","HI","IV","TV","EV","NV"],
      "depth":5,
      "products":[${message}]}`.replace(/[\r\n]/g, '')
  }
}

export const followInstrument = (instrument: Instrument) => (dispatch: Function) => {
  dispatch(addToWatchlist(instrument))
  dispatch(listenMarketData(instrument))
}

export const unfollowInstrument = (instrumentTicker: string) => (dispatch: Function) => {
  dispatch(removeFromWatchlist(instrumentTicker))
}

const websocketMessage = (symbol: string, marketId: string) => 
  `{"type":"smd","level":1,
    "entries":["BI","OF","LA","OP","CL","SE","OI","LO","HI","IV","TV","EV","NV"],
    "depth":5,
    "products":[{"symbol":"${symbol}","marketId":"${marketId}"}]}`
