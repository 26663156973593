import * as React from 'react'
import { css } from 'glamor'

import { Colors } from '../types/colors'

export class Panel extends React.PureComponent {
  render() {
    return (
      <div {...style} className="panel">
        {this.props.children}
      </div>
    );
  }
}

const style = css({
  backgroundColor: Colors.lightViolet,
  border: 0,
  borderRadius: 0,
  padding: 0,
  width: '100%',
  height: '100%',
  overflowY: 'hidden',

  '&::-webkit-scrollbar' : {
    display: 'none'
  },
})
