import * as React from 'react'
import { css } from 'glamor'

import { Instrument } from 'types/classes-instrument'
import { Colors } from 'types/colors'
import { CandleStick } from './CandleStick';
import { ExpandIcon } from 'components/Icons';

interface Props {
  instrument: Instrument
  marketData?: MarketData
  position?: DetailedPositionType
  openNewOrder: (side: OrderSide, instrument: Instrument, offer?: OfferJSON) => any
  openInstrument: (nstrument: Instrument) => void
}

export default class CompactInstrumentCell extends React.PureComponent<Props> {

  get bidArray() {
    const { BI } = this.props.marketData || { BI: [] }
    return BI || []
  }

  get offerArray() {
    const { OF } = this.props.marketData || { OF: [] }
    return OF || []
  }

  openNewOrderModel = (side: OrderSide, offer?: OfferJSON) => () => {
    const { instrument, openNewOrder } = this.props
    if (instrument.type !== 'INDEX') {
      openNewOrder(side, instrument, offer)
    }
  }

  openInstrumentDetails = () => {
    const { instrument, openInstrument } = this.props
    openInstrument(instrument)
  }

  render() {
    const { instrument, marketData, position } = this.props
    const buyButtonClass = instrument.type === 'INDEX' ? 
      'column medium center' : 
      'column medium center buy-btn tooltip tooltip-bottom'
    return (
      <div {...compactStyles} className='row'>
        <div className="column medium">
          {instrument.ticker}
          <div className="expand-icon" onClick={this.openInstrumentDetails}>
            <ExpandIcon className="icon"/>
          </div>
        </div>
        <div className="column medium">{instrument.lastPriceStr(marketData)}</div>
        <div className={`column small ${instrument.returnColor(marketData)}`}>{instrument.dailyDiffStr(marketData)}</div>
        <div className={`column small ${instrument.returnColor(marketData)}`}>{instrument.dailyReturnStr(marketData)}</div>
        <div className="column small">{instrument.lastTimestampStr(marketData)}</div>
        <div className="column small">{instrument.volumeStr(marketData)}</div>
        <div className="column small">
          {position && <span>{position.instrumentCurrentSize} {position.settlementSuffix}</span>}
          {!position && <span>--</span>}
        </div>
        <div className="column big">
          <CandleStick
            formatter={instrument.priceFormatter}
            returnColor={instrument.returnColor(marketData)}
            max={instrument.maxPrice(marketData)}
            min={instrument.minPrice(marketData)}
            open={instrument.openPrice(marketData)}
            close={instrument.lastPrice(marketData)}
          />
        </div>
        <div className={buyButtonClass}
          data-tooltip="COMPRAR"
          onClick={this.openNewOrderModel("BUY", this.offerArray[0])}>
          {instrument.bestOfferStr(marketData)}
        </div>
        <div className={buyButtonClass}
          data-tooltip="VENDER"
          onClick={this.openNewOrderModel("SELL", this.bidArray[0])}>
          {instrument.bestBidStr(marketData)}
        </div>
      </div>
    )
  }
}

const compactStyles = css({
  height: '50px',
  padding: '0 12px',
  borderStyle: 'none none solid none',
  borderBottomWidth: '1pt',
  borderColor: Colors.darkLineViolet,
  backgroundColor: Colors.middleViolet,
  color: Colors.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '@media(max-width: 1230px)': {
    width: 'fit-content'
  },

  '&:hover': {
    backgroundColor: Colors.lightViolet,
  },

  '& > .column': {
    padding: '6px',
    display: 'flex',
    alignItems: 'center'
  },

  '& > .column.small': {
    width: '7%',
    maxWidth: '7%',
    minWidth: '74px',
  },

  '& > .column.medium': {
    width: '12%',
    maxWidth: '12%',
    minWidth: '127px',
  },

  '& > .column.big': {
    width: '15%',
    maxWidth: '15%',
    minWidth: '159px',
  },

  '& > .column.positive': {
    color: Colors.green,
  },

  '& > .column.negative': {
    color: Colors.red,
  },

  '& > .column.center': {
    textAlign: 'center',
    justifyContent: 'center',
    color: Colors.lightGray,
    fontSize: '90%',
  },

  '& > .column.buy-btn': {
    cursor: 'pointer',
    borderRadius: '4pt',
  },

  '& > .column.buy-btn:hover': {
    color: Colors.white,
    backgroundColor: Colors.darkViolet,
  },

  '& > .column > .expand-icon': {
    cursor: 'pointer',
    display: 'none',
    borderRadius: '4pt',
    marginLeft: '6px',
    backgroundColor: Colors.darkViolet
  },

  '& > .column > .expand-icon > .icon': {
    width: '12px',
    height: '12px',
    margin: '6px'
  },

  '& > .column > .expand-icon:hover': {
    backgroundColor: Colors.violet
  },

  '&:hover > .column > .expand-icon': {
    display: 'inherit'
  }

})