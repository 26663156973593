import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators, Action } from 'redux'
import { css } from 'glamor'

import { Colors } from 'types/colors'
import { Instrument, OptionInstrument } from 'types/classes-instrument'
import { openLoginModal } from 'actions/user'
import { openNewOrderModal } from 'actions/orders'
import { fullScreenChart } from 'actions/app'
import { fetchHistoricalOffers, chooseInstrument, popInstrument } from 'actions/instruments'
import { followInstrument, unfollowInstrument } from 'actions/watchlist'
import { State as MarketDataState } from 'reducers/market-data'
import { State as UserState } from 'reducers/user'
import { State as HistoricalDataState } from 'reducers/historical-data'
import { State as WatchlistState } from 'reducers/watchlist'
import { RootState } from 'reducers'

import InstrumentHeader from './InstrumentHeader'
import MarketDataCarousel from './MarketDataCarousel'
import PositionDataCarousel from './PositionDataCarousel'
import OptionsCarousel from './OptionsCarousel'
import BuyAndSellButtons from './BuyAndSellButtons'
import { optionsForInstrumentSelector } from 'selectors/instrument-details';

interface State {
  showBook: boolean
  selectedRange: GraphRange
}

interface Props {
  instrument: Instrument | null
  marketData: MarketDataState
  historicalData: HistoricalDataState
  user: UserState
  watchlist: WatchlistState
  options: OptionInstrument[] | null
}

interface ActionCreators {
  openLoginModal: () => any
  openNewOrderModal: (side: OrderSide, instrument: Instrument, offer?: OfferJSON) => any
  fetchHistoricalOffers: (instrument: Instrument, range: GraphRange) => any
  followInstrument: (instrument: Instrument) => any
  unfollowInstrument: (instrumentTicker: string) => any
  chooseInstrument: (instrument: Instrument) => any
  popInstrument: () => any
  fullScreenChart: () => any
}

const mapStateToProps = ({ 
  selectedInstrument, marketData, historicalData, 
  user, watchlist, instruments 
}: RootState) => ({
  instrument: selectedInstrument,
  marketData: marketData,
  historicalData: historicalData,
  watchlist:  watchlist,
  user: user,
  options: optionsForInstrumentSelector({ selectedInstrument, instruments })
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators(
    { openNewOrderModal, fetchHistoricalOffers, followInstrument, unfollowInstrument, 
      chooseInstrument, popInstrument, openLoginModal, fullScreenChart }, 
    dispatch
  )

class InstrumentDetails extends React.Component<Props & ActionCreators, State> {
  state: State = {
    showBook: false,
    selectedRange: '1D'
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (prevProps.instrument && this.props.instrument 
        && prevProps.instrument.symbol !== this.props.instrument.symbol) {
      this.setState({ showBook: false, selectedRange: '1D' })
    } 
  }

  openNewOrderModal = (side: OrderSide, offer?: OfferJSON) => {
    const { instrument, openNewOrderModal, openLoginModal, user } = this.props
    if (!user.loggedIn) {
      openLoginModal()
    } else if (instrument && user.loggedIn && user.selectedAccount) {
      openNewOrderModal(side, instrument, offer)
    }
  }

  selectRange = (range: GraphRange) => {
    const { instrument, fetchHistoricalOffers } = this.props
    this.setState({ selectedRange: range })
    if (instrument) {
      fetchHistoricalOffers(instrument, range)
    }
  }

  watchInstrument = () => {
    const { instrument, watchlist } = this.props
    if (instrument === null) { return }
    if (watchlist.all.indexOf(instrument.ticker) >= 0) {
      this.props.unfollowInstrument(instrument.ticker)
    } else {
      this.props.followInstrument(instrument)
    }
  }

  onClickOption = (instrument: Instrument) => {
    this.props.chooseInstrument(instrument)
  }

  popInstrument = () => {
    this.props.popInstrument()
  }

  get watched() {
    const { instrument, watchlist } = this.props
    if (instrument === null) { return false }
    return watchlist.all.indexOf(instrument.ticker) >= 0
  }

  get returnColor() {
    const { instrument } = this.props
    return (instrument) ? instrument.returnColor(this.marketData) : 'positive'
  }

  get marketData() {
    const { instrument, marketData } = this.props
    return marketData.all[instrument?.symbol || '']
  }

  get historicalData() {
    const { instrument, historicalData } = this.props
    const { selectedRange } = this.state
    if (instrument && historicalData.all[instrument.symbol]) {
      return historicalData.all[instrument.symbol][selectedRange]
    }
    return null
  }

  get position() {
    const { instrument, user : { detailedPositions : { data }} } = this.props
    return (instrument && data) ?
    data.positionFor(instrument.ticker) : 
    null
  }

  get emptyState() {
    return (
      <div {...emptyStyles} className="empty">
        <h4 className="title">Seleccioná un instrumento del listado para ver el detalle</h4>
      </div>
    )
  }

  toogleBook = () => {
    this.setState({ showBook: !this.state.showBook })
  }

  render() {
    const { instrument } = this.props
    const hasToShowInMobile = instrument !== null
    return (
      <div {...style(hasToShowInMobile)} className="panel">
        {!instrument && this.emptyState}
        <div className="panel-body">
          {instrument && <InstrumentHeader
            instrument={instrument}
            watched={this.watched}
            marketData={this.marketData}
            selectedRange={this.state.selectedRange}
            historicalData={this.historicalData}
            selectRange={this.selectRange}
            watchInstrument={this.watchInstrument}
            onClose={this.popInstrument}
            openFullScreenChart={this.props.fullScreenChart}
          />}
          {this.marketData && instrument &&
            <MarketDataCarousel marketData={this.marketData} formatter={instrument.priceFormatter} maturityDate={instrument.maturityDate}/>}
          {this.position && instrument &&
            <PositionDataCarousel 
              isFuture={instrument.isFuture} 
              position={this.position} 
              formatter={instrument.positionFormatter}
            />}
          {this.props.options && this.props.options.length > 0 &&
            <OptionsCarousel options={this.props.options} onClickOption={this.onClickOption}/>}
        </div>
        {instrument && instrument.type !== 'INDEX' &&
          <BuyAndSellButtons
            marketData={this.marketData}
            formatter={instrument.priceFormatter}
            showBook={this.state.showBook}
            toogleBook={this.toogleBook}
            openNewOrderModal={this.openNewOrderModal}
          />}
      </div>
    );
  }
}

const style = (hasToShowInMobile: boolean) => css({
  zIndex: 600,
  display: 'inherit',
  border: 0,
  borderRadius: 0,
  backgroundColor: Colors.lightViolet,
  width: `calc(100% - 450px)`,
  position: 'absolute',
  padding: 0,
  bottom: 0,
  top: 0,
  right: 0,
  left: '450px',
  overflowY: 'hidden',

  '@media(max-width: 900px)': {
    zIndex: 750,
    display: hasToShowInMobile ? 'inherit' : 'none',
    width: '100%',
    left: '0px',
  },

  '&::-webkit-scrollbar' : {
    display: 'none'
  },
  
  '& > .panel-body': {
    padding: '0 !important',
    color: Colors.white,

    '&::-webkit-scrollbar' : {
      display: 'none'
    },
  }
})

const emptyStyles = css({
  position: 'absolute',
  top: '0px !important',
  left: '0px !important',
  right: '0px !important',
  bottom: '0px !important',
  backgroundColor: Colors.lightViolet,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',

  '& > .title': {
    fontWeight: 250,
    color: Colors.lightGray,
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentDetails)
