import { apiPromiseClient } from './api-client'
import { transformAndFilterHermesInstruments } from './helpers/primary-helpers'
import { InstrumentsByCategory, Instrument, intervalDict, timeframeDict } from 'types/classes-instrument'
import { transformHermesHistoricalData } from './helpers/hermes-helpers'

export namespace HermesClient {

  const DEFAULT_BASE_URL = 'https://api.talaris.com.ar/'
  export var BASE_URL = DEFAULT_BASE_URL
  const headers = {
    'API-CLIENT': 'e5n8bc1wdihjvjyg2jgyqrpc99m3esg9prvglzi0xr',
    'API-SECRET': 'o1hgoot8tdclvflxg6eh5tkfnow73suk5na8fprpyi'
  }
 
  export const getInstruments = (broker: Broker | undefined = undefined): Promise<InstrumentsByCategory> => {
    let brokerSlug = 'demo'
    if (broker) {
      brokerSlug = broker.isXOMS ? broker.slug : ''
    }
    const request = {
      method: 'GET',
      url: BASE_URL + `v1/instruments/${brokerSlug}`,
      headers
    }
    return apiPromiseClient<HermesInstrumentsResponse>(request).then(r => transformAndFilterHermesInstruments(r))
  }

  export const getPrices = (instrument: Instrument, range: GraphRange): Promise<CandleHistoricalOffersData> => {
    const path = range === '1D' || range === '5D' ? 'prices' : 'dailyPrices'
    const request = {
      method: 'GET',
      url: BASE_URL + `v2/instruments/${encodeURIComponent(instrument.ticker)}/${path}?timeframe=${timeframeDict[range]}&interval=${intervalDict[range]}`,
      headers
    }
    return apiPromiseClient<HermesHistoricalDataResponse>(request).then(r => transformHermesHistoricalData(r))
  }
  
}
