import * as React from 'react'
import { css } from 'glamor'
import { Instrument } from 'types/classes-instrument'
import { Colors } from 'types/colors'
import { assetUrl } from 'utils/assets'

import InstrumentCharts from './InstrumentCharts'
import { OpenWatchlistIcon, CloseWatchlistIcon } from 'components/Icons'

const backIcon = assetUrl('back.svg')

interface Props {
  instrument?: Instrument
  watched: boolean
  marketData?: MarketData
  historicalData: CandleHistoricalOffersData | null | 'error'
  selectedRange: GraphRange
  selectRange: (range: GraphRange) => void
  watchInstrument: (instrument: Instrument) => any
  openFullScreenChart: () => any
  onClose: () => any
}

export default class InstrumentHeader extends React.PureComponent<Props> {

  onWatchInstrument = () => {
    const { instrument } = this.props
    if (instrument) { this.props.watchInstrument(instrument) }
    return
  }

  get ticker() {
    return (this.props.instrument) ? this.props.instrument.ticker : ''
  }

  get name() {
    const { instrument } = this.props
    if (instrument && instrument.type === 'FUTURE' && instrument.segmentSubtype) { 
      return instrument.segmentSubtype.name
    }
    return (instrument && instrument.name) ? instrument.name : ''
  }

  get lastPriceStr() {
    const { instrument, marketData } = this.props
    return (instrument) ? instrument.lastPriceStr(marketData) : ''
  }

  get dailyReturnStr() {
    const { instrument, marketData } = this.props
    return (instrument) ? instrument.dailyReturnStr(marketData) : ''
  }

  get returnColor() {
    const { instrument, marketData } = this.props
    return (instrument) ? instrument.returnColor(marketData) : 'positive'
  }

  render() {
    const { watched, instrument, historicalData, selectedRange, selectRange, openFullScreenChart, marketData } = this.props
    return (
      <div {...style} className={`panel-header ${this.returnColor}`}>
        <div className="back">
          <div onClick={this.props.onClose}>
            <img alt="Atras" className="backIcon" src={backIcon} />
          </div>
        </div>
        <div className="info">
          {instrument &&
            <div className="left center-vertically">
              <div className="ticker-watched">
                <div className="ticker bold">{this.ticker}</div>
                <div className={`btn ${this.returnColor}`} onClick={this.onWatchInstrument}>
                  {watched && <OpenWatchlistIcon className={`icon ${this.returnColor}`} />}
                  {!watched && <CloseWatchlistIcon className={`icon ${this.returnColor}`} />}
                  <p>{watched ? 'Siguiendo' : 'Seguir'}</p>
                </div>
              </div>
              <div className="name">{this.name}</div>
            </div>
          }
          <div className="right center-vertically">
            <div className="last-price">{this.lastPriceStr}</div>
            <div className="return">{this.dailyReturnStr}</div>
          </div>
        </div>
        {instrument && <InstrumentCharts
            symbol={instrument.symbol}
            segmentStatus={instrument.segmentStatus}
            historicalData={historicalData}
            selectedRange={selectedRange}
            selectRange={selectRange}
            color={this.returnColor}
            closePrice={instrument.closePrice(marketData)}
            priceFormatter={instrument.priceFormatter}
            openFullScreenChart={openFullScreenChart}
          />}
      </div>
    );
  }
}

const style = css({
  margin: '12px 20px',
  padding: '0',
  color: Colors.white,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: Colors.darkViolet,
  borderRadius: '4pt',
  borderStyle: 'solid',
  borderWidth: '1pt',

  '&.positive': {
    borderColor: Colors.green,
    color: Colors.green
  },

  '&.negative': {
    borderColor: Colors.red,
    color: Colors.red
  },

  '& > .back': {
    display: 'block',
    cursor: 'pointer'
  },

  '& > .back > div > .backIcon': {
    width: '23px',
    height: 'auto',
  },

  '& > .info > .center-vertically': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    height: '100%',
  },

  '& > .info > .left': {
    float: 'left',
    color: Colors.white
  },

  '& > .info > .left > .name': {
    marginTop: '6px',
    color: Colors.lightGray
  },

  '& > .info > .right': {
    float: 'right',
    textAlign: 'right',
  },

  '& > .info > .right > .last-price': {
    fontSize: '160%'
  },

  '& > .info > .right > .return': {
    fontSize: '130%'
  },

  '& > .info > .center-vertically > .ticker-watched > .ticker': {
    fontSize: '170%',
  },

  '& > .info > .center-vertically > .ticker-watched': {
    display: 'flex',
    alignItems: 'center',
  },

  '& > .info > .center-vertically > .ticker-watched > .btn': {
    backgroundColor: 'rgba(0,0,0,0.25)',
    borderColor: 'rgba(0,0,0,0.25)',
    borderWidth: '0',
    color: Colors.white,
    marginLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    height: '30px !important',
    border: 'solid 1px white',
    borderRadius: '3px'
  },

  '& > .info > .center-vertically > .ticker-watched > .positive': {
    borderColor: Colors.green,
    color: Colors.green
  },

  '& > .info > .center-vertically > .ticker-watched > .negative': {
    borderColor: Colors.red,
    color: Colors.red
  },

  '& > .info > .center-vertically > .ticker-watched > .btn:hover': {
    backgroundColor: Colors.lightViolet,
  },

  '& > .info > .center-vertically > .ticker-watched > .btn > p': {
    margin: '0 !important'
  },

  '& > .info > .center-vertically > .ticker-watched > .btn > .icon': {
    marginRight: '12px',
    width: '25px',
    height: 'auto',
    backgroundSize: 'cover',
  },

  '& > .info > .center-vertically > .ticker-watched > .btn > .icon.positive > path': {
    fill: Colors.green
  },

  '& > .info > .center-vertically > .ticker-watched > .btn > .icon.negative > path': {
    fill: Colors.red
  },
})
