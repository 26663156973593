import { Action } from '../actions/instruments'
import { Instrument } from '../types/classes-instrument'

export type State = Instrument | null

export const selectedInstrument = (state: State = null, action: Action): State => {
  switch (action.type) {
    case 'SELECT_INSTRUMENT':
      return action.instrument
    default:
      return state
  }
}
