import { Action } from '../actions/instruments'
import { Instrument } from '../types/classes-instrument'

export type State = Instrument[]

const initialState: State = []

export const instrumentHistory = (state: State = initialState, action: Action): State => {
  let newState = state
  switch (action.type) {
    case 'PUSH_HISTORY':
      newState.push(action.instrument)
      return newState
    case 'POP_HISTORY':
      newState.pop()
      return newState
    case 'CLEAR_HISTORY':
      return []
    default: break
  }
  return newState
}
