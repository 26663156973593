import * as React from 'react'
import { css } from 'glamor'
import { Dispatch, bindActionCreators, Action } from 'redux'
import { connect } from 'react-redux'
import { Colors } from 'types/colors'

import { Loading } from '../Loading'
import { DetailsPanel } from '../DetailsPanel'
import { NetworkError, LoginEmptyState, NotAccount } from '../EmptyState'
import OrderCell from './OrderCell'

import { State as UserState } from 'reducers/user'
import { AllOrdersState } from 'reducers/orders'
import { fetchAllOrders, cancelOrder } from 'actions/orders'
import { openLoginModal } from 'actions/user'
import { RootState } from 'reducers'

interface Props {
  orders: AllOrdersState
  user: UserState
}

interface ActionCreators {
  fetchAllOrders: (account: string | number) => any
  cancelOrder: (order: PrimaryOrderJSON) => any
  openLoginModal: () => any
}

const mapStateToProps = ({ orders, user }: RootState) => ({
  orders: orders.all,
  user: user
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators({ fetchAllOrders, cancelOrder, openLoginModal }, dispatch)

class OrdersPanel extends React.Component<Props & ActionCreators> {

  fetchOrders = () => {
    const { user : { selectedAccount }, fetchAllOrders } = this.props
    if (selectedAccount) { fetchAllOrders(selectedAccount.name) }
  }

  componentDidMount() {
    this.fetchOrders()
  }

  header = () => {
    return (
      <div className="row">
        <div className="column big">Instrumento</div>
        <div className="column">Tipo</div>
        <div className="column">Cantidad</div>
        <div className="column">Precio</div>
        <div className="column">Creada el</div>
        <div className="column">Estado</div>
        <div className="column small"/>
      </div>
    )
  }

  render() {
    const { orders, user, openLoginModal } = this.props
    const isLoggedIn = process.env.IS_BROWSER && user.loggedIn
    const hasAccount = user.selectedAccount !== null
    const loading = isLoggedIn && hasAccount && (orders.status === 'pending' || orders.status === 'fetching')
    const errored = isLoggedIn && hasAccount && orders.status === 'errored'
    const succeeded = isLoggedIn && hasAccount && orders.status === 'succeeded'
    return (
      <DetailsPanel title="Ordenes">
        <div {...style}>
          <h4 className="title">Mis Ordenes</h4>
          {process.env.IS_BROWSER && !user.loggedIn && <LoginEmptyState onRetry={openLoginModal}/>}
          {isLoggedIn && !hasAccount && <NotAccount />}
          {loading && <div className="center"><Loading /></div>}
          {errored && <NetworkError onRetry={this.fetchOrders}/>}
          {succeeded && orders.data && orders.data.length === 0 &&
            <div className="center">
              <p className="title">No tienes ordenes</p>
            </div>
          }
          {succeeded && orders.data && orders.data.length > 0 && this.header()}
          {succeeded && orders.data &&
            orders.data.map((order, i) => (
              <OrderCell
                key={i}
                order={order}
                onCancelOrder={this.props.cancelOrder}
              />
          ))}
        </div>
      </DetailsPanel>
    )
  }
}

const style = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignSelf: 'center',
  margin: '24px 0',
  padding: '12px',
  borderRadius: '5pt',
  backgroundColor: Colors.violet,

  '& > .title': {
    padding: '0 0 12px 0',
    color: Colors.lightGray,
    fontSize: '130%'
  },

  '& > .center': {
    width: '100%',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
  },

  '& > .center > .title': {
    color: Colors.white,
    fontSize: '125%',
    textAlign: 'center',
    alignSelf: 'center'
  },

  '& > .row': {
    fontSize: '90%',
    padding: '6px 12px',
    backgroundColor: Colors.darkLineViolet,
    display: 'flex'
  },

  '& > .row > .column': {
    width: '15%',
    minWidth: '15%',
    maxWidth: '15%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    color: Colors.lightGray,
    padding: 0
  },

  '& > .row > .column.big': {
    width: '20%',
    minWidth: '20%',
    maxWidth: '20%',
  },

  '& > .row > .column.small': {
    width: '5%',
    minWidth: '5%',
    maxWidth: '5%',
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPanel)
