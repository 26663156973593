import * as React from 'react'
import { css } from 'glamor'

import { assetUrl } from 'utils/assets'
import { Colors } from 'types/colors'
// import isMobile from 'utils/isMobile'

const defaultStyles = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  background: Colors.violet,
  color: Colors.white,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  '& > .principal > .icons': {
    display: 'flex',
    flexDirection: 'row',
  },
  
  '& > .principal > .icons > .icon': {
    height: '100px',
  },

  '& > .principal > .title': {
    margin: '30px 15px 30px 0px',
    textAlign: 'left',
    fontSize: '1.2rem',
  },

  '& > .principal > .donwload-box': {
    display: 'flex',
    flexDirection: 'row',
  },

  '& > .principal > .donwload-box > .download-button': {
    background: Colors.blue,
    color: 'white',
    borderRadius: '8pt',
    marginTop: '0',
    marginBottom: '30px',
    marginRight: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px'
  },

  '& > .principal > .donwload-box  > .download-button > .store-icon': {
    width: '24px',
    marginRight: '8px',
  },

  '& > .detail': {
    maxWidth: '50%',
  },

  '& > .detail > .screen': {
    width: "100%",
    maxWidth: '250px',
  },
})

//const iconTalaris = assetUrl('talaris-icon.svg')
const screenshotList = assetUrl('iphone-screen-list.png')
const iconMatriz = assetUrl('icon-matriz.svg')
const iconAndroid = assetUrl('icon-android.svg')
const iconIOS = assetUrl('icon-ios.svg')

const DesktopDownloadAppBanner = ({ styles = defaultStyles }) => (
  <div {...styles}>
    <div className="principal">
      <div className='icons'>
        <img alt="matriz" className="icon" src={iconMatriz}/>
      </div>
      <h5 className="title">¡Talaris ahora es matriz! Descargá la app en tu celular</h5>
      <div className='donwload-box'>
        <a className="download-button" href="https://apps.apple.com/ar/app/matriz-by-primary/id1066002526">
          <img alt="store" className="store-icon" src={iconIOS}/>
          <p>Descargar la app</p>
        </a>
        <a className="download-button" href="https://play.google.com/store/apps/details?id=com.primary.talaris&hl=es_AR&gl=US">
          <img alt="store" className="store-icon" src={iconAndroid}/>
          <p>Descargar la app</p>
        </a>
      </div>
    </div>
    <div className="detail">
      <img alt="screenshot" className="screen" src={screenshotList}/>
    </div>
  </div>
)

export default DesktopDownloadAppBanner
