import { Action } from '../websocket/actions'

export type State = {
  all: Dictionary<MarketData>
}

const initialState: State = {
  all: {}
}

export const marketData = (state: State = initialState, action: Action): State => {
  var newState = state.all
  switch (action.type) {
    case 'WEBSOCKET_MESSAGE':
      const response = JSON.parse(action.payload.data)
      if (response && response.instrumentId) {
        newState[response.instrumentId.symbol] = response.marketData
      }
      break
    default: break
  }
  return {
    all: newState
  }
}
