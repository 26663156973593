import * as React from 'react'
import { css, StyleAttribute } from 'glamor'
import { Colors } from 'types/colors'

import { ArrowLeftIcon, ArrowRightIcon } from 'components/Icons'

interface State {
  showNext: boolean
  showPrevious: boolean
}

interface Props {
  id: string
  backgroundColor?: string
  style?: StyleAttribute
}

//t = current time
//b = start value
//c = change in value
//d = duration
const easeInOutQuad = (t: number , b: number, c: number, d: number) => {
	t /= d/2;
	if (t < 1) return c/2*t*t + b;
	t--;
	return -c/2 * (t*(t-2) - 1) + b;
};

export class Carousel extends React.PureComponent<Props, State> {
  state: State = {
    showNext: true,
    showPrevious: false
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps !== this.props) {
      const showNext = (this.carousel.offsetWidth < this.carousel.scrollWidth)
      const showPrevious = this.carousel.scrollLeft > 0
      this.setState({ showNext, showPrevious })
    }
  }

  scrollTo(element: HTMLElement, to: number, duration: number) {
    const start = element.scrollLeft,
        change = to - start,
        increment = 20;
    var currentTime = 0
        
    const animateScroll = () => {        
        currentTime += increment;
        const val = easeInOutQuad(currentTime, start, change, duration)
        element.scrollLeft = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment)
        }
    }
    animateScroll();
  }

  nextPage = () => {
    const newScrollLeft = this.carousel.scrollLeft + this.offset
    this.scrollTo(this.carousel, newScrollLeft, 250)
    const showNext = !((newScrollLeft + this.offset) >= this.carousel.scrollWidth)
    this.setState({ showNext, showPrevious: true })
  }

  previousPage = () => {
    const newScrollLeft = this.carousel.scrollLeft - this.offset
    this.scrollTo(this.carousel, newScrollLeft, 250)
    const showPrevious = !(newScrollLeft <= 0)
    this.setState({ showNext: true, showPrevious })
  }

  get offset() {
    return this.carousel.offsetWidth * 0.75
  }

  get carousel() {
    return document.getElementById(this.carouselId)!
  }

  get carouselId(): string {
    return `carousel-${this.props.id}`
  }

  get leftArrowId(): string {
    return `left-arrow-${this.props.id}`
  }

  get rightArrowId(): string {
    return `right-arrow-${this.props.id}`
  }

  render() {
    const hasChildren = this.props.children !== undefined
    const { showPrevious, showNext } = this.state
    const styles = {
      ...style(this.props.backgroundColor),
      ...this.props.style
    }
    return (
      <div className="carousel-container" {...styles}>
        {hasChildren && showPrevious &&
          <div className="arrow left" id={this.leftArrowId} onClick={this.previousPage}>
            <ArrowLeftIcon className="icon"/>
          </div>}
        <div className="carousel" id={this.carouselId}>
          <div className="padding"/>
          {this.props.children}
          <div className="padding"/>
        </div>
        {hasChildren && showNext &&
          <div className="arrow right" id={this.rightArrowId} onClick={this.nextPage}>
            <ArrowRightIcon className="icon"/>
          </div>}
      </div>
    );
  }
}

const padding = '6px'
const arrowWidth = '40px'

const style = (color: string = Colors.middleViolet) => css({
  position: 'relative',
  overflow: 'hidden',

  '& > .carousel': {
    width: '100%',
    overflowX: 'hidden',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    margin: '9px 0',
    
    '::-webkit-scrollbar' : {
      display: 'none'
    }
  },

  '& > .carousel > .padding': {
    width: padding,
    maxWidth: padding,
    minWidth: padding,
  },

  '& > .arrow': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: arrowWidth,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px'
  },

  '& > .arrow > .icon': {
    width: '14px',
    height: '14px',
  },

  '& > .arrow > .icon > path': {
    fill: Colors.lightGray
  },

  '& > .arrow:hover > .icon > path': {
    fill: Colors.white
  },

  '& > .arrow.left': {
    left: 0,
    justifyContent: 'flex-start',
    backgroundImage: `linear-gradient(to right, ${color} 0%, ${color} 60%, rgba(0,0,0,0) 100%)`
  },

  '& > .arrow.right': {
    right: 0,
    justifyContent: 'flex-end',
    backgroundImage: `linear-gradient(to left, ${color} 0%, ${color} 60%, rgba(0,0,0,0) 100%)`
  }
})
