import * as React from 'react'
import { css } from 'glamor'
import { Colors } from 'types/colors'

import { OptionInstrument, Instrument } from 'types/classes-instrument'

interface Props {
  option: OptionInstrument
  onClick: (instrument: Instrument) => void
}

export default class OptionInfo extends React.PureComponent<Props> {

  selectOption = () => {
    this.props.onClick(this.props.option)
  }
  
  render() {
    return (
      <div {...style} onClick={this.selectOption}>
        <div className="title">{this.props.option.ticker}</div>
      </div>
    );
  }
}

const style = css({
  padding: '6px 0',
  marginRight: '12px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  width: '130px',
  minWidth: '130px',

  '&:hover': {
    cursor: 'pointer'
  },

  '& > .title': {
    color: Colors.white,
    textAlign: 'center',
    padding: '6px 9px',
    borderStyle: 'solid',
    borderWidth: '1pt',
    borderRadius: '3px',
    borderColor: Colors.blue,
    backgroundColor: Colors.darkViolet
  }
})
