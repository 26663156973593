import { createSelector } from 'reselect'
import { Instrument, InstrumentsByCategory } from 'types/classes-instrument'
import { SortByArray } from 'utils/helpers/primary-helpers'
import { union, values } from 'lodash'

const instrumentsDataSelector = (state: any) => state.instruments.data as InstrumentsByCategory | null

const tickerWatchlistSelector = (state: any) => state.watchlist.all as string[]

export const positionsDataSelector = (state: any) => state.user.detailedPositions.data as AccountReportDetailedType | null

export const indexInstrumentsSelector = createSelector(
  instrumentsDataSelector,
  (instruments: InstrumentsByCategory | null) => {
    if (instruments !== null && instruments.all !== null) { 
      const dolar = instruments?.futureInstruments?.filter(i => i.segmentSubtype?.subtype === 'dolar')[0]
      const indiceRofex20 = instruments?.futureInstruments?.filter(i => i.segmentSubtype?.subtype === 'indice-rofex-20')[0]
      const merval = instruments?.indexInstruments?.find(i => i.ticker === 'MERVAL')
      const rfx20 = instruments?.indexInstruments?.find(i => i.ticker === 'RFX20')
      const wti = instruments?.futureInstruments?.filter(i => i.segmentSubtype?.subtype === 'wti')[0]
      const soja = instruments?.futureInstruments?.filter(i => i.segmentSubtype?.subtype === 'soja')[0]
      const oro = instruments?.futureInstruments?.filter(i => i.segmentSubtype?.subtype === 'oro')[0]
      return [rfx20, merval, indiceRofex20, dolar, wti, soja, oro].filter(i => i !== undefined)
    }
    return []
  }
)

export const watchlistSelector = createSelector(
  tickerWatchlistSelector,
  instrumentsDataSelector,
  positionsDataSelector,
  (watchlist: string[], instruments: InstrumentsByCategory | null, positionsData: AccountReportDetailedType | null) => {
    if (instruments !== null && instruments.all !== null) { 
      var list: Instrument[] = []
      var tickerList = watchlist
      if (positionsData) {
        const positionsTicker = values(positionsData.positions).map(p => p.ticker)
        tickerList = union(tickerList, positionsTicker)
      }
      tickerList.forEach((ticker: string) => {
        let instrument = instruments.all[ticker] || instruments.optionInstruments[ticker]
        if (instrument) {
          list.push(instrument)
        }
      })
      SortByArray(list, 'ticker')
      return list
    }
    return [] 
  }
)