import * as React from 'react'
import { Switch, Route } from 'react-router'
import { Helmet } from 'react-helmet'

import { amplitudeScript } from './components/Analytics/Amplitude'
// import { hotjarScript } from './components/Analytics/Hotjar'

import Home from 'pages/Home'
import Account from 'pages/Account'
import Orders from 'pages/Orders'
import Terms from 'pages/Terms'
import Contact from 'pages/Contact'

export default class Routes extends React.Component {
  render() {
    return (
      <div>
        <Helmet defaultTitle="Talaris">
          <html lang="es-AR" />
          <meta content="text/html; charset=utf-8" httpEquiv="content-type" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
          <meta name="theme-color" content="#000000"/>
          <meta name="apple-itunes-app" content="app-id=1066002526"/>
          <meta name="google-site-verification" content="NyBSBjYSwn7EwWEruGE1lWkEBI1dld6HbdhzyNsXTyo" />
          <meta content="Talaris" name="og:title" />
          <meta content="Talaris" property="og:site_name" />
          <meta content="website" property="og:type" />
          <meta content="Plataforma de trading multi mercado - Rofex - Merval - Matba" name="description" />
          <meta content="Plataforma de trading multi mercado - Rofex - Merval - Matba" name="og:description" />
          <meta content="https://www.talaris.com.ar" name="og:url" />
          <script type="text/javascript">{amplitudeScript('74df83920a4f710b028609d1acf963a3')}</script>
          {/* <script type="text/javascript">{hotjarScript('681132')}</script> */}
        </Helmet>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/account" component={Account} />
          <Route path="/orders" component={Orders} />
          <Route path="/terms" component={Terms} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </div>
    )
  }
}