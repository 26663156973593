/**
 * Client-side Bugsnag initialization. This script should run as early as possible.
 */

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import * as React from 'react'

Bugsnag.start({
  appVersion: '1.4.0',
  apiKey: "e8aa2acb70b93937bcf7392c2f1bec30",
  releaseStage: process.env.NODE_ENV || 'development',
  enabledReleaseStages: ['production', 'staging', 'development'],
  plugins: [
    new BugsnagPluginReact(React)
  ]
})

export const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

