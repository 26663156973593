import { apiPromiseClient, ApiRequest } from './api-client'
import { Instrument, AccountReportDetailed } from '../types/classes-instrument'
import {
  dateString,
  transformAndFilterOrders,
  transformPrimaryIntraDayData,
  transformPrimaryIntraWeekData,
  mergePositionsAndDetailedPositions,
  transformPositionsResponse,
  transformPrimaryHistoricalData
} from './helpers/primary-helpers'
import * as moment from 'moment'

export namespace PrimaryClient {

  const DEFAULT_WEBSOCKET_URL = 'wss://api.primary.com.ar/'
  const DEFAULT_BASE_URL = 'https://api.primary.com.ar'
  const DEFAULT_URLS = { baseUrl: DEFAULT_BASE_URL, websocketUrl: DEFAULT_WEBSOCKET_URL }
  export var BASE_URL = DEFAULT_BASE_URL + '/rest/'
  export var WEBSOCKET_URL = DEFAULT_WEBSOCKET_URL
 
  export const setBroker = (broker?: Broker) => {
    const { baseUrl, websocketUrl } = broker || DEFAULT_URLS
    BASE_URL = baseUrl + '/rest/'
    WEBSOCKET_URL = websocketUrl
  }

  // Users
  export const defaultLogin = (): Promise<PrimaryResponse> => {
    return login('ebivachi', 'Emiliano7+')
  }

  export const login = (name: string, password: string, broker?: Broker): Promise<PrimaryResponse> => {
    setBroker(broker)
    let body = {
      username: name,
      password: password
    }
    if (broker && !broker.isXOMS) {
      body["brokerId"] = broker.brokerId
    }
    const request = {
      method: 'POST',
      url: BASE_URL + 'users/login',
      contentType: 'application/json',
      body: body
    }
    return apiPromiseClient<PrimaryResponse>(request)
      .then(r => {
        if (r.status === 'ERROR') { throw r }
        return r
      })
  }
  
  export const logout = () => {
    setBroker()
  }

  export const loginWithToken = (name: string, password: string, broker?: Broker): Promise<PrimaryResponse> => {
    const fetchConfig: RequestInit = {
      credentials: 'include',
      method: 'POST',
      mode: 'cors',
      headers: {
        'X-Username': name,
        'X-Password': password
      }
    }
    return fetch('https://api.primary.com.ar/auth/getToken', fetchConfig)
      .then<PrimaryResponse>(response => {
        return response.json()
      })
  }

  export const getAccounts = (broker?: Broker): Promise<PrimaryAccount[]> => {
    const request = {
      method: 'GET',
      url: (broker?.baseUrl || BASE_URL) + 'accounts'
    }
    return apiPromiseClient<ListOfAccountsResponse>(request).then(r => r.accounts)
  }

  export const loginAndMyInformation = 
    (name: string, password: string, broker: Broker): Promise<LoginResponse> => {
      setBroker(broker)
      return login(name, password, broker)
        .then(r => getAccounts())
        .then(accounts => {
          if (accounts && accounts.length > 0) {
            return getMyInformation(name, accounts, accounts[0], broker.name)
          }
          return { 
            name, 
            accounts, 
            brokerName: broker.name, 
            myAccountInformation: {} 
          }
        })
  }

  // tslint:disable-next-line:max-line-length
  export const getMyInformation = (name: string, accounts: PrimaryAccount[], account: PrimaryAccount, brokerName?: string): Promise<LoginResponse> => {
    return Promise.all([getDetailedPositionsAndPositions(account), getAccountReport(account)]).then(values => {
      return {
        name: name,
        brokerName: brokerName,
        accounts: accounts,
        selectedAccount: account,
        myAccountInformation: {
          detailedPositions: values[0],
          accountReport: values[1],
        }
      }
    })
  }

  export const getAccountReport = (account: PrimaryAccount): Promise<AccountReportJSON> => {
    const request = {
      method: 'GET',
      url: BASE_URL + `risk/accountReport/${account.name}`
    }
    return apiPromiseClient<AccountReportResponse>(request)
      .then(r => {
        if (r.status === 'ERROR') { throw r }
        return r
      })
      .then(r => r.accountData)
  }

  export const getDetailedPositionsAndPositions = (account: PrimaryAccount): Promise<AccountReportDetailed> => {
    return Promise.all([getPositions(account), getDetailedPositions(account)])
      .then(values => mergePositionsAndDetailedPositions(values[0], values[1]))
  }

  export const getDetailedPositions = (account: PrimaryAccount): Promise<AccountReportDetailed> => {
    const request = {
      method: 'GET',
      url: BASE_URL + `risk/detailedPosition/${account.name}`
    }
    return apiPromiseClient<AccountReportDetailedResponse>(request)
      .then(r => {
        if (r.status === 'ERROR') { throw r }
        return r
      })
      .then(r => new AccountReportDetailed(r.detailedPosition))
  }

  export const getPositions = (account: PrimaryAccount): Promise<Dictionary<Dictionary<PositionJSON>>> => {
    const request = {
      method: 'GET',
      url: BASE_URL + `risk/position/getPositions/${account.name}`
    }
    return apiPromiseClient<PositionsResponse>(request)
      .then(r => {
        if (r.status === 'ERROR') { throw r }
        return r
      })
      .then(r => transformPositionsResponse(r))
  }

  // Orders
  export const createNewOrder = (order: NewOrderJSON): Promise<OrderResponse> => {
    let url = BASE_URL + 'order/newSingleOrder?account=' + order.account.name +
      '&marketId=' + order.instrument.marketId +
      '&symbol=' + encodeURIComponent(order.instrument.symbol) +
      '&orderQty=' + order.orderQty +
      '&ordType=' + order.ordType +
      '&side=' + order.side +
      '&timeInForce=' + order.timeInForce

    if (order.ordType === 'LIMIT') {
      url = url + `&price=${order.price}`
    } else if (order.ordType === 'STOP_LIMIT') {
      url = url + `&price=${order.price}&stopPrice=${order.stopPrice}`
    }

    if (order.timeInForce === 'GTD') {
      url = url + `&expireDate=${order.expireDate}`
    }

    const request = { method: 'GET', url }
    return apiPromiseClient<OrderResponse>(request)
      .then(r => {
        if (r.status === 'ERROR') { throw r }
        return r
      })
  }

  export const getOrders = (account: string | number): Promise<PrimaryOrderJSON[]> => {
    const request = {
      method: 'GET',
      url: BASE_URL + 'order/all?accountId=' + account
    }
    return apiPromiseClient<ListOfOrdersResponse>(request)
      .then(r => {
        if (r.status === 'ERROR') { throw r }
        return r
      })
      .then(r => transformAndFilterOrders(r))
  }

  export const cancelOrder = (order: PrimaryOrderJSON): Promise<OrderResponse> => {
    const request = {
      method: 'GET',
      url: BASE_URL + `order/cancelById?clOrdId=${order.clOrdId}&proprietary=${order.proprietary}`
    }
    return apiPromiseClient<OrderResponse>(request)
      .then(r => {
        if (r.status === 'ERROR') { throw r }
        return r
      })
  }

  export const getMarketData = (instrument: Instrument): Promise<MarketData> => {
    const request = {
      method: 'GET',
      url: BASE_URL + 
        'marketdata/get?marketId=' + instrument.instrumentId.marketId +
        '&symbol=' + encodeURIComponent(instrument.symbol) +
        "&entries=BI,OF,LA,OP,CL,SE,OI,LO,HI,TV,EV,NV,IV" +
        "&depth=5"
    }
    return apiPromiseClient<MarketDataResponse>(request)
      .then(r => r.marketData)
  }

  // 'https://api.primary.com.ar/rest/data/getTrades?
  //   marketId=\(instrument.marketId)&symbol=\(instrument.symbol)&date=\(date)'
  export const getIntraDayMarketData = (instrument: Instrument): Promise<CandleHistoricalOffersData> => {
    const closeTime = (instrument.segmentSubtype) ? instrument.segmentSubtype.closeTime : ''
    const today = dateString()
    const request: ApiRequest = {
      method: 'GET',
      url: BASE_URL +
        'data/getTrades?marketId=' + instrument.primaryChartMarketId +
        '&symbol=' + encodeURIComponent(instrument.symbol) +
        '&date=' + today +
        '&' + instrument.externalSource
    }
    return apiPromiseClient<PrimaryHistoricalDataResponse>(request)
      .then(r => {
        if (r.status === 'ERROR') { throw r }
        return r
      })
      .then(r => transformPrimaryIntraDayData(r, closeTime))
  }

  // "https://api.primary.com.ar/rest/data/getTrades?
  // marketId=\(instrument.marketId)&symbol=\(instrument.symbol)&dateFrom=\(startDateStr)&dateTo=\(endDateStr)"
  export const getIntraWeekMarketData = (instrument: Instrument): Promise<CandleHistoricalOffersData> => {
    const endDate = dateString()
    const startDate = dateString(moment().subtract('7', 'day'))
    const request: ApiRequest = {
      method: 'GET',
      url: BASE_URL +
        'data/getTrades?marketId=' + instrument.primaryChartMarketId +
        '&symbol=' + encodeURIComponent(instrument.symbol) +
        '&dateFrom=' + startDate +
        '&dateTo=' + endDate +
        '&' + instrument.externalSource
    }
    return apiPromiseClient<PrimaryHistoricalDataResponse>(request)
      .then(r => {
        if (r.status === 'ERROR') { throw r }
        return r
      })
      .then(r => transformPrimaryIntraWeekData(r))
  }

  const intervalStringDict: Dictionary<string> = {
    '1D': '1',
    '5D': '5',
    '1M': '1',
    '3M': '3',
    '6M': '6',
    '1A': '12',
  }

  export const getHistoricalMarketData = (instrument: Instrument, range: GraphRange): Promise<CandleHistoricalOffersData> => {
    const startDate = dateString(moment().subtract(intervalStringDict[range], 'month'))
    const endDate = dateString()
    const request: ApiRequest = {
      method: 'GET',
      url: BASE_URL +
        'data/getTrades?marketId=' + instrument.primaryChartMarketId +
        '&symbol=' + encodeURIComponent(instrument.symbol) +
        '&dateFrom=' + startDate +
        '&dateTo=' + endDate +
        '&' + instrument.externalSource
    }
    return apiPromiseClient<PrimaryHistoricalDataResponse>(request)
      .then(r => transformPrimaryHistoricalData(r, range))
  }
}
