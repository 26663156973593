import { Action } from '../actions/instruments'

export type HistoricalData = {
  '1D': CandleHistoricalOffersData | null | 'error'
  '5D': CandleHistoricalOffersData | null | 'error'
  '1M': CandleHistoricalOffersData | null | 'error'
  '3M': CandleHistoricalOffersData | null | 'error'
  '6M': CandleHistoricalOffersData | null | 'error'
  '1A': CandleHistoricalOffersData | null | 'error'
}

export type State = {
  all: Dictionary<HistoricalData>
}

const initialState: State = {
  all: {}
}

export const historicalData = (state: State = initialState, action: Action): State => {
  var newState = state.all
  switch (action.type) {
    case 'REQUEST_HISTORICAL_DATA':
      if (newState[action.instrument.symbol] !== undefined &&
        newState[action.instrument.symbol][action.range] === 'error') {
          newState[action.instrument.symbol][action.range] = null
      }
      break
    case 'RECEIVE_HISTORICAL_DATA':
      if (newState[action.symbol] === undefined) {
        newState[action.symbol] = { 
          '1D': null,
          '5D': null,
          '1M': null,
          '3M': null,
          '6M': null,
          '1A': null
        }
      }
      newState[action.symbol][action.range] = action.historicalOffers
      break
    case 'FAIL_REQUESTING_HISTORICAL_DATA': {
      if (newState[action.symbol] === undefined) {
        newState[action.symbol] = { 
          '1D': null,
          '5D': null,
          '1M': null,
          '3M': null,
          '6M': null,
          '1A': null
        }
      }
      newState[action.symbol][action.range] = 'error'
      break
    }
    default: break
  }
  return {
    all: newState
  }
}
