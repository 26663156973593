import { Action } from '../websocket/actions'

export type State = {
  isOpen: boolean
}

const initialState: State = {
  isOpen: false
}

export const websocket = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'WEBSOCKET_OPEN':
      return  {
        ...state,
        isOpen: true
      }
    case 'WEBSOCKET_DISCONNECT':
    case 'WEBSOCKET_CLOSED':
      return  {
        ...state,
        isOpen: false
      }
    default: break;
  }
  return state
}
