import * as React from 'react'
import { css } from 'glamor'
import { Colors } from 'types/colors'
import { formatSize } from 'types/classes-instrument'

import { BookUpIcon } from 'components/Icons'

interface Props {
  showBook: boolean
  marketData: MarketData | null
  formatter: Intl.NumberFormat
  toogleBook: () => void
  openNewOrderModal: (side: OrderSide, offer?: OfferJSON) => void
}

export default class BuyAndSellButtons extends React.PureComponent<Props> {

  get bidArray() {
    const { BI } = this.props.marketData || { BI: [] }
    return BI || []
  }

  get offerArray() {
    const { OF } = this.props.marketData || { OF: [] }
    return OF || []
  }

  openNewOrderModal = (side: OrderSide, offer?: OfferJSON) => () => {
    this.props.openNewOrderModal(side, offer)
  }

  priceFor(offer?: OfferJSON) {
    if (offer) {
      return this.props.formatter.format(offer.price)
    }
    return '--'
  }

  sizeFor(offer?: OfferJSON) {
    if (offer) {
      return formatSize(offer.size)
    }
    return ''
  }

  buttonFor = (side: OrderSide, offer?: OfferJSON) => {
    const sideStr = side === 'SELL' ? 'VENDER' : 'COMPRAR'
    return (
      <div className="btn-side" onClick={this.openNewOrderModal(side, offer)}>
        <div className="top">
          <div className="side">{sideStr}</div>
          <div className="size">{this.sizeFor(offer)}</div>
        </div>
        <div className="price">{this.priceFor(offer)}</div>
      </div>
    )
  }

  offerRow = (offer: OfferJSON, index: number) => {
    const priceStr = this.props.formatter.format(offer.price)
    const sizeStr = formatSize(offer.size)
    return <div key={index}>{`${sizeStr} x ${priceStr}`}</div>
  }

  listOfOffer = (title: string, offers: OfferJSON[]) => {
    return (
      <div className="offers">
        <p className="title">{title}</p>
        {offers.map((off, i) => this.offerRow(off, i)) }
      </div>
    )
  }

  render() {
    const { showBook, toogleBook } = this.props
    return (
      <div className="panel-footer" {...style}>
        <div className="actions">
          <div className="left">
            {this.buttonFor("SELL", this.bidArray[0])}
            {this.buttonFor("BUY", this.offerArray[0])}
          </div>
          <div className="right" onClick={toogleBook}>
            <p>Ver libro de ofertas</p>
            <BookUpIcon className="icon"/>
          </div>
        </div>
        {showBook &&
          <div className="book">
            {this.listOfOffer("VENTA", this.bidArray)}
            {this.listOfOffer("COMPRA", this.offerArray)}
          </div>
        }
      </div>
    );
  }
}

const style = css({
  margin: '0 20px',
  padding: '0 !important',
  backgroundColor: Colors.darkViolet,
  display: 'flex',
  flexDirection: 'column',
  borderStyle: 'none',
  borderRadius: '8pt 8pt 0 0',
  color: Colors.white,

  '& > .actions': {
    width: '100%',
    height: '90px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '15px'
  },

  '& > .book': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0 20%'
  },

  '& > .book > .offers': {
    width: "50%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 5px 15px 5px'
  },

  '& > .book > .offers > .title': {
    color: Colors.lightGray,
    marginBottom: '6px'
  },

  '& > .actions > .left': {
    display: 'flex',
    alignItems: 'stretch'
  },

  '& > .actions > .right': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.lightGray,
    cursor: 'pointer'
  },

  '& > .actions > .right > .icon': {
    width: '15px',
    marginLeft: '6px',
  },

  '& > .actions > .right > .icon > path': {
    fill: Colors.lightGray
  },

  '& > .actions > .right:hover': {
    color: Colors.white,
  },

  '& > .actions > .right:hover > .icon > path': {
    fill: Colors.white
  },

  '& > .actions > .left > .btn-side': {
    width: '150px',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '15px',
    textAlign: 'center',
    backgroundColor: Colors.darkViolet,
    border: 'solid 1px white',
    borderRadius: '4pt'
  },

  '& > .actions > .left > .btn-side:hover': {
    cursor: 'pointer',
    backgroundColor: Colors.lightViolet,
  },

  '& > .actions > .left > .btn-side > .top': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '95%'
  },

  '& > .actions > .left > .btn-side > .top > .side': {
    color: Colors.lightGray
  },

  '& > .actions > .left > .btn-side > .price': {
    fontSize: '135%'
  },
})
