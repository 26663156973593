import * as React from 'react'
import { css } from 'glamor'
import { Colors } from 'types/colors'
import { TrashIcon } from 'components/Icons'

import { formatSize, defaultPriceFormatter } from 'types/classes-instrument'

interface Props {
  order: PrimaryOrderJSON
  onCancelOrder: (order: PrimaryOrderJSON) => any
}

interface State {
  isCanceling: boolean
}

export default class OrderCell extends React.PureComponent<Props> {
  state: State = {
    isCanceling: false
  }

  cancelOrder = () => {
    this.props.onCancelOrder(this.props.order)
    this.setState({ isCanceling: true })
  }

  get priceStr() {
    const { order } = this.props
    return `${order.orderQty} x $${order.price}`
  }

  get ticker() {
    const { order } = this.props
    let array = order.instrumentId.symbol.split(' - ')
    const ticker = (array.length > 2) ? array[2] : order.instrumentId.symbol
    const settlement = (array.length > 3) ? ` (${array[3]})` : ''
    return ticker + settlement
  }

  get transactionTime() {
    const array = this.props.order.transactTime.split('-')
    if (array.length > 1) {
      return array[1].split(".")[0]
    }
    return this.props.order.transactTime
  }

  get isActive() {
    return this.state.isCanceling == false &&
      (this.props.order.status === 'NEW' ||
      this.props.order.status === 'PENDING_NEW' ||
      this.props.order.status === 'PARTIALLY_FILLED')
  }

  get statusClassName() {
    switch (this.props.order.status) {
      case 'NEW':
      case 'PENDING_NEW':
      case 'PENDING_CANCEL':
        return 'status blue'
      case 'REJECTED':
      case 'CANCELLED':
      case 'OUT_PARTIALLY_FILLED':
        return 'status red'
      case 'PARTIALLY_FILLED':
      case 'FILLED':
        return 'status green'
      case 'EXPIRED':
        return 'status gray'
      default:
        return 'status blue'
    }
  }

  get status() {
    const order = this.props.order
    switch (order.status) {
      case 'NEW': return order.ordType === 'STOP_LIMIT' ? 'Stop limit' : 'Aceptada'
      case 'PENDING_NEW': return 'Pendiente'
      case 'PARTIALLY_FILLED': return 'Parc. operada'
      case 'PENDING_CANCEL': return 'Cancelando'
      case 'REJECTED': return 'Rechazada'
      case 'CANCELLED': return 'Cancelada'
      case 'OUT_PARTIALLY_FILLED': return 'Cancelada'
      case 'FILLED': return 'Operada'
      case 'EXPIRED': return 'Vencida'
      default: return ''
    }
  }

  get side() {
    switch (this.props.order.side) {
      case 'BUY': return 'COMPRA'
      case 'SELL': return 'VENTA'
      default: return ''
    }
  }

  get isFilled() {
    return this.props.order.status === 'OUT_PARTIALLY_FILLED'
      || this.props.order.status === 'PARTIALLY_FILLED'
      || this.props.order.status === 'FILLED'
  }

  get price() {
    const price = this.isFilled ? this.props.order.lastPx : parseFloat(this.props.order.price)
    return defaultPriceFormatter.format(price)
  }

  get size() {
    let totalSize = formatSize(parseInt(this.props.order.orderQty, 10))
    let partialSize = formatSize(this.props.order.cumQty)
    return `${partialSize}/${totalSize}`
  }

  get message() {
    if (this.props.order.status === 'PARTIALLY_FILLED' || this.props.order.status === 'OUT_PARTIALLY_FILLED') {
      return `Se operaron ${this.props.order.cumQty} de ${this.props.order.orderQty}`
    }
    return this.props.order.text
  }

  get hasToShowMessage() {
    return this.message && this.message.length > 0 && 
      (this.props.order.status === 'REJECTED' ||
      this.props.order.status === 'PARTIALLY_FILLED' ||
      this.props.order.status === 'OUT_PARTIALLY_FILLED')
  }

  render() {
    const rowClass = this.hasToShowMessage ? "row tooltip tooltip-bottom" : "row"
    return (
      <div className="container" {...styles}>
        <div className={rowClass} data-tooltip={this.message} >
          <div className="column big white">{this.ticker}</div>
          <div className="column">{this.side}</div>
          <div className="column">{this.size}</div>
          <div className="column">{this.price}</div>
          <div className="column">{this.transactionTime}</div>
          <div className="column"><div className={this.statusClassName}>{this.status}</div></div>
          <div className="column small">
            {this.isActive && <div className="delete" onClick={this.cancelOrder}>
                <TrashIcon className="icon"/>
              </div>}
          </div>
        </div>
      </div>
    );
  }
}

const styles = css({
  padding: 0,

  '& > .row': {
    width: '100%',
    color: Colors.white,
    borderBottom: `1pt solid ${Colors.darkLineViolet}`,
    backgroundColor: Colors.darkViolet,
    padding: '12px',
    display: 'flex'
  },

  '& > .row > .column': {
    width: '15%',
    minWidth: '15%',
    maxWidth: '15%',
    height: '32px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    color: Colors.white,
    fontSize: '110%',
    padding: 0,
  },

  '& > .row > .column.big': {
    width: '20%',
    minWidth: '20%',
    maxWidth: '20%',
  },

  '& > .row > .column.small': {
    width: '5%',
    minWidth: '5%',
    maxWidth: '5%',
  },

  '& > .row > .column > .status': {
    width: '100%',
    height: '100%',
    margin: '2px 6px',
    borderRadius: '4pt',
    fontSize:'14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& > .row > .column > .status.blue': {
    backgroundColor: Colors.blue,
  },

  '& > .row > .column > .status.red': {
    backgroundColor: Colors.red,
  },

  '& > .row > .column > .status.green': {
    backgroundColor: Colors.green,
  },

  '& > .row > .column > .status.gray': {
    backgroundColor: Colors.gray,
  },

  '& > .row > .column > .delete': {
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    borderRadius: '4pt',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& > .row > .column > .delete:hover': {
    backgroundColor: Colors.lightViolet
  },

  '& > .row > .column > .delete > .icon': {
    width: '15px'
  },

  '& > .row > .column > .delete > .icon > path': {
    fill: Colors.white
  },

  '& > .row > .column > .delete:hover > .icon > path': {
    fill: Colors.red
  },
})
