import * as React from 'react'
import { Dispatch, bindActionCreators, Action } from 'redux'
import { connect } from 'react-redux'
import { css } from 'glamor'
import { groupBy } from 'lodash'

import { Colors } from 'types/colors'
import { Loading } from 'components/Loading'
import IndexCarousel from './IndexCarousel'
import CompactInstrumentList from './CompactInstrumentList'
import NormalInstrumentList from './NormalInstrumentList'
import Search from '../../Search'
import { EmptyWatchlist, ErrorWatchlist } from 'components/EmptyState'

import { Instrument } from 'types/classes-instrument'
import { RootState } from 'reducers'
import { State as InstrumentsState } from 'reducers/instruments'
import { State as WatchlistState } from 'reducers/watchlist'
import { State as MarketDataState } from 'reducers/market-data'
import { State as WebsocketState } from 'reducers/websocket'
import { State as UserState } from 'reducers/user'
import { chooseInstrument, fetchAllInstruments, clearHistorial } from 'actions/instruments'
import { compactMode } from 'actions/app'
import { openLoginModal } from 'actions/user'
import { listenInstruments, followInstrument, unfollowInstrument } from 'actions/watchlist'
import { openNewOrderModal } from 'actions/orders'
import { indexInstrumentsSelector, watchlistSelector, positionsDataSelector } from 'selectors/instruments-list';

interface Props {
  instrumentsData: InstrumentsState
  watchlistData: WatchlistState
  indexInstruments: Instrument[]
  watchlist: Instrument[]
  marketData: MarketDataState
  websocket: WebsocketState
  positionsData: AccountReportDetailedType | null
  selectedInstrument: Instrument | null
  compact: boolean
  user: UserState
}

interface ActionCreators {
  fetchAllInstruments: () => any
  openLoginModal: () => any
  openNewOrderModal: (side: OrderSide, instrument: Instrument, offer?: OfferJSON) => any
  chooseInstrument: (instrument: Instrument) => any
  listenInstruments: (instruments: Instrument[]) => any
  compactMode: () => any
  followInstrument: (instrument: Instrument) => any
  unfollowInstrument: (instrumentTicker: string) => any
  clearHistorial: () => any
}

const mapStateToProps = ({ 
  instruments, user, watchlist, marketData, 
  websocket, selectedInstrument, app 
}: RootState) => ({
  instrumentsData: instruments,
  watchlistData: watchlist,
  indexInstruments: indexInstrumentsSelector({ instruments }),
  watchlist: watchlistSelector({ watchlist, instruments, user }),
  marketData: marketData,
  websocket: websocket,
  positionsData: positionsDataSelector({ user }),
  user: user,
  selectedInstrument: selectedInstrument,
  compact: app.compactMode
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({ 
  chooseInstrument, listenInstruments, compactMode, clearHistorial, openLoginModal,
  fetchAllInstruments, followInstrument, unfollowInstrument, openNewOrderModal }, 
  dispatch)

class InstrumentList extends React.Component<Props & ActionCreators> {

  componentDidUpdate(prevProps: Props & ActionCreators) {
    if (this.props.websocket.isOpen && !prevProps.websocket.isOpen) {
      this.props.listenInstruments(this.props.indexInstruments)
      this.props.listenInstruments(this.props.watchlist)
    }
  }

  onClickInstrument = (instrument: Instrument) => {
    this.props.chooseInstrument(instrument)
  }

  onClickCompact = () => {
    this.props.clearHistorial()
    this.props.compactMode()
  }

  watchInstrument = (instrument: Instrument) => {
    if (this.props.watchlistData.all.indexOf(instrument.ticker) >= 0) {
      this.props.unfollowInstrument(instrument.ticker)
    } else {
      this.props.followInstrument(instrument)
    }
  }
  
  get watchlistDict(): Dictionary<Instrument[]> {
    return groupBy(this.props.watchlist, i => i.type)
  }

  focusOnSearch = () => {
    document.getElementById("search-input")!.focus()
  }

  openNewOrderModal = (side: OrderSide, instrument: Instrument, offer?: OfferJSON) => {
    const { openNewOrderModal, openLoginModal, user } = this.props
    if (!user.loggedIn) {
      openLoginModal()
    } else if (instrument && user.loggedIn && user.selectedAccount) {
      openNewOrderModal(side, instrument, offer)
    }
  }

  render() {
    const { 
      instrumentsData, watchlist, marketData, indexInstruments,
      positionsData, selectedInstrument, compact 
    } = this.props
    const loading = instrumentsData.status === 'fetching' || instrumentsData.status === 'pending'
    const errored = instrumentsData.status === 'errored'
    const succeeded = instrumentsData.status === 'succeeded'
    const panelClass = compact ? "panel compact" : "panel"
    return (
      <div {...style} className={panelClass}>
        <div className="panel-header" {...panelHeaderStyles}>
          <Search
            instrumentsState={instrumentsData}
            watchlist={this.props.watchlistData}
            selectInstrument={this.onClickInstrument}
            watchInstrument={this.watchInstrument}
            onClickCompact={this.onClickCompact}
            compact={compact}
          />
          <IndexCarousel 
            instruments={indexInstruments} 
            marketData={marketData}
            onClick={this.onClickInstrument}
            selectedInstrument={selectedInstrument}
          />
        </div>
        <div className="panel-body" {...bodyStyles}>
          {loading && <div {...centerStyles}><Loading /></div>}
          {errored && <ErrorWatchlist onRetry={this.props.fetchAllInstruments}/>}
          {succeeded && watchlist.length === 0 && <EmptyWatchlist onRetry={this.focusOnSearch} />}
          {succeeded && watchlist.length > 0 && !compact &&
            <NormalInstrumentList 
              instruments={this.watchlistDict}
              marketData={marketData}
              positionsData={positionsData}
              selectedInstrument={selectedInstrument}
              onClickInstrument={this.onClickInstrument} />}
          {succeeded && watchlist.length > 0 && compact &&
            <CompactInstrumentList 
              instruments={watchlist}
              marketData={marketData}
              positionsData={positionsData}
              openNewOrder={this.openNewOrderModal}
              openInstrument={this.onClickInstrument} />}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentList)

const panelHeaderStyles = css({
  padding: '0 !important',
})

const bodyStyles = css({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 !important',
  backgroundColor: Colors.middleViolet,

  '& > .list': {
    width: '100%'
  }
})

const centerStyles = css({
  position: 'absolute',
  top: '0px !important',
  bottom: '0px !important',
  left: '0px !important',
  right: '0px !important',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
})

const style = css({
  backgroundColor: Colors.middleViolet,
  padding: '0px',
  bottom: '0px',
  top: 0,
  right: 0,
  left: 0,
  position: 'absolute',
  overflowY: 'hidden',
  width: '450px',
  borderColor: Colors.darkLineViolet,
  borderStyle: 'none none none solid',
  borderWidth: '0 0 0 1pt',

  '@media(max-width: 900px)': {
    width: '100%',
  },

  '&::-webkit-scrollbar' : {
    display: 'none'
  },

  '&.compact': {
    width: '100%'
  }
})
