import * as React from 'react'
import { css } from 'glamor'
import { Dispatch, bindActionCreators, Action } from 'redux'
import { connect } from 'react-redux'

import { Colors } from 'types/colors'
import { SortByArray } from 'utils/helpers/primary-helpers'
import { Loading } from '../../Loading'
import { NetworkError } from '../../EmptyState'
import { CategorySection } from './CategorySection'

import { State as UserState } from 'reducers/user'
import { fetchDetailedPositions } from 'actions/user'
import { RootState } from 'reducers'

interface Props {
  user: UserState
}

interface ActionCreators {
  fetchDetailedPositions: (account: PrimaryAccount) => any
}

const mapStateToProps = ({ user }: RootState) => ({
  user: user
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators({ fetchDetailedPositions }, dispatch)

class MyPositionsModal extends React.Component<Props & ActionCreators, {}> {

  fetchPositions = () => {
    const { user, fetchDetailedPositions } = this.props
    if (user.selectedAccount) { fetchDetailedPositions(user.selectedAccount) }
  }

  componentDidMount() {
    this.fetchPositions()
  }

  section(category: string, accountReportDetailed: AccountReportDetailedType, key: number) {
    return (
      <CategorySection 
        category={category} 
        positions={SortByArray(accountReportDetailed.reportByContractType[category], "ticker")}
        marketValue={accountReportDetailed.marketValueFor(category)}
        totalDailyDiff={accountReportDetailed.totalDailyDiffFor(category)}
        totalDiff={accountReportDetailed.totalDiffFor(category)}
        dailyReturn={accountReportDetailed.dailyReturnFor(category)}
        totalReturn={accountReportDetailed.totalReturnFor(category)}
        key={key} 
      />
    )
  }

  allSections(accountReportDetailed: AccountReportDetailedType) {
    if (Object.keys(accountReportDetailed.reportByContractType).length === 0) {
      return (
        <div className="positions">
          <div className="list">
            <div className="empty-state">
              <p className="title">No tienes posiciones</p>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="positions">
          <div className="row">
            <div className="column big"/>
            <div className="column">ULTIMO PRECIO</div>
            <div className="column">GANANCIA DIARIA</div>
            <div className="column">RETORNO DIARIO</div>
            <div className="column">GANANCIA TOTAL</div>
            <div className="column">RETORNO TOTAL</div>
            <div className="column">VALOR DE MERCADO</div>
          </div>
          <div className="list">
            {Object.keys(accountReportDetailed.reportByContractType)
              .map((category, i) => this.section(category, accountReportDetailed, i))}
          </div>
        </div>
      )
    }
  }

  render() {
    const { user } = this.props
    const loading = user.detailedPositions.status === 'pending' || user.detailedPositions.status === 'fetching'
    const errored = user.detailedPositions.status === 'errored'
    const succeeded = user.detailedPositions.status === 'succeeded'
    const positions = user.detailedPositions.data

    return (
      <div {...style}>
        <h4 className="title">Mis Posiciones</h4>
        {loading && <Loading />}
        {errored && <NetworkError onRetry={this.fetchPositions}/>}
        {succeeded && positions && this.allSections(positions)}
      </div>
    )
  }
}

const style = css({
  display: 'inherit',
  flexDirection: 'column',
  padding: '12px',
  marginBottom: '24px',
  borderRadius: '5pt',
  backgroundColor: Colors.violet,
  width: '100%',

  '& > .title': {
    padding: '0 0 12px 0',
    color: Colors.lightGray,
    fontSize: '130%'
  },

  '& > .positions > .row': {
    width: '100%',
    display: 'flex',
    padding: '0 12px',
    backgroundColor: Colors.darkLineViolet
  },

  '& > .positions > .row > .column': {
    width: '14%',
    minWidth: '14%',
    maxWidth: '14%',
    textAlign: 'left',
    fontSize: '80%',
    padding: '6px',
    color: Colors.lightGray,
  },

  '& > .positions > .row > .column.big': {
    width: '16%',
    minWidth: '16%',
    maxWidth: '16%',
  },

  '& > .positions > .list': {
    margin: 0,
    width: '100%',
    padding: 0,
  },

  '& > .positions > .list > .empty-state > .title': {
    color: Colors.white,
    textAlign: 'center',
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(MyPositionsModal)
