import * as React from 'react'
import { css } from 'glamor'

import CompactInstrumentCell from './CompactInstrumentCell'
import { ArrowLeftIcon, ArrowRightIcon } from 'components/Icons'

import { State as MarketDataState } from 'reducers/market-data'
import { Instrument } from 'types/classes-instrument'
import { Colors } from 'types/colors'

interface Props {
  instruments: Instrument[], 
  positionsData: AccountReportDetailedType | null
  marketData: MarketDataState,
  openNewOrder: (side: OrderSide, instrument: Instrument, offer?: OfferJSON) => any
  openInstrument: (nstrument: Instrument) => void
}

interface State {
  sortBy: SortType
  sortDirection: 'asc' | 'desc'
}

export default class CompactInstrumentList extends React.PureComponent<Props, State> {
  state: State = {
    sortBy: 'ticker',
    sortDirection: 'asc'
  }

  onOrderBy = (sortBy: SortType) => () => {
    if (sortBy === this.state.sortBy) {
      const sortDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc'
      this.setState({ sortDirection })
    } else {
      this.setState({ sortBy, sortDirection: 'asc' })
    }
  }

  sortInstruments = (instruments: Instrument[], marketData: MarketDataState, sortBy: SortType, sortDirection: SortDirection) => {
    instruments.sort((a, b) => {
      const mdA = marketData.all[a.symbol]
      const mdB = marketData.all[b.symbol]
      let sortFunctionA: number | string = 0
      let sortFunctionB: number | string = 0
      if (sortBy === 'lastPrice') {
        sortFunctionA = a.lastPrice(mdA) || 0
        sortFunctionB = b.lastPrice(mdB) || 0
      } else if (sortBy === 'dailyDiff') {
        sortFunctionA = a.dailyDiff(mdA) || 0
        sortFunctionB = b.dailyDiff(mdB) || 0
      } else if (sortBy === 'dailyReturn') {
        sortFunctionA = a.dailyReturn(mdA) || 0
        sortFunctionB = b.dailyReturn(mdB) || 0
      } else if (sortBy === 'size') {
        sortFunctionA = a.size(mdA) || 0
        sortFunctionB = b.size(mdB) || 0
      } else if (sortBy === 'timestamp') {
        sortFunctionA = a.lastTimestamp(mdA) || 0
        sortFunctionB = b.lastTimestamp(mdB) || 0
      } else if (sortBy === 'ticker') {
        sortFunctionA = a.ticker
        sortFunctionB = b.ticker
      } else if (sortBy === 'position') {
        const positionA = this.positionForInstrument(a) || { instrumentCurrentSize: 0 }
        const positionB = this.positionForInstrument(b) || { instrumentCurrentSize: 0 }
        sortFunctionA = positionA.instrumentCurrentSize
        sortFunctionB = positionB.instrumentCurrentSize
      }

      if (sortFunctionA < sortFunctionB) {
        return sortDirection === 'asc' ? -1 : 1
      } else if (sortFunctionA > sortFunctionB) {
        return sortDirection === 'asc' ? 1 : -1
      }
      return 0
    })
  }

  positionForInstrument = (instrument: Instrument) => {
    const { positionsData } = this.props
    if (positionsData) {
      return positionsData.positionFor(instrument.ticker)
    }
    return undefined
  }


  compactHeaderColumn = (size: string, title: string, sortBy?: SortType) => {
    const isOrdered = sortBy === this.state.sortBy
    const clickeableClass = sortBy !== undefined ? 'clickeable' : ''
    const sortAsc = this.state.sortDirection === 'asc'
    const className = `column ${size} ${clickeableClass} ${isOrdered ? "ordered" : ""}`
    return (
      <div className={className} 
        onClick={sortBy ? this.onOrderBy(sortBy) : undefined}>
        {title}
        {isOrdered && sortAsc && <ArrowLeftIcon className="icon" />}
        {isOrdered && !sortAsc && <ArrowRightIcon className="icon" />}
      </div>
    )
  }

  render() {
    const { instruments, marketData, openNewOrder, openInstrument } = this.props
    const { sortBy, sortDirection } = this.state
    this.sortInstruments(instruments, marketData, sortBy, sortDirection)
    return (
      <div className="fix-table" {...style}>
        <div className="row header fix" {...headerStyles}>
          {this.compactHeaderColumn("medium", "INSTRUMENTO", "ticker")}
          {this.compactHeaderColumn("medium", "ULTIMO PRECIO", "lastPrice")}
          {this.compactHeaderColumn("small", "CAMBIO", "dailyDiff")}
          {this.compactHeaderColumn("small", "% CAMBIO", "dailyReturn")}
          {this.compactHeaderColumn("small", "HORA", "timestamp")}
          {this.compactHeaderColumn("small", "VOLUMEN", "size")}
          {this.compactHeaderColumn("small", "POSICION", "position")}
          {this.compactHeaderColumn("big", "RANGO DIARIO")}
          {this.compactHeaderColumn("medium center", "COMPRA")}
          {this.compactHeaderColumn("medium center", "VENTA")}
        </div>
        <div className="section">
          {instruments.map((instrument, i) => (
            <CompactInstrumentCell
              instrument={instrument}
              marketData={marketData.all[instrument.symbol]}
              position={this.positionForInstrument(instrument)}
              key={i}
              openNewOrder={openNewOrder}
              openInstrument={openInstrument}
            />))
          }
        </div>
      </div>
    )
  }

}

const style = css({
  position: 'relative',
  overflow: 'auto',
  width: '100%'
})

const headerStyles = css({
  padding: '0 12px',
  margin: 0,
  fontSize: '100%',
  backgroundColor: Colors.darkViolet,
  color: Colors.lightGray,
  borderColor: Colors.darkLineViolet,
  borderStyle: 'none none solid none',
  borderWidth: '0 0 1pt 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&.header.fix': {
    position: 'sticky',
    top: 0,
    height: '32px',
    zIndex: '200',

    '@media(max-width: 1230px)': {
      width: 'fit-content'
    },
  },

  '& > .column': {
    padding: '6px',
    fontSize: '95%'
  },

  '& > .column.clickeable': {
    cursor: 'pointer'
  },

  '& > .column > .icon': {
    width: '6px',
    height: 'auto',
    marginLeft: '3px',
    transform: 'rotate(-90deg)'
  },

  '& > .column > .icon > path': {
    fill: Colors.lightGray
  },

  '& > .column:hover > .icon > path': {
    fill: Colors.white
  },

  '& > .column.clickeable:hover': {
    color: Colors.white
  },

  '& > .column.ordered': {
    color: Colors.white
  },

  '& > .column.ordered > .icon > path': {
    fill: Colors.white
  },

  '& > .column.small': {
    width: '7%',
    maxWidth: '7%',
    minWidth: '74px',
  },

  '& > .column.medium': {
    width: '12%',
    maxWidth: '12%',
    minWidth: '127px',
  },

  '& > .column.big': {
    width: '15%',
    maxWidth: '15%',
    minWidth: '159px',
  },

  '& > .column.center': {
    textAlign: 'center'
  },
})

