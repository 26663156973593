import * as React from 'react'
import { css } from 'glamor'

import { Colors } from 'types/colors'
import { formatPrice, formatReturn } from 'types/classes-instrument'

interface Props {
  symbol: string
  position: DetailedPositionType
  sharesName: string
}

export default class PositionCell extends React.PureComponent<Props> {

  get marketValue() {
    const hasReturn = this.props.position.hasReturn
    const marketValue = hasReturn ? this.props.position.instrumentMarketValue : this.props.position.totalDiff
    return formatPrice(marketValue)
  }

  get totalDiff() {
    return formatPrice(this.props.position.totalDiff)
  }

  get dailyDiff() {
    return formatPrice(this.props.position.dailyDiff)
  }

  get totalReturn() {
    return formatReturn(this.props.position.totalReturn)
  }

  get dailyReturn() {
    return formatReturn(this.props.position.dailyReturn)
  }

  get marketPrice() {
    return formatPrice(this.props.position.marketPrice)
  }

  get dailyReturnColor() {
    const { position } = this.props
    return (position.dailyDiff || 0) >= 0 ? 'positive' : 'negative'
  }

  get totalReturnColor() {
    const { position } = this.props
    return (position.totalDiff || 0) >= 0 ? 'positive' : 'negative'
  }

  get size() {
    const { position, sharesName } = this.props
    return `${position.instrumentCurrentSize} ${sharesName}${position.settlementSuffix}`
  }

  render() {
    return (
      <div className="row" {...styles}>
        <div className="column instrument">
          <p className="ticker">{this.props.symbol}</p>
          <p className="size">{this.size}</p>
        </div>
        <div className={`column ${this.dailyReturnColor}`}>{this.marketPrice}</div>
        <div className={`column ${this.dailyReturnColor}`}>{this.dailyDiff}</div>
        <div className={`column ${this.dailyReturnColor}`}>{this.dailyReturn}</div>
        <div className={`column ${this.totalReturnColor}`}>{this.totalDiff}</div>
        <div className={`column ${this.totalReturnColor}`}>{this.totalReturn}</div>
        <div className="column total"><span>{this.marketValue}</span></div>
      </div>
    );
  }
}

const styles = css({
  minHeight: '60px',
  padding: '6px 12px',
  margin: '0',
  borderStyle: 'none none solid none',
  borderBottomWidth: '1pt',
  borderColor: Colors.darkLineViolet,
  color: Colors.white,
  display: 'flex',
  flexDirection: 'row',

  '& > .column': {
    width: '14%',
    minWidth: '14%',
    maxWidth: '14%',
    display: 'flex',
    alignItems: 'center'
  },

  '& > .instrument': {
    width: '16% !important',
    minWidth: '16% !important',
    maxWidth: '16% !important',
    display: 'flex',
    flexDirection: 'column',
  },

  '& > .instrument > p': {
    width: '100%',
    margin: '0 !important',
    textAlign: 'left'
  },

  '& > .column.instrument > .size': {
    fontSize: '75%',
    color: Colors.gray
  },

  '& > .positive': {
    color: Colors.green
  },

  '& > .negative': {
    color: Colors.red
  },

  '& > .total': {
    justifyContent: 'center'
  },

  '& > .total > span': {
    width: '100%',
    padding: '6px',
    backgroundColor: Colors.darkLineViolet,
    borderRadius: 4,
    textAlign: 'center'
  }
})
