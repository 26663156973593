export namespace Colors {
  export const lightViolet = '#171835'
  export const violet = '#12132A'
  export const middleViolet = '#111324'
  export const darkViolet = '#0D0F1C'
  export const darkLineViolet = "#06070E"//"#080A1C"
  export const green = '#3FB498'
  export const red = '#FF624F'
  export const blue = '#2299DF'
  export const lightBlue = '#3FAFF4'
  export const gray = '#DDDDDD'
  export const lightGray = '#6C6C6C'
  export const backgroundGray = '#f8f9fa'
  export const white = 'white'

  export const yellow = '#fff700'
  export const orange = '#fcba03'
  export const greenLight = '#a6ff00'
  export const magent = '#f700ff'
  export const fuxia = '#f70066'
  export const furiusRed = '#ff0000'
  export const furiusBlue = '#0011ff'
  export const furiusGreen = '#005916'
  export const cian = '#00fbff'

  export const array = [blue, orange, furiusRed, yellow, magent, furiusBlue, greenLight, cian, furiusGreen]
}

export const colorLuminance = (hex: string, lum: number) => {

  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#", c, i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i*2,2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
    rgb += ("00"+c).substr(c.length);
  }

  return rgb;
}