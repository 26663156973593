/*
 * This config is the main entry point to access the config file in the browser and also in the server.
 * This file is not transpiled by babel, so it must use ES5 syntax
 */
/* eslint-disable no-var */

declare var window: { App: any }

var config = process.env.IS_BROWSER ? window.App.config : require('../server/config')
var assetHost = config.assetHost
var assets = config.assets

var requireAsset = (asset: string) => {
  return require('../assets/' + asset)
}

export const mapAsset = (asset: string) => {
  // skip external urls
  if (asset.match(/\/\//)) { return asset }
  return [assetHost, assets[asset] || asset].join('/')
}

export const assetUrl = (asset: string) => {
  return process.env.IS_BROWSER ? requireAsset(asset).default : mapAsset(asset)
}