import * as React from 'react'

import App from './App'
import ContactPanel from 'components/ContactPanel'
import { trackEvent } from 'trackers'

export default class Contact extends React.Component {

  componentDidMount() {
    trackEvent('contact-open')
  }

  render() {
    return (
      <App page="terms">
        <ContactPanel />
      </App>
    )
  }
}