import * as React from 'react'
import { css } from 'glamor'

import { Colors } from 'types/colors'
import IndexCell from './IndexCell'

import { State as MarketDataState } from 'reducers/market-data'
import { Instrument } from 'types/classes-instrument'
import { Carousel } from 'components/Carousel'

interface Props {
  instruments: Instrument[]
  marketData: MarketDataState
  onClick: (instrument: Instrument) => void
  selectedInstrument: Instrument | null
}

export default class IndexCarousel extends React.PureComponent<Props> {

  render() {
    const { instruments } = this.props
    return (
      <Carousel id="index-carousel" style={style}>
        {instruments.map((i, key) => 
          <IndexCell 
            key={key} 
            instrument={i}
            marketData={this.props.marketData.all[i.symbol]}
            onClick={this.props.onClick}
            isSelected={this.props.selectedInstrument === i}
          />)}
      </Carousel>
    )
  }
}

const style = css({
  borderColor: Colors.darkLineViolet,
  borderStyle: 'solid none solid none',
  borderWidth: '1pt 0 1pt 0',
})
