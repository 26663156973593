import { Action } from '../actions/user'

export type DetailedPositionsState = RequestStatus<AccountReportDetailedType | undefined>
export type AccountReportState = RequestStatus<AccountReportJSON | undefined>
export type AccountsState = RequestStatus<PrimaryAccount[] | undefined>

export interface LoginState {
  loggingIn: boolean
  error: Error | null
}

export interface State {
  loggedIn: boolean
  name?: string
  brokerName?: string
  broker?: Broker
  login: LoginState
  selectedAccount?: PrimaryAccount
  detailedPositions: DetailedPositionsState
  accountReport: AccountReportState
  accounts: AccountsState
  brokersModalShown: boolean
  accountsModalShown: boolean
  loginModalShown: boolean
}

const initialLoginState: LoginState = {
  loggingIn: false,
  error: null
}

const initialDetailedPositionsState: DetailedPositionsState = {
  status: 'pending',
  error: null,
  data: undefined
}

const initialAccountReportState: AccountReportState = {
  status: 'pending',
  error: null,
  data: undefined
}

const initialAccountsState: AccountsState = {
  status: 'pending',
  error: null,
  data: undefined
}

const initialState: State = {
  loggedIn: false,
  login: initialLoginState,
  detailedPositions: initialDetailedPositionsState,
  accountReport: initialAccountReportState,
  accounts: initialAccountsState,
  brokersModalShown: false,
  loginModalShown: false,
  accountsModalShown: false
}

export const user = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...state,
        loggedIn: false,
        selectedAccount: undefined,
        broker: action.broker,
        brokerName: action.broker?.name,
        login: {
          ...state.login,
          loggingIn: true,
          error: null
        },
        accounts: {
          status: 'fetching',
          data: undefined,
          error: null
        },
        accountReport: {
          status: 'fetching',
          data: undefined,
          error: null
        }
      }
    case 'RECEIVE_LOGIN':
      return {
        ...state,
        loggedIn: true,
        selectedAccount: action.response.selectedAccount,
        name: action.response.name,
        login: {
          loggingIn: false,
          error: null
        },
        accounts: {
          status: 'succeeded',
          data: action.response.accounts,
          error: null
        },
        accountReport: {
          status: 'succeeded',
          data: action.response.myAccountInformation.accountReport,
          error: null
        },
        detailedPositions: {
          status: 'succeeded',
          data: action.response.myAccountInformation.detailedPositions,
          error: null
        }
      }
    case 'FAIL_RECEIVING_LOGIN':
      return {
        ...state,
        loggedIn: false,
        selectedAccount: undefined,
        broker: undefined,
        name: undefined,
        login: {
          loggingIn: false,
          error: action.error
        },
        accounts: {
          status: 'errored',
          data: undefined,
          error: action.error
        },
        accountReport: {
          status: 'errored',
          data: undefined,
          error: action.error
        }
      }
    case 'OPEN_LOGIN_MODAL':
      return {
        ...state,
        loginModalShown: true
      }
    case 'CLOSE_LOGIN_MODAL':
      return {
        ...state,
        loginModalShown: false
      }
    case 'OPEN_BROKERS_MODAL':
      return {
        ...state,
        brokersModalShown: true
      }
    case 'CLOSE_BROKERS_MODAL':
      return {
        ...state,
        brokersModalShown: false
      }
    case 'REQUEST_ACCOUNT_REPORT':
      if (state.accountReport.status != 'succeeded') {
        return {
          ...state,
          accountReport: {
            status: 'fetching',
            ...state.accountReport
          }
        }
      }
      return state
    case 'RECEIVE_ACCOUNT_REPORT':
      return {
        ...state,
        accountReport: {
          status: 'succeeded',
          data: action.accountReport,
          error: null
        }
      }
    case 'REQUEST_DETAILED_POSITIONS':
      if (state.detailedPositions.status != 'succeeded') {
        return {
          ...state,
          detailedPositions: {
            status: 'fetching',
            data: undefined,
            error: null
          }
        }
      }
      return state
    case 'RECEIVE_DETAILED_POSITIONS':
      return {
        ...state,
        detailedPositions: {
          status: 'succeeded',
          data: action.detailedPositions,
          error: null
        }
      }
    case 'REQUEST_ACCOUNTS':
      return {
        ...state,
        accounts: {
          status: 'fetching',
          ...state.accounts
        }
      }
    case 'RECEIVE_ACCOUNTS':
      return {
        ...state,
        accounts: {
          status: 'succeeded',
          data: action.accounts,
          ...state.accounts
        }
      }
    case 'LOGOUT':
      return {
        ...initialState,
        name: state.name,
        brokerName: state.brokerName
      }
    case 'CHOOSE_ACCOUNT':
      return  {
        ...state,
        selectedAccount: action.account,
        accountsModalShown: false
      }
    case 'OPEN_ACCOUNTS_MODAL': {
      return {
        ...state,
        accountsModalShown: true
      }
    }
    case 'CLOSE_ACCOUNTS_MODAL': {
      return {
        ...state,
        accountsModalShown: false
      }
    }
    default:
      return state
  }
}
