import * as moment from 'moment'
import { candleDataFor, errorResponse } from './primary-helpers'

// tslint:disable-next-line:max-line-length
export const transformYahooHistoricalData = (response: YahooResponse, range: GraphRange, ticker: string, closeTime: string): CandleHistoricalOffersData => {
  const timestamps = response.chart.result[0].timestamp
  const closes = response.chart.result[0].indicators.quote[0].close
  const volumes = response.chart.result[0].indicators.quote[0].volume
  const highs = response.chart.result[0].indicators.quote[0].high
  const lows = response.chart.result[0].indicators.quote[0].low
  const opens = response.chart.result[0].indicators.quote[0].open

  var candleData: CandleDataEntry[] = []
  var priceData: number[] = []
  var sizeData: SizeData[] = []
  var datetimeData: string[] = []
  var maxPrice: number = 0
  var minPrice: number = 10000000
  var maxSize: number = 0

  if (timestamps === undefined) {
    return errorResponse
  }

  var lastPrice: number = 0
  var i = 0
  for (let time of timestamps) {
    let date = moment(time * 1000)
    let price: number
    let size: number
    if (closes[i] !== null && volumes[i] !== null) {
        price = Math.round(100 * closes[i]) / 100
        size = volumes[i]
        lastPrice = closes[i]
    } else {
        price = lastPrice
        size = 0
    }

    let low = lows[i] || price
    let high = highs[i] || price
    let open = opens[i] || price
    
    candleData.push({ o: open, l: low, h: high, c: price, v: size })
    priceData.push(price)
    let color = open <= price ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
    sizeData.push({ y: size, x: candleData.length, color })
    if (range === '1D') {
      datetimeData.push(date.format('HH:mm'))
    } else if (range === '5D') {
      datetimeData.push(date.format('ddd HH:mm'))
    } else {
      datetimeData.push(date.format('D MMM'))
    }
    
    maxPrice = Math.max(maxPrice, price)
    minPrice = Math.min(minPrice, price)
    maxSize = Math.max(maxSize, size)
    i += 1
  }

  const historicalData = {
    status: 'OK',
    ticker,
    priceData,
    sizeData,
    datetimeData,
    candleData,
    maxPrice,
    minPrice,
    maxSize
  }
  
  let intervalRange = 10
  if (range === '5D') {
    intervalRange = 12
  } else if (range === '1M' || range === '3M') {
    intervalRange = 1
  } else if (range === '6M' || range === '1A') {
    intervalRange = 5
  }
  return candleDataFor(historicalData, intervalRange)
}
