import * as React from 'react'
import { css } from 'glamor'

import { assetUrl } from 'utils/assets'
import { Colors } from 'types/colors'
import isMobile from 'utils/isMobile'

const defaultStyles = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  background: Colors.violet,
  color: Colors.white,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  '& > .icon-talaris': {
    height: '80px',
  },

  '& > .title': {
    margin: '30px 15px',
    textAlign: 'center',
    fontSize: '1.2rem',
  },

  '& > .download-button': {
    background: Colors.blue,
    color: 'white',
    borderRadius: '4pt',
    marginTop: '0',
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px'
  },

  '& > .download-button > .store-icon': {
    width: '20px',
    marginRight: '8px',
  },

  '& > .screenshot': {
    maxWidth: '50%',
  },

  '& > .screenshot > .screen-list': {
    width: "100%"
  },
})

const iconMatriz = assetUrl('icon-matriz.svg')
const screenshotList = assetUrl('iphone-screen-list.png')
const iconAndroid = assetUrl('icon-android.svg')
const iconIOS = assetUrl('icon-ios.svg')

const DownloadAppBanner = ({ styles = defaultStyles }) => (
  <div {...styles}>
    <img alt="Talaris" className="icon-talaris" src={iconMatriz}/>
    <h5 className="title">¡Talaris ahora es matriz! Descargá la app en tu celular</h5>
    <a className="download-button" href="https://talaris.app.link/ppMvZNTb80">
      {isMobile.Android() && <img alt="store" className="store-icon" src={iconAndroid}/>}
      {isMobile.iOS() && <img alt="store" className="store-icon" src={iconIOS}/>}
      <p>Descargar la app</p>
    </a>
    <div className="screenshot">
      <img alt="screenshot" className="screen-list" src={screenshotList}/>
    </div>
  </div>
)

export default DownloadAppBanner
