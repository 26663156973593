import * as React from 'react'

import App from './App'
import AccountPanel from 'components/AccountPanel'
import { trackEvent } from 'trackers'

export default class Account extends React.Component {

  componentDidMount() {
    trackEvent('account-open')
  }

  render() {
    return (
      <App page="account">
        <AccountPanel />
      </App>
    )
  }
}