import * as React from 'react'

import App from './App'
import TermsPanel from 'components/TermsPanel'
import { trackEvent } from 'trackers'

export default class Terms extends React.Component {

  componentDidMount() {
    trackEvent('terms-open')
  }

  render() {
    return (
      <App page="terms">
        <TermsPanel />
      </App>
    )
  }
}