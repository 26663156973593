import * as moment from 'moment'
import { groupBy, reduce } from 'lodash'
import { errorResponse } from './primary-helpers'

export const candleDataFor = (historicalOffers: HistoricalOffersData, group?: string): CandleHistoricalOffersData => {
  let sizeData: SizeData[] = []
  if (group === undefined) {
    historicalOffers.candleData.forEach((candle, idx) => {
      let size = historicalOffers.sizeData[idx].y
      let date = historicalOffers.datetimeData[idx]
      let color = candle.o <= candle.c ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
      candle.data = { rangeTime: date, volume: size }
      sizeData.push({ y: size, x: idx, color })
    })
    return {
      ...historicalOffers,
      digestedCandleData: {
        priceData: historicalOffers.candleData,
        sizeData,
        datetimeData: historicalOffers.datetimeData,
        maxSize: historicalOffers.maxSize,
        maxPrice: historicalOffers.maxPrice,
        minPrice: historicalOffers.minPrice
      }
    }
  }
  let groupFormat = 'ddd HH:mm'
  let coeff = 1
  if (group === '5min') coeff = 1000 * 60 * 5
  if (group === '10min') coeff = 1000 * 60 * 10
  if (group === '30min') coeff = 1000 * 60 * 30
  if (group === 'hour') groupFormat = 'YYYY-MM-DD HH'
  if (group === 'day') groupFormat = 'YYYY-MM-DD'
  if (group === 'week') groupFormat = 'YYYY-WW'
  if (group === 'month') groupFormat = 'YYYY-MM'
  let candleData: CandleDataEntry[] = []
  let datetimeData: string[] = []
  let maxSize = 0
  let maxPrice = 0
  let minPrice = 1000000000000
  const tradesDict = groupBy(historicalOffers.candleData, o => moment(Math.floor(o.timestamp! * 1000 / coeff) * coeff).format(groupFormat))
  Object.keys(tradesDict).forEach(time => {
    let candle = reduce(tradesDict[time], (accum, entry) => {
      return {
        h: Math.max(accum.h, entry.h),
        l: Math.min(accum.l, entry.l),
        o: accum.o || entry.o,
        c: entry.c,
        v: (accum.v || 0) + (entry.v || 0)
      } as CandleDataEntry
    })!
    if (candle) {
      let minDate = tradesDict[time][0].rangeTime!
      let maxDate = tradesDict[time][tradesDict[time].length - 1].rangeTime!
      datetimeData.push(minDate)
      let range = minDate + " - " + maxDate
      let color = candle.o <= candle.c ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
      let size = candle.v

      maxSize = Math.max(size, maxSize)
      maxPrice = Math.max(candle.h, maxPrice)
      minPrice = Math.min(candle.l, minPrice)
      candle.data = { rangeTime: range, volume: size }
      candleData.push(candle)
      sizeData.push({ y: size, x: candleData.length - 1, color })
    }
  })

  return {
    ...historicalOffers,
    digestedCandleData: {
      priceData: candleData,
      sizeData,
      datetimeData,
      maxSize,
      minPrice,
      maxPrice
    }
  }
}

// tslint:disable-next-line:max-line-length
export const transformHermesHistoricalData = (response: HermesHistoricalDataResponse): CandleHistoricalOffersData => {

  var candleData: CandleDataEntry[] = []
  var priceData: number[] = []
  var sizeData: SizeData[] = []
  var datetimeData: string[] = []
  var maxPrice: number = 0
  var minPrice: number = 10000000
  var maxSize: number = 0

  if (response.prices.time === undefined) {
    return errorResponse
  }

  var lastPrice: number = 0
  var i = 0
  for (let time of response.prices.time) {
    let date = moment(time * 1000)
    let price: number
    let size: number
    if (response.prices.close[i] !== null && response.prices.volume[i] !== null) {
        price = Math.round(100 * response.prices.close[i]) / 100
        size = response.prices.volume[i]
        lastPrice = response.prices.close[i]
    } else {
        price = lastPrice
        size = 0
    }

    let low = response.prices.low[i] || price
    let high = response.prices.high[i] || price
    let open = response.prices.open[i] || price
    
    priceData.push(price)
    let color = open <= price ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
    sizeData.push({ y: size, x: candleData.length, color })
    let dateStr = ''
    if (response.timeframe === '1d') {
      dateStr = date.format('HH:mm')
    } else if (response.timeframe === '5d') {
      dateStr = date.format('ddd HH:mm')
    } else {
      dateStr = date.format('D MMM')
    }
    datetimeData.push(dateStr)
    candleData.push({ o: open, l: low, h: high, c: price, v: size, timestamp: time, rangeTime: dateStr })
    maxPrice = Math.max(maxPrice, price)
    minPrice = Math.min(minPrice, price)
    maxSize = Math.max(maxSize, size)
    i += 1
  }

  const historicalData = {
    status: 'OK',
    ticker: response.ticker,
    priceData,
    sizeData,
    datetimeData,
    candleData,
    maxPrice,
    minPrice,
    maxSize
  }
  
  let group = 'day'
  if (response.timeframe === '5y') group = 'month'
  if (response.timeframe === '1y') group = 'week'
  if (response.timeframe === '5d') group = '30min'
  if (response.timeframe === '1d') group = '10min'
  return candleDataFor(historicalData, group)
}
