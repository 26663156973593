
// export const WEBSOCKET_CONNECT = 'WEBSOCKET:CONNECT';
// export const WEBSOCKET_DISCONNECT = 'WEBSOCKET:DISCONNECT';
// export const WEBSOCKET_SEND = 'WEBSOCKET:SEND';
// Action types dispatched by the WebSocket implementation
// export const WEBSOCKET_CONNECTING = 'WEBSOCKET:CONNECTING';
// export const WEBSOCKET_OPEN = 'WEBSOCKET:OPEN';
// export const WEBSOCKET_DISCONNECTING = 'WEBSOCKET:DISCONNECTING';
// export const WEBSOCKET_CLOSED = 'WEBSOCKET:CLOSED';
// export const WEBSOCKET_MESSAGE = 'WEBSOCKET:MESSAGE';

// These actions are more concerned with connection state
// and are trigged async by the WebSocketMiddleware

export type Payload = {
  timestamp: Date,
  event: Event,
  data?: any,
  websocket?: WebSocket
}

export type Config = {
  url: string
  protocol?: string | string[] | undefined
}

export type Action =
  { type: 'WEBSOCKET_CONNECT', payload: Config }
  | { type: 'WEBSOCKET_DISCONNECT', payload: string }
  | { type: 'WEBSOCKET_SEND', payload: string }
  | { type: 'WEBSOCKET_OPEN', payload: Payload }
  | { type: 'WEBSOCKET_CLOSED', payload: Payload }
  | { type: 'WEBSOCKET_MESSAGE', payload: Payload }
  | { type: 'WTFFFFFF' }

export const open = (event: Event): Action => ({
  type: 'WEBSOCKET_OPEN',
  payload: {
    timestamp: new Date(),
    event
  }
})

export const closed = (event: Event): Action => ({
  type: 'WEBSOCKET_CLOSED',
  payload: {
    timestamp: new Date(),
    event
  }
})

export const message = (event: MessageEvent): Action => ({
  type: 'WEBSOCKET_MESSAGE',
  payload: {
    timestamp: new Date(),
    data: event.data,
    event
  }
})
