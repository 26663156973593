import Bugsnag from '@bugsnag/js'
import { PrimaryClient } from 'utils/primary-client'
import { PrimalyticsClient } from 'utils/primalytics-client'
import { Instrument } from 'types/classes-instrument'
import { trackPurchase } from 'trackers'

export type Action =
  { type: 'OPEN_NEW_ORDER_MODAL', side: OrderSide, instrument: Instrument, offer?: OfferJSON }
  | { type: 'CLOSE_NEW_ORDER_MODAL' }
  | { type: 'REQUEST_NEW_ORDER', order: NewOrderJSON }
  | { type: 'RECEIVE_NEW_ORDER', orderResponse: OrderResponse }
  | { type: 'FAIL_REQUESTING_NEW_ORDER', error: Error }
  | { type: 'REQUEST_ALL_ORDERS' }
  | { type: 'RECEIVE_ALL_ORDERS', orders: PrimaryOrderJSON[] }
  | { type: 'FAIL_REQUESTING_ALL_ORDERS', error: Error }
  | { type: 'REQUEST_CANCEL_ORDER', order: PrimaryOrderJSON }
  | { type: 'RECEIVE_CANCEL_ORDER', orderResponse: OrderResponse }
  | { type: 'FAIL_REQUESTING_CANCEL_ORDER', error: Error }
  | { type: 'WTFFFF' }

const requestNewOrder = (order: NewOrderJSON): Action => ({
  type: 'REQUEST_NEW_ORDER',
  order
})

const receiveNewOrder = (orderResponse: OrderResponse): Action => ({
  type: 'RECEIVE_NEW_ORDER',
  orderResponse
})

const failRequestingNewOrder = (error: Error): Action => ({
  type: 'FAIL_REQUESTING_NEW_ORDER',
  error
})

const requestAllOrders = (): Action => ({
  type: 'REQUEST_ALL_ORDERS'
})

const receiveAllOrder = (orders: PrimaryOrderJSON[]): Action => ({
  type: 'RECEIVE_ALL_ORDERS',
  orders
})

// const failRequestingAllOrder = (error: Error): Action => ({
//   type: 'FAIL_REQUESTING_ALL_ORDERS',
//   error
// })

const requestCancelOrder = (order: PrimaryOrderJSON): Action => ({
  type: 'REQUEST_CANCEL_ORDER',
  order
})

const receiveCancelOrder = (orderResponse: OrderResponse): Action => ({
  type: 'RECEIVE_CANCEL_ORDER',
  orderResponse
})

const failRequestingCancelOrder = (error: Error): Action => ({
  type: 'FAIL_REQUESTING_CANCEL_ORDER',
  error
})

export const openNewOrderModal = (side: OrderSide, instrument: Instrument, offer?: OfferJSON): Action => ({
  type: 'OPEN_NEW_ORDER_MODAL',
  side,
  instrument,
  offer
})

export const closeNewOrderModal = (): Action => ({ type: 'CLOSE_NEW_ORDER_MODAL' })

export const createNewOrder = (order: NewOrderJSON) => (dispatch: Function, getState: Function) => {
  dispatch(requestNewOrder(order))
  return PrimaryClient.createNewOrder(order)
    .then(response => {
      if (response.status === 'ERROR') {
        return dispatch(failRequestingNewOrder(Error()))
      }
      trackOrder(order, response, getState().user.name, getState().user.broker.identityRofexId)
      return dispatch(receiveNewOrder(response.order))
    })
    .catch(e => dispatch(failRequestingNewOrder(e)))
}

export const cancelOrder = (order: PrimaryOrderJSON) => (dispatch: Function, getState: Function) => {
  dispatch(requestCancelOrder(order))
  return PrimaryClient.cancelOrder(order)
    .then(response => {
      if (response.status === 'ERROR') {
        return dispatch(failRequestingNewOrder(Error()))
      }
      dispatch(receiveCancelOrder(response.order))
      fetchAllOrders(order.accountId.id)(dispatch, getState)
    })
    .catch(e => dispatch(failRequestingCancelOrder(e)))
}

export const fetchAllOrders = (account: string | number) => (dispatch: Function, getState: Function) => {
  dispatch(requestAllOrders())
  return PrimaryClient.getOrders(account)
    .then(orders => dispatch(receiveAllOrder(orders)))
    // .catch(e => dispatch(failRequestingAllOrder(e)))
}

export const trackOrder = (order: NewOrderJSON, response: OrderResponse, user: string, brokerId: string) => {
  trackPurchase(order, response.order.clientId, user)
  PrimalyticsClient.createNewOrder(order, response.order.clientId, user, brokerId)
    .then()
    .catch(e => {
      Bugsnag.notify({ name: 'FAIL_TO_SAVE_ORDER', message: e }, event => {
        event.addMetadata('component', { order, response, user })
      })
    })
}
