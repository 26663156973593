import { groupBy, union, reduce, forEach } from 'lodash'
import * as moment from 'moment'

import { intraDayTimes } from './intraday-times'

import { Colors } from 'types/colors'
import {
  Instrument,
  Equity,
  Debt,
  Future,
  Index,
  OptionInstrument,
  Note,
  InstrumentsByCategory,
  Repurchase
} from 'types/classes-instrument'

// '2017-09-04'
export const dateString = (date: moment.Moment = moment()) => {
  if (date.isoWeekday() > 5) {
    date.subtract(date.isoWeekday() - 5, 'days')
  }
  return date.format('YYYY-MM-DD')
}

export const configInstrument = (ticker: string, type: string, subtype: string) => {
  return {
    ticker: ticker,
    type: type,
    subtype: subtype,
    optionPrefix: '',
    name: '',
    yahooName: ''
  }
}

export const transformAndFilterHermesInstruments = (response: HermesInstrumentsResponse): InstrumentsByCategory => {
  var equityInstruments: Equity[] = []
  var debtInstruments: Debt[] = []
  var futureInstruments: Future[] = []
  var indexInstruments: Index[] = []
  var optionInstruments: Dictionary<OptionInstrument> = {}
  var noteInstruments: Note[] = []
  var repurchaseInstruments: Repurchase[] = []
  var all: Dictionary<Instrument> = {}

  var segmentsHash: Dictionary<SegmentSubtypeJSON> = {}
  for (let segment of response.segments) {
    segmentsHash[segment.id] = segment
  }

  for (let i of response.futures) {
    let instrument = new Future(i)
    instrument.segmentSubtype = segmentsHash[instrument.segmentId]
    futureInstruments.push(instrument)
    all[instrument.ticker] = instrument
  }

  for (let i of response.indexes) {
    let instrument = new Index(i)
    instrument.segmentSubtype = segmentsHash[instrument.segmentId]
    indexInstruments.push(instrument)
    all[instrument.ticker] = instrument
  }

  for (let i of response.bonds) {
    let instrument = new Debt(i)
    instrument.segmentSubtype = segmentsHash[instrument.segmentId]
    debtInstruments.push(instrument)
    all[instrument.ticker] = instrument
  }

  for (let i of response.stocks) {
    let instrument = new Equity(i)
    instrument.segmentSubtype = segmentsHash[instrument.segmentId]
    equityInstruments.push(instrument)
    all[instrument.ticker] = instrument
  }

  for (let i of response.notes) {
    let instrument = new Note(i)
    instrument.segmentSubtype = segmentsHash[instrument.segmentId]
    noteInstruments.push(instrument)
    all[instrument.ticker] = instrument
  }

  for (let i of response.options) {
    let instrument = new OptionInstrument(i)
    all[instrument.ticker] = instrument
    optionInstruments[instrument.ticker] = instrument
  }

  for (let i of response.repurchases) {
    let instrument = new Repurchase(i)
    all[instrument.ticker] = instrument
    repurchaseInstruments[instrument.ticker] = instrument
  }

  return {
    equityInstruments: equityInstruments,
    debtInstruments: debtInstruments,
    futureInstruments: futureInstruments,
    optionInstruments: optionInstruments,
    noteInstruments: noteInstruments,
    indexInstruments: indexInstruments,
    repurchaseInstruments: repurchaseInstruments,
    all: all
  }
}

export const transformPositionsResponse = (response: PositionsResponse): Dictionary<Dictionary<PositionJSON>> => {
  if (response.positions === null) { return {} }
  const positionsDict = groupBy(response.positions, p => p.instrument.symbolReference)
  let positionsSettlementDict: Dictionary<Dictionary<PositionJSON>> = {}
  forEach(positionsDict, (positions, key) => {
    positionsSettlementDict[key] = {}
    positions.forEach(p => {
      const settl = p.instrument.settlType || "0"      
      positionsSettlementDict[key][settl] = p
    })
  })
  return positionsSettlementDict
}

export const transformAndFilterOrders = (response: ListOfOrdersResponse): PrimaryOrderJSON[] => {
  let orders: PrimaryOrderJSON[] = []

  let orderDictionary = groupBy(response.orders, o => o.orderId || o.clOrdId)
  for (let key in orderDictionary) {
    if (key === 'NONE') {
      orders = union(orders, orderDictionary[key])
    } else {
      let order = reduce(orderDictionary[key], (a: PrimaryOrderJSON, b) => {
        if (a === null) { return b }
        var prev = moment(a.transactTime, 'YYYYMMDD-HH:mm:ss')
        var next = moment(b.transactTime, 'YYYYMMDD-HH:mm:ss')
        return prev > next ? a : b
      })
      if (order) {
        orders.push(order)
      }
    }
  }
  SortByArray(orders, 'transactTime', 'desc')
  return orders
}
//
// struct ListOfOrdersResponse: ResponseModel {
//
//     var status: String
//     let orders: [PrimaryOrder]
//
//     init(_ json: JSON) {
//         status = json['status'].string!
//         var ordersSet = Set<PrimaryOrder>()
//         for orderDecoder in json['orders'].arrayValue {
//             let order = PrimaryOrder(orderDecoder)
//             if let prevOrderInx = ordersSet.index(of: order) {
//                 let prevOrder = ordersSet[prevOrderInx]
//                 if prevOrder.transactionTime?.timeIntervalSince1970 < order.transactionTime?.timeIntervalSince1970
//                    || order.status === .Cancelled {
//                     ordersSet.remove(prevOrder)
//                     ordersSet.insert(order)
//                 }
//             } else {
//                 ordersSet.insert(order)
//             }
//         }
//         self.orders = Array(ordersSet)
//     }
// }

export const mergePositionsAndDetailedPositions = (
  positions: Dictionary<Dictionary<PositionJSON>>, 
  detailedPositions: AccountReportDetailedType
  ): AccountReportDetailedType => {
    forEach(detailedPositions.positions, (detailedPosition, symbolReference) => {
      const positionsBySettlement = positions[symbolReference]
      forEach(positionsBySettlement, (p, settlType) => {
        detailedPosition.itemsBySettlement[settlType].position = p
      })
    })
    return detailedPositions
}

export function SortByArray(values: any[], sortBy: any, sortDirection: 'asc' | 'desc' = 'asc') {
  return values.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return sortDirection === 'asc' ? -1 : 1
    }
    if (a[sortBy] > b[sortBy]) {
      return sortDirection === 'asc' ? 1 : -1
    }
    return 0
  })
}

export const errorResponse = {
  status: 'ERROR',
  ticker: '',
  priceData: [],
  sizeData: [],
  datetimeData: [],
  candleData: [],
  maxPrice: 0,
  minPrice: 0,
  maxSize: 0,
  digestedCandleData: {
    priceData: [],
    datetimeData: [],
    sizeData: [],
    maxSize: 0,
    maxPrice: 0,
    minPrice: 0,
  }
}

export const transformPrimaryIntraDayData = (response: PrimaryHistoricalDataResponse, closeTime: string): CandleHistoricalOffersData => {

  if (response.status === 'ERROR') {
    return errorResponse
  }
  var priceData: number[] = []
  var sizeData: SizeData[] = []
  var datetimeData: string[] = []
  var candleData: CandleDataEntry[] = []
  var maxPrice: number = 0
  var minPrice: number = 10000000000000
  var maxSize: number = 0

  const priceKey = (response.trades.length > 0 && response.trades[0].indexValue !== undefined) ? 
    "indexValue" : "price"
  let tradesDict = groupBy(response.trades, o => moment(o.datetime).subtract(3, 'hour').format('HH:mm'))
  
  Object.keys(tradesDict).forEach(time => {
    let firstOffer = tradesDict[time][0]
    let high = firstOffer[priceKey]!
    let low = firstOffer[priceKey]!
    let open = firstOffer[priceKey]!
    let offer = tradesDict[time].reduce((previousOffer, offer) => {
      const value = offer[priceKey]
      if (value) {
        previousOffer[priceKey] = value
        high = Math.max(high, value)
        low = Math.min(low, value)
      }
      previousOffer.size += offer.size || 0
      return previousOffer
    }) || firstOffer

    if (datetimeData.length > 0) {
      const previousTime = datetimeData[datetimeData.length - 1]
      const previousTimeIndex = intraDayTimes.indexOf(previousTime)
      const lastTimeIndex = intraDayTimes.indexOf(time)
      const diff = lastTimeIndex - previousTimeIndex - 1
      const previousPrice = priceData[datetimeData.length - 1]
      for (let i = 1; i <= diff; i++) {
        candleData.push({ o: previousPrice, l: previousPrice, h: previousPrice, c: previousPrice, v: 0 })
        priceData.push(previousPrice)
        sizeData.push({ y: 0, x: candleData.length, color: Colors.green })
        datetimeData.push(intraDayTimes[previousTimeIndex + i])
      }
    }

    const close = offer[priceKey]!
    candleData.push({ o: open, l: low, h: high, c: close, v: offer.size })
    priceData.push(offer[priceKey]!)
    let color = open <= close ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
    sizeData.push({ y: offer.size, x: candleData.length, color })
    datetimeData.push(time)
    maxPrice = Math.max(maxPrice, offer[priceKey]!)
    minPrice = Math.min(minPrice, offer[priceKey]!)
    maxSize = Math.max(maxSize, offer.size)
  })

  if (datetimeData.length > 0) {
    const lastTime = datetimeData[datetimeData.length - 1]
    const lastIndex = intraDayTimes.indexOf(lastTime)
    const closeIndex = intraDayTimes.indexOf(closeTime) || intraDayTimes.length - 1
    if (lastIndex > -1 && closeIndex > lastIndex) {
      const latestTimes = intraDayTimes.slice(lastIndex + 1, closeIndex)
      datetimeData = datetimeData.concat(latestTimes)
    }
  }

  let historicalData = {
    status: 'OK',
    ticker: response.symbol,
    priceData,
    sizeData,
    datetimeData,
    candleData,
    maxPrice,
    minPrice,
    maxSize
  }

  return candleDataFor(historicalData, 10)
}

export const transformPrimaryIntraWeekData = (response: PrimaryHistoricalDataResponse): CandleHistoricalOffersData => {
  var priceData: number[] = []
  var sizeData: SizeData[] = []
  var datetimeData: string[] = []
  var candleData: CandleDataEntry[] = []
  var maxPrice: number = 0
  var minPrice: number = 10000000000000
  var maxSize: number = 0

  if (response.status === 'ERROR') {
    return errorResponse
  }

  let tradesDict = groupBy(response.trades, o => moment(o.datetime).subtract(3, 'hour').format('ddd HH:mm'))
  Object.keys(tradesDict).forEach(time => {
    let offer: OfferJSON = {
      size: 0,
      price: 0,
      datetime: time,
      date: 0
    }
    let high = -1
    let low = 10000000000000000
    let open = -1
    for (let offerString of tradesDict[time]) {
      let price = offerString.indexValue || offerString.price
      let size = offerString.size
      if (open === -1) open = price
      offer.size += size
      offer.price = price
      high = Math.max(high, price)
      low = Math.min(low, price)
    }
    const close = offer.price
    candleData.push({ o: open, l: low, h: high, c: close, v: offer.size })
    priceData.push(offer.price)
    let color = open <= close ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
    sizeData.push({ y: offer.size, x: candleData.length, color })
    datetimeData.push(time)
    maxPrice = Math.max(maxPrice, offer.price)
    minPrice = Math.min(minPrice, offer.price)
    maxSize = Math.max(maxSize, offer.size)
  })

  // let lastOfferDatetime = ""
  // let lastOffer: OfferJSON | null = null
  // for (let offer of response.trades) {
  //   let offerDate = new Date(offer.datetime)
  //   const offerDatetime = moment(offerDate).subtract(3, 'hour').format('ddd HH:mm')
  //   if (lastOffer) {
  //     if (lastOfferDatetime === offerDatetime) {
  //       offer.size += lastOffer.size
  //     } else {
  //       priceData.push(lastOffer.indexValue || lastOffer.price)
  //       sizeData.push(lastOffer.size)
  //       datetimeData.push(lastOfferDatetime)   
  //       maxPrice = Math.max(maxPrice, lastOffer.indexValue || lastOffer.price)
  //       minPrice = Math.min(minPrice, lastOffer.indexValue || lastOffer.price)
  //       maxSize = Math.max(maxSize, lastOffer.size) 
  //     }
  //   }
  //   lastOffer = offer
  //   lastOfferDatetime = offerDatetime
  // }

  // if (lastOffer && lastOfferDatetime) {
  //   priceData.push(lastOffer.indexValue || lastOffer.price)
  //   sizeData.push(lastOffer.size)
  //   datetimeData.push(lastOfferDatetime)
  //   maxPrice = Math.max(maxPrice, lastOffer.indexValue || lastOffer.price)
  //   minPrice = Math.min(minPrice, lastOffer.indexValue || lastOffer.price)
  //   maxSize = Math.max(maxSize, lastOffer.size) 
  // }
  
  let historicalData = {
    status: 'OK',
    ticker: response.symbol,
    priceData,
    sizeData,
    datetimeData,
    candleData,
    maxPrice,
    minPrice,
    maxSize
  }

  return candleDataFor(historicalData, 12)
}

export const transformPrimaryHistoricalData = (response: PrimaryHistoricalDataResponse, range: GraphRange): CandleHistoricalOffersData => {
  var priceData: number[] = []
  var sizeData: SizeData[] = []
  var datetimeData: string[] = []
  var candleData: CandleDataEntry[] = []
  var maxPrice: number = 0
  var minPrice: number = 10000000000000
  var maxSize: number = 0

  if (response.status === 'ERROR') {
    return errorResponse
  }

  let tradesDict = groupBy(response.trades, o => moment(o.datetime).format('D MMM'))
  Object.keys(tradesDict).forEach(time => {
    let offer: OfferJSON = {
      size: 0,
      price: 0,
      datetime: time,
      date: 0
    }
    let high = -1
    let low = 10000000000000000
    let open = -1
    for (let offerString of tradesDict[time]) {
      let price = offerString.indexValue || offerString.price
      let size = offerString.size
      if (open === -1) open = price
      offer.size += size
      offer.price = price
      high = Math.max(high, price)
      low = Math.min(low, price)
    }
    const close = offer.price
    candleData.push({ o: open, l: low, h: high, c: close, v: offer.size })
    priceData.push(offer.price)
    let color = open <= close ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
    sizeData.push({ y: offer.size, x: candleData.length, color })
    datetimeData.push(time)
    maxPrice = Math.max(maxPrice, offer.price)
    minPrice = Math.min(minPrice, offer.price)
    maxSize = Math.max(maxSize, offer.size)
  })

  let historicalData = {
    status: 'OK',
    ticker: response.symbol,
    priceData,
    sizeData,
    datetimeData,
    candleData,
    maxPrice,
    minPrice,
    maxSize
  }

  let intervalRange = (range === '6M' || range === '1A') ? 5 : 1
  return candleDataFor(historicalData, intervalRange)
}

export const transformHPrimaryHistoricalData = (response: HPrimaryHistoricalDataResponse, range: GraphRange): CandleHistoricalOffersData => {
  var priceData: number[] = []
  var sizeData: SizeData[] = []
  var datetimeData: string[] = []
  var candleData: CandleDataEntry[] = []
  var maxPrice: number = 0
  var minPrice: number = 10000000000000
  var maxSize: number = 0

  if (response.status === 'ERROR') {
    return errorResponse
  }

  let tradesDict = groupBy(response.marketDataH, o => moment(o.datetime).format('D MMM'))
  Object.keys(tradesDict).forEach(time => {
    let offer: OfferJSON = {
      size: 0,
      price: 0,
      datetime: time,
      date: 0
    }
    let high = -1
    let low = 10000000000000000
    let open = -1
    for (let offerString of tradesDict[time]) {
      let price = parseFloat(offerString.price)
      let size = parseFloat(offerString.size)
      if (open === -1) open = price
      offer.size += size
      offer.price = price
      high = Math.max(high, price)
      low = Math.min(low, price)
    }
    const close = offer.price
    candleData.push({ o: open, l: low, h: high, c: close, v: offer.size })
    priceData.push(offer.price)
    let color = open <= close ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
    sizeData.push({ y: offer.size, x: candleData.length, color })
    datetimeData.push(time)
    maxPrice = Math.max(maxPrice, offer.price)
    minPrice = Math.min(minPrice, offer.price)
    maxSize = Math.max(maxSize, offer.size)
  })

  const historicalData = {
    status: 'OK',
    ticker: response.security,
    priceData,
    sizeData,
    datetimeData,
    candleData,
    maxPrice,
    minPrice,
    maxSize
  }
  let intervalRange = (range === '6M' || range === '1A') ? 5 : 1
  return candleDataFor(historicalData, intervalRange)
}

export const candleDataFor = (historicalOffers: HistoricalOffersData, intervalRange: number): CandleHistoricalOffersData => {
  let dateTimesCount = historicalOffers.datetimeData.length
  let candleCount = historicalOffers.candleData.length
  let datetimeIntervals = dateTimesCount / intervalRange
  var intervals = (dateTimesCount < candleCount ? dateTimesCount : candleCount) / intervalRange
  intervals = Math.ceil(intervals)
  let sizeData: SizeData[] = []
  if (intervalRange > 1) {
    let candleData: CandleDataEntry[] = []
    let datetimeData: string[] = []
    let maxSize = 0
    let maxPrice = 0
    let minPrice = 1000000000000
    for (var i = 0; i < intervals; i++) {
      let minInterval = i * intervalRange
      let maxInterval = Math.min((i + 1) * intervalRange - 1, historicalOffers.candleData.length - 1)
      let subsetCandle = historicalOffers.candleData.slice(minInterval, maxInterval + 1)
      let subsetSize = historicalOffers.sizeData.slice(minInterval, maxInterval + 1)
      let candle = reduce(subsetCandle, (accum, entry) => {
        return {
          h: Math.max(accum.h, entry.h),
          l: Math.min(accum.l, entry.l),
          o: accum.o || entry.o,
          c: entry.c
        } as CandleDataEntry
      })!
      
      if (candle) { 
        let maxDate = historicalOffers.datetimeData[maxInterval]
        let minDate = historicalOffers.datetimeData[minInterval]
        datetimeData.push(minDate)
        let range = minDate + " - " + maxDate
        let color = candle.o <= candle.c ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
        let size = subsetSize.reduce((p, c) => p + c.y, 0)
        
        maxSize = Math.max(size, maxSize)
        maxPrice = Math.max(candle.h, maxPrice)
        minPrice = Math.min(candle.l, minPrice)
        sizeData.push({ y: size, x: i, color })
        candle.data = { rangeTime: range, volume: size }
        candleData.push(candle)
      }
    }
    for (var i = candleData.length; i < datetimeIntervals; i++) {
      let minInterval = i * intervalRange
      let minDate = historicalOffers.datetimeData[minInterval]
      datetimeData.push(minDate)
    }

    return {
      ...historicalOffers,
      digestedCandleData: {
        priceData: candleData,
        sizeData,
        datetimeData,
        maxSize,
        minPrice,
        maxPrice
      }
    }
  }
  historicalOffers.candleData.forEach((candle, idx) => {
    let size = historicalOffers.sizeData[idx].y
    let date = historicalOffers.datetimeData[idx]
    let color = candle.o <= candle.c ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
    candle.data = { rangeTime: date, volume: size }
    sizeData.push({ y: size, x: idx, color })
  })
  return {
    ...historicalOffers,
    digestedCandleData: {
      priceData: historicalOffers.candleData,
      sizeData,
      datetimeData: historicalOffers.datetimeData,
      maxSize: historicalOffers.maxSize,
      maxPrice: historicalOffers.maxPrice,
      minPrice: historicalOffers.minPrice
    }
  }
}
