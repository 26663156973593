import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { app, State as AppState } from './app'
import { user, State as UserState } from './user'
import { instruments, State as InstrumentsState } from './instruments'
import { orders, State as OrdersState } from './orders'
import { watchlist, State as WatchlistState } from './watchlist'
import { selectedInstrument } from './selected-instrument'
import { marketData, State as MarketDataState } from './market-data'
import { websocket, State as WebsocketState } from './websocket'
import { historicalData, State as HistoricalDataState } from './historical-data'
import { instrumentHistory, State as HistoryState } from './history'
import { Instrument } from 'types/classes-instrument'

export default (history: any) => combineReducers({
  router: connectRouter(history),
  app,
  user,
  instruments,
  orders,
  watchlist,
  selectedInstrument,
  marketData,
  websocket,
  historicalData,
  instrumentHistory
  // intl: intlReducer,
})

export interface RootState {
  app: AppState
  instruments: InstrumentsState
  user: UserState
  watchlist: WatchlistState
  marketData: MarketDataState
  websocket: WebsocketState,
  selectedInstrument: Instrument | null
  orders: OrdersState,
  historicalData: HistoricalDataState,
  history: HistoryState
}