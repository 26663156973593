import * as React from 'react'
import { css } from 'glamor'

import { Colors } from '../types/colors'

interface Props {
  color: string
  selectedRange: GraphRange
  selectRange: (range: GraphRange) => () => void
}

export class RangeSelector extends React.PureComponent<Props> {
  render() {
    const allGraphRanges: GraphRange[] = ['1D', '5D', '1M', '3M', '6M', '1A']
    const { color, selectRange, selectedRange } = this.props
    return (
      <ul className="tab tab-block" {...style(color)}>
        {allGraphRanges.map((range, i) => (
          <li key={i} className={(selectedRange === range) ? 'tab-item active' : 'tab-item'}>
            <a className="range" onClick={selectRange(range)}>{range}</a>
          </li>)
        )}
      </ul>
    );
  }
}

const style = (color: string) => {
  return css({
    backgroundColor: Colors.lightViolet,
    border: 0,
    borderRadius: '16px',
    padding: '0 2px',
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
    color: 'white',

    '& > .tab-item.active > a': {
      color: color,
      padding: '2px 12px',
      border: `1px solid ${color}`,
      borderRadius: '15px'
    },

    '& > .tab-item': {
      cursor: 'pointer'
    },

    '& > .tab-item > .range:hover': {
      color: color
    }
  })
}
