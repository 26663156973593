import * as React from 'react'
import { css } from 'glamor'

import { formatPrice, formatReturn } from 'types/classes-instrument'
import { Colors } from 'types/colors'
import PositionCell from './PositionCell'

interface Props {
  category: string
  positions: DetailedPositionType[]
  marketValue: number
  totalDiff: number
  totalDailyDiff: number
  totalReturn: number | null
  dailyReturn: number | null
  key: number
}

export const CategorySection: React.SFC<Props> = (props) => {

  const categoryName = (category: string) => {
    switch (category) {
      case 'STOCK': return 'ACCIONES'
      case 'FUTURE': return 'FUTUROS'
      case 'BOND': return 'BONOS'
      case 'STOCK_OPTION_CALL': return 'OPCIONES'
      case 'STOCK_OPTION_PUT': return 'OPCIONES'
      case 'FUTURE_OPTION_CALL': return 'OPCIONES'
      case 'FUTURE_OPTION_PUT': return 'OPCIONES'
      case 'LETTER_NOTE': return 'LETRAS'
      default: return category
    }
  }

  const sharesName = (category: string) => {
    switch (category) {
      case 'STOCK': 
      case 'CEDEAR': 
        return 'ACCIONES'
      case 'BOND': return 'NOMINALES'
      default: return 'CONTRATOS'
    }
  }

  const row = (position: DetailedPositionType, category: string, key: number) => {
    return (
      <PositionCell
        key={key}
        symbol={position.ticker}
        position={position}
        sharesName={sharesName(category)}
      />
    )
  }

  const dailyReturnColor = () => {
    return props.totalDailyDiff >= 0 ? 'positive' : 'negative'
  }

  const totalReturnColor = () => {
    return props.totalDiff >= 0 ? 'positive' : 'negative'
  }

  return (
    <div {...style} className="category-section" key={props.key}>
      <div className="row category-header">
        <p className="column big">{categoryName(props.category)}</p>
        <p className={`column`} />
        <p className={`column ${dailyReturnColor()}`}>{formatPrice(props.totalDailyDiff)}</p>
        <p className={`column ${dailyReturnColor()}`}>{formatReturn(props.dailyReturn)}</p>
        <p className={`column ${totalReturnColor()}`}>{formatPrice(props.totalDiff)}</p>
        <p className={`column ${totalReturnColor()}`}>{formatReturn(props.totalReturn)}</p>
        <p className="column center">{formatPrice(props.marketValue)}</p>
      </div>
      {props.positions.map((position, i) => row(position, props.category, i))}
    </div>
  )
}

const style = css({
  '& > .category-header': {
    fontSize: '110%',
    color: Colors.gray,
    backgroundColor: Colors.darkViolet,
    display: 'flex',
    padding: '12px',
    borderStyle: 'none none solid none',
    borderBottomWidth: '1pt',
    borderColor: Colors.darkLineViolet,
  },

  '& > .row > .column.big': {
    width: '16%',
    minWidth: '16%',
    maxWidth: '16%',
  },

  '& > .row > .column': {
    width: '14%',
    minWidth: '14%',
    maxWidth: '14%',
  },

  '& > .row > .column.center': {
    textAlign: 'center'
  },

  '& > .category-header > .positive': {
    color: Colors.green
  },

  '& > .category-header > .negative': {
    color: Colors.red
  },
})