import Bugsnag from '@bugsnag/js'
import { PrimaryClient } from '../utils/primary-client'
import { Action as WebsocketAction } from '../websocket/actions'
import { fetchAllInstruments } from './instruments'
import { setUserProperties } from 'trackers'

declare var window: { App: any }
const config = process.env.IS_BROWSER ? window.App.config : require('server/config')

export type Action =
  { type: 'REQUEST_DEFAULT_LOGIN' }
  | { type: 'RECEIVE_DEFAULT_LOGIN' }
  | { type: 'OPEN_LOGIN_MODAL' }
  | { type: 'CLOSE_LOGIN_MODAL' }
  | { type: 'REQUEST_LOGIN', user: string, broker: Broker }
  | { type: 'RECEIVE_LOGIN', response: LoginResponse }
  | { type: 'FAIL_RECEIVING_LOGIN', error: Error }
  | { type: 'LOGOUT' }
  | { type: 'OPEN_BROKERS_MODAL' }
  | { type: 'CLOSE_BROKERS_MODAL' }
  | { type: 'REQUEST_ACCOUNTS' }
  | { type: 'RECEIVE_ACCOUNTS', accounts: PrimaryAccount[] }
  | { type: 'FAIL_RECEIVING_ACCOUNTS', error: Error }
  | { type: 'REQUEST_POSITIONS' }
  | { type: 'RECEIVE_POSITIONS', positions: Dictionary<Dictionary<PositionJSON>> }
  | { type: 'FAIL_RECEIVING_POSITIONS', error: Error }
  | { type: 'REQUEST_ACCOUNT_REPORT' }
  | { type: 'RECEIVE_ACCOUNT_REPORT', accountReport: AccountReportJSON }
  | { type: 'FAIL_RECEIVING_ACCOUNT_REPORT', error: Error }
  | { type: 'REQUEST_DETAILED_POSITIONS' }
  | { type: 'RECEIVE_DETAILED_POSITIONS', detailedPositions: AccountReportDetailedType }
  | { type: 'FAIL_RECEIVING_DETAILED_POSITIONS', error: Error }
  | { type: 'OPEN_ACCOUNTS_MODAL' }
  | { type: 'CLOSE_ACCOUNTS_MODAL' }
  | { type: 'CHOOSE_ACCOUNT', account: PrimaryAccount }
  | { type: 'WTFFF' }

const requestDefaultLogin = (): Action => ({
  type: 'REQUEST_DEFAULT_LOGIN'
})

const receiveDefaultLogin = (): Action => ({
  type: 'RECEIVE_DEFAULT_LOGIN'
})

const requestLogin = (user: string, broker: Broker): Action => ({
  type: 'REQUEST_LOGIN',
  user: user,
  broker
})

const receiveLogin = (response: LoginResponse): Action => ({
  type: 'RECEIVE_LOGIN',
  response
})

const failReceivingLogin = (error: Error): Action => ({
  type: 'FAIL_RECEIVING_LOGIN',
  error
})

export const openLoginModal = (): Action => ({ type: 'OPEN_LOGIN_MODAL' })

export const closeLoginModal = (): Action => ({ type: 'CLOSE_LOGIN_MODAL' })

export const openAccountsModal = (): Action => ({ type: 'OPEN_ACCOUNTS_MODAL' })

export const closeAccountsModal = (): Action => ({ type: 'CLOSE_ACCOUNTS_MODAL' })

export const logout = (): Action => {
  PrimaryClient.logout()
  return { type: 'LOGOUT' }
}

export const openBrokersModal = (): Action => ({ type: 'OPEN_BROKERS_MODAL' })

export const closeBrokersModal = (): Action => ({ type: 'CLOSE_BROKERS_MODAL' })

export const selectAccount = (account: PrimaryAccount): Action => ({ type: 'CHOOSE_ACCOUNT', account })

const requestAccounts = (): Action => ({
  type: 'REQUEST_ACCOUNTS'
})

const receiveAccounts = (accounts: PrimaryAccount[]): Action => ({
  type: 'RECEIVE_ACCOUNTS',
  accounts
})

const failReceivingAccounts = (error: Error): Action => ({
  type: 'FAIL_RECEIVING_ACCOUNTS',
  error
})

const requestPositions = (): Action => ({
  type: 'REQUEST_POSITIONS'
})

const receivePositions = (positions: Dictionary<Dictionary<PositionJSON>>): Action => ({
  type: 'RECEIVE_POSITIONS',
  positions
})

const failReceivingPositions = (error: Error): Action => ({
  type: 'FAIL_RECEIVING_POSITIONS',
  error
})

const requestAccountReport = (): Action => ({
  type: 'REQUEST_ACCOUNT_REPORT'
})

const receiveAccountReport = (accountReport: AccountReportJSON): Action => ({
  type: 'RECEIVE_ACCOUNT_REPORT',
  accountReport
})

const failReceivingAccountReport = (error: Error): Action => ({
  type: 'FAIL_RECEIVING_ACCOUNT_REPORT',
  error
})

const requestDetailedPositions = (): Action => ({
  type: 'REQUEST_DETAILED_POSITIONS'
})

const receiveDetailedPositions = (detailedPositions: AccountReportDetailedType): Action => ({
  type: 'RECEIVE_DETAILED_POSITIONS',
  detailedPositions
})

const failReceivingDetailedPositions = (error: Error): Action => ({
  type: 'FAIL_RECEIVING_DETAILED_POSITIONS',
  error
})

export const disconnectWebsocket = (): WebsocketAction => ({
    type: 'WEBSOCKET_DISCONNECT',
    payload: 'something'
})

export const connectWebsocket = (): WebsocketAction => ({
    type: 'WEBSOCKET_CONNECT',
    payload: {
      url: PrimaryClient.WEBSOCKET_URL
    }
})

export const fetchAccounts = () => (dispatch: Function, getState: Function) => {
  dispatch(requestAccounts())
  PrimaryClient.setBroker(getState().user.broker)
  return PrimaryClient.getAccounts()
    .then(r => dispatch(receiveAccounts(r)))
    .catch(e => dispatch(failReceivingAccounts(e)))
}

export const fetchPositions = (account: PrimaryAccount) => (dispatch: Function, getState: Function) => {
  dispatch(requestPositions())
  PrimaryClient.setBroker(getState().user.broker)
  return PrimaryClient.getPositions(account)
    .then(r => dispatch(receivePositions(r)))
    .catch(e => dispatch(failReceivingPositions(e)))
}

export const fetchAccountReport = (account: PrimaryAccount) => (dispatch: Function, getState: Function) => {
  dispatch(requestAccountReport())
  PrimaryClient.setBroker(getState().user.broker)
  return PrimaryClient.getAccountReport(account)
    .then(r => dispatch(receiveAccountReport(r)))
    .catch(e => dispatch(failReceivingAccountReport(e)))
}

export const fetchDetailedPositions = (account: PrimaryAccount) => (dispatch: Function, getState: Function) => {
  dispatch(requestDetailedPositions())
  PrimaryClient.setBroker(getState().user.broker)
  return PrimaryClient.getDetailedPositionsAndPositions(account)
    .then(r => dispatch(receiveDetailedPositions(r)))
    .catch(e => dispatch(failReceivingDetailedPositions(e)))
}

export const defaultLogin = () => (dispatch: Function, getState: Function) => {
  dispatch(requestDefaultLogin())
  return PrimaryClient.defaultLogin()
    .then(_ => dispatch(receiveDefaultLogin()))
}

export const loginAndAccounts = (user: string, password: string, brokerName?: string) => 
  (dispatch: Function, getState: Function) => {
    const broker = config.brokers.find((b: any) => b.name === brokerName)!
    dispatch(requestLogin(user, broker))
    return PrimaryClient.loginAndMyInformation(user, password, broker)
      .then(response => {
        setUserProperties({
          'loggedIn': true,
          'user': user,
          'brokerName': brokerName,
          'brokerId': response.selectedAccount?.brokerId
        })
        Bugsnag.setUser(user, 'none', user + ' | ' + response.selectedAccount?.brokerId)
        dispatch(disconnectWebsocket())
        dispatch(connectWebsocket())
        dispatch(closeLoginModal())
        dispatch(receiveLogin(response))
        dispatch(fetchAllInstruments())
      }).catch(e => dispatch(failReceivingLogin(e)))
}

export const fetchMyInformation = 
  (user?: string, accounts?: PrimaryAccount[], selectedAccount?: PrimaryAccount) =>
  (dispatch: Function, getState: Function) => {
  if (user && accounts && accounts.length > 0 && selectedAccount) {
    PrimaryClient.setBroker(getState().user.broker)
    return PrimaryClient.getMyInformation(user, accounts, selectedAccount, getState().user.brokerName)
      .then(response => dispatch(receiveLogin(response)))
      .catch(e => {
        if (e.status === 'ERROR') {
          dispatch(logout())
          dispatch(failReceivingLogin(e))
        }
      })
  }
  return
}
