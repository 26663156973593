import { Action } from '../actions/watchlist'
import { xor } from 'lodash'

export type State = {
  all: string[]
}

const initialState: State = {
  all: []
}

export const watchlist = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'ADD_TO_WATCHLIST':
      return {
        all: xor([action.instrument.ticker], state.all)
      }
    case 'REMOVE_FROM_WATCHLIST':
      return {
        all: xor([action.instrumentTicker], state.all)
      }
    default: break;
  }
  return state
}
