import * as React from 'react'
import { css } from 'glamor'

import { Colors } from 'types/colors'
import { assetUrl } from 'utils/assets'
import { Button } from './Button'
import { UnlockIcon, OpenWatchlistIcon } from './Icons'

interface Props {
  title: string,
  subtitle?: string,
  icon: any,
  actionTitle?: string
}

interface Actions {
  onRetry?: () => any,
}

export const BaseEmptyModal: React.SFC<Props & Actions> = (props) => {
  return (
    <div {...styles}>
      <div className="empty-icon">
        {typeof props.icon === 'string' && <img alt={props.title} src={assetUrl(props.icon)}/>}
        {typeof props.icon !== 'string' && props.icon}
      </div>
      <h4 className="title">{props.title}</h4>
      {props.subtitle && <h6 className="subtitle">{props.subtitle}</h6>}
      {props.actionTitle && props.onRetry &&
          <Button onClick={props.onRetry}>{props.actionTitle}</Button>}
    </div>
  );
};

export const NetworkError: React.SFC<Actions> = (props) => {
  return (
    <BaseEmptyModal 
      title="¡Ups! Hubo un error!"
      icon="error.svg"
      actionTitle="Volver a intentar"
      onRetry={props.onRetry}
    />
  )
}

export const LoginEmptyState: React.SFC<Actions> = (props) => {
  return (
    <BaseEmptyModal 
      title="¡Ups! Necesitás una cuenta"
      subtitle="Para poder realizar este tipo de acciones debes tener una cuenta"
      icon={<UnlockIcon className='icon' />}
      actionTitle="Iniciar sesión"
      onRetry={props.onRetry}
    />
  )
}

export const EmptyWatchlist: React.SFC<Actions> = (props) => {
  return (
    <BaseEmptyModal 
      title="Seguí activos"
      subtitle="Todavía no sigues a ningún instrumento"
      icon={<OpenWatchlistIcon className='icon' />}
      actionTitle="Buscá tus activos"
      onRetry={props.onRetry}
    />
  )
}

export const NotAccount: React.SFC<Actions> = (props) => {
  return (
    <BaseEmptyModal 
      title="No tenés cuenta"
      subtitle="Para poder ver los datos de tu cuenta necesitas tener una asociada a tu usuario"
      icon={<UnlockIcon className='icon' />}
    />
  )
}

export const ErrorWatchlist: React.SFC<Actions> = (props) => {
  return (
    <BaseEmptyModal 
      title="¡Ups! Hubo un error!"
      subtitle="Parece que hubo un error al cargar los instrumentos"
      icon="error.svg"
      actionTitle="Volver a intentar"
      onRetry={props.onRetry}
    />
  )
}

export const ContactThankYou: React.SFC<Actions> = (props) => {
  return (
    <BaseEmptyModal 
      title="¡Muchas gracias por contactarnos!"
      subtitle="Responderemos sus dudas a la brevedad"
      icon="sent-ok.svg"
    />
  )
}

const styles = css({
  backgroundColor: `rgba(0,0,0,0) !important`,
  color: 'white !important',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  padding: '40px 30px',

  '& > .title': {
    textAlign: 'center',
    marginBottom: '12px',
  },

  '& > .subtitle': {
    textAlign: 'center',
    marginBottom: '12px',
    color: Colors.lightGray
  },

  '& > .btn': {
    padding: '12px 20px',
    fontSize: '130%',
    marginTop: '20px',
    backgroundColor: Colors.blue,
    borderColor: Colors.blue,
    height: '2.1rem'
  },

  '& > .btn:focus': {
    backgroundColor: Colors.blue,
    borderColor: Colors.blue,
  },

  '& > .btn:hover': {
    backgroundColor: Colors.blue,
    borderColor: Colors.blue
  },

  '& > .empty-icon > img': {
    marginBottom: '20px',
    width: '50px',
    height: 'auto',
    backgroundSize: 'cover'
  },

  '& > .empty-icon > .icon': {
    marginBottom: '20px',
    width: '50px',
    height: 'auto',
    backgroundSize: 'cover',
    fill: Colors.lightGray,
  },

  '& > .empty-icon > .icon > path': {
    fill: Colors.lightGray,
  }
})