import * as React from 'react'
import { css } from 'glamor'

import { Colors } from 'types/colors'

interface Props {
  name: string,
  direction: 'row' | 'column'
  fieldName: string,
  multiline?: boolean,
  rows?: number,
  value?: string | number,
  type?: string,
  onChange?: any,
  styles?: any
}

export const TTextField = ({ name, fieldName, value, type, onChange, multiline = false, 
  rows = 4, direction = 'column', styles = defaultStyles }: Props) => (
  <div {...styles(direction)}>
    <label>{name}</label>
    {!multiline &&
      <input 
        className="input"
        id={fieldName}
        value={value}
        onChange={onChange}
        type={type}
      />}
    {multiline &&
      <textarea
        className="input"
        id={fieldName}
        value={value}
        rows={rows}
        cols={20}
        onChange={onChange}
      />}
  </div>
)

const defaultStyles = (direction: string) => {
  return css({
    width: '100%',
    margin: '1px',
    marginBottom: '12px',
    color: Colors.lightGray,
    display: 'flex',
    flexDirection: direction,
    justifyContent: direction === 'column' ? 'inherit' : 'space-between',
    alignItems: direction === 'column' ? 'inherit' : 'center',
  
    '& > label': {
      padding: direction === 'column' ? '6px' : '0',
    },
  
    '& > .input': {
      padding: '6px',
      backgroundColor: Colors.middleViolet,
      border: `1px solid ${Colors.darkLineViolet}`,
      borderRadius: '3pt',
      textAlign: direction === 'column' ? 'left' : 'right',
      resize: 'none',
      color: Colors.white,
      width: direction === 'column' ? '100%' : '50px',
      fontSize: '14px',
      boxShadow: '0 0 0 0 rgba(0,0,0,0.2) inset',
  
      '::placeholder': {
        color: Colors.lightGray
      },
  
      ':focus': {
        outline: 'none',
        boxShadow: '0 0 0 0 rgba(0,0,0,0.2) inset',
      },
  
      // ':-internal-autofill-previewed': {
      //   backgroundColor: 'red !important'
      // },
      // ':-internal-autofill-selected': {
      //   backgroundColor: 'red !important'
      // },
      ':-webkit-autofill': {
        backgroundColor: 'red !important'
      },
      ':-webkit-autofill:hover': {
        backgroundColor: 'red !important'
      },
      ':-webkit-autofill:focus': {
        backgroundColor: 'red !important'
      },
      ':-webkit-autofill:active': {
        backgroundColor: 'red !important'
      }
  
    },
  })
}