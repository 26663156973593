import { MiddlewareAPI } from 'redux';
// var partial = require('lodash.partial')
import { Config, open, closed, message } from './actions';

export const createWebsocketMiddleware = () => {
  // Hold a reference to the WebSocket instance in use.
  let websocket: WebSocket | null
  let websocketConfig: Config
  let queue: string[]

  let attempts = 1
  const generateInterval = (k: number) => Math.min(30, (Math.pow(2, k) - 1)) * 1000

  /**
   * A function to create the WebSocket object and attach the standard callbacks
   */
  const initialize = ({ dispatch }: MiddlewareAPI<any>, config: any) => {
    // Instantiate the websocket.
    websocket = new WebSocket(config.url, config.protocol)
    websocketConfig = config

    // Function will dispatch actions returned from action creators.
    // const dispatchAction = partial(compose, [dispatch]);

    // Setup handlers to be called like this:
    // dispatch(open(event));
    if (websocket) {
      queue = []
      websocket.onopen = (event) => dispatch(open(event))
      websocket.onclose = (event) => dispatch(closed(event))
      websocket.onmessage = (event) => dispatch(message(event))

      // An optimistic callback assignment for WebSocket objects that support this
      // const onConnecting = dispatchAction(connecting);
      // Add the websocket as the 2nd argument (after the event).
      // websocket.onconnecting = partialRight(onConnecting, [websocket]);
    }

  };

  /**
   * Close the WebSocket connection and cleanup
   */
  const close = () => {
    if (websocket) {
      websocket.close();
      websocket = null;
    }
  };

  /**
   * The primary Redux middleware function.
   * Each of the actions handled are user-dispatched.
   */
  return (store: MiddlewareAPI<any>) => (next: any) => (action: any) => {
    switch (action.type) {
      // User request to connect
      case 'WEBSOCKET_CONNECT':
        close();
        initialize(store, action.payload);
        next(action);
        break;

      // User request to disconnect
      case 'WEBSOCKET_DISCONNECT':
        close();
        next(action);
        break;

      // User request to send a message
      case 'WEBSOCKET_SEND':
        if (websocket && websocket.readyState === websocket.OPEN) {
          websocket.send(action.payload);
        } else {
          queue.push(action.payload)
        }
        next(action);
        break;

      // User request to open a connection
      case 'WEBSOCKET_OPEN':
        attempts = 1
        if (queue.length > 0 && websocket && websocket.readyState === websocket.OPEN) {
          queue.forEach(a => websocket!.send(a))
          queue = []
        }
        next(action)
        break

      // The connection has closed
      case 'WEBSOCKET_CLOSED':
        // We've tried to reconnect so increment the attempts by 1
        if (!document.hidden) {
          const time = generateInterval(attempts);
          setTimeout(() => { attempts++; initialize(store, websocketConfig) }, time)
        }
        next(action)
        break

      default:
        next(action);
    }
  };
};
