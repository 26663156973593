import * as React from 'react'

import App from './App'
import OrdersPanel from 'components/OrdersPanel'
import { trackEvent } from 'trackers'

export default class Orders extends React.Component {

  componentDidMount() {
    trackEvent('orders-open')
  }

  render() {
    return (
      <App page="orders">
        <OrdersPanel />
      </App>
    )
  }
}