import * as React from 'react'
import { css } from 'glamor'
import { Instrument, instrumentTypeName } from 'types/classes-instrument'
import { Colors } from 'types/colors'
import { assetUrl } from 'utils/assets'

const openWatchlist = assetUrl('open-watchlist.png')
const closeWatchlist = assetUrl('close-watchlist.png')

interface Props {
  instrument: Instrument
  watched: boolean,
  onClick: (instrument: Instrument) => void
  onWatchClick: (instrument: Instrument) => void
}

export default class Cell extends React.PureComponent<Props> {

  selectInstrument = () => {
    this.props.onClick(this.props.instrument)
  }

  onWatchInstrument = () => {
    this.props.onWatchClick(this.props.instrument)
  }

  render() {
    const {
      instrument,
      watched
    } = this.props

    return (
      <div {...styles} onClick={this.selectInstrument}>
        <div className="left center-vertically">
          <p className="ticker-label bold">{instrument.ticker}</p>
        </div>
        <div className="right center-vertically">
          <span className="instrument-type">{instrumentTypeName(instrument.type)}</span>
          <button className="btn" onClick={this.onWatchInstrument}>
            <img alt="Seguido" className="watched" src={(watched) ? openWatchlist : closeWatchlist} />
          </button>
        </div>
      </div>
    );
  }
}

const styles = css({
  width: '100%',
  height: '45px',
  padding: '0 20px 0 20px',
  borderStyle: 'none none solid none',
  borderBottomWidth: '1pt',
  borderColor: Colors.darkLineViolet,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: Colors.darkViolet,
  },

  '& > .center-vertically': {
    // height: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },

  '& > .left': {
    float: 'left',
  },

  '& > .left > .bold': {
    fontSize: '120%',
    color: Colors.white,
  },

  '& > .left > p': {
    margin: 0,
  },

  '& > .right': {
    float: 'right',
    flexDirection: 'row',
    alignItems: 'center',
  },

  '& > .right > p': {
    margin: 0,
  },

  '& > .right > .btn': {
    backgroundColor: Colors.lightViolet,
    borderWidth: 0,
    width: '60px',
    height: '30px',
  },

  '& > .right > .btn:hover': {
    backgroundColor: Colors.middleViolet,
  },

  '& > .right > .btn > .watched': {
    width: '26px',
    height: '100%',
    objectFit: 'contain',
  },

  '& > .right > .instrument-type': {
    color: Colors.lightGray,
    backgroundColor: Colors.darkLineViolet,
    fontSize: '85%',
    borderRadius: '10pt',
    padding: '3px 9px',
    marginRight: '12px'
  },
})
