import * as React from 'react'
import { css, keyframes } from 'glamor'
import { Colors } from '../types/colors'

interface Props {
  size?: number,
  thickness?: number,
  color?: string
}

export const Loading = ({ size = 50, thickness = 4, color = Colors.blue }: Props) => {
  return <div className="loader" {...style({ size, thickness, color })}/>
}

// make the keyframes with glamor
const spin = keyframes('spin', {
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' }
})

const style = (props: Props) => {
  return css({
    backgroundColor: 'rgba(255, 255, 255, 0)',
    border: `${props.thickness}px solid rgba(255, 255, 255, 0)`,
    borderTop: `${props.thickness}px solid ${props.color}`,
    borderRadius: '50%',
    width: `${props.size}px`,
    height: `${props.size}px`,
    animation: `${spin} 1s linear infinite`,
    alignSelf: 'center'
  })
}

// export const Loading = ({ size = 40, thickness = 5, color = Colors.blue }: Props) => {
//   return (
//     <div className="sk-fading-circle" {...style}>
//       <div className="sk-circle1 sk-circle"></div>
//       <div className="sk-circle2 sk-circle"></div>
//       <div className="sk-circle3 sk-circle"></div>
//       <div className="sk-circle4 sk-circle"></div>
//       <div className="sk-circle5 sk-circle"></div>
//       <div className="sk-circle6 sk-circle"></div>
//       <div className="sk-circle7 sk-circle"></div>
//       <div className="sk-circle8 sk-circle"></div>
//       <div className="sk-circle9 sk-circle"></div>
//       <div className="sk-circle10 sk-circle"></div>
//       <div className="sk-circle11 sk-circle"></div>
//       <div className="sk-circle12 sk-circle"></div>
//     </div>
//   )
// }

// // make the keyframes with glamor
// const circleFadeDelay = keyframes('circleFadeDelay', {
//   '0%': { opacity: 0 },
//   '39%': { opacity: 0 },
//   '40%': { opacity: 1 },
//   '100%': { opacity: 0 }
// })

// const style = css({
//   margin: '100px auto',
//   width: '60px',
//   height: '60px',
//   position: 'relative',
//   zIndex: '10',
//   opacity: 1,

//   '& > .sk-circle': {
//     width: '100%',
//     height: '100%',
//     position: 'absolute',
//     left: 0,
//     top: 0,
//   },
  
//   '& > .sk-circle:before': {
//     content: '',
//     display: 'block',
//     margin: '0 auto',
//     width: '15%',
//     height: '15%',
//     backgroundColor: Colors.white,
//     borderRadius: '100%',
//     animation: `${circleFadeDelay} 1.2s infinite ease-in-out both`
//   },
//   '& > .sk-circle2': {
//     // '-webkit-transform': 'rotate(30deg)',
//         // '-ms-transform': 'rotate(30deg)',
//             transform: 'rotate(30deg)'
//   },
//   '& > .sk-circle3': {
//     // '-webkit-transform': 'rotate(60deg)',
//     //     '-ms-transform': 'rotate(60deg)',
//             transform: 'rotate(60deg)'
//   },
//   '& > .sk-circle4': {
//     // '-webkit-transform': 'rotate(90deg)',
//     //     '-ms-transform': 'rotate(90deg)',
//             transform: 'rotate(90deg)'
//   },
//   '& > .sk-circle5': {
//     // '-webkit-transform': 'rotate(120deg)',
//     //     '-ms-transform': 'rotate(120deg)',
//             transform: 'rotate(120deg)'
//   },
//   '& > .sk-circle6': {
//     // '-webkit-transform': 'rotate(150deg)',
//     //     '-ms-transform': 'rotate(150deg)',
//             transform: 'rotate(150deg)'
//   },
//   '& > .sk-circle7': {
//     // '-webkit-transform': 'rotate(180deg)',
//     //     '-ms-transform': 'rotate(180deg)',
//             transform: 'rotate(180deg)'
//   },
//   '& > .sk-circle8': {
//     // '-webkit-transform': 'rotate(210deg)',
//     //     '-ms-transform': 'rotate(210deg)',
//             transform: 'rotate(210deg)'
//   },
//   '& > .sk-circle9': {
//     // '-webkit-transform': 'rotate(240deg)',
//     //     '-ms-transform': 'rotate(240deg)',
//             transform: 'rotate(240deg)'
//   },
//   '& > .sk-circle10': {
//     // '-webkit-transform': 'rotate(270deg)',
//     //     '-ms-transform': 'rotate(270deg)',
//             transform: 'rotate(270deg)'
//   },
//   '& > .sk-circle11': {
//     // '-webkit-transform': 'rotate(300deg)',
//     //     '-ms-transform': 'rotate(300deg)',
//             transform: 'rotate(300deg)'
//   },
//   '& > .sk-circle12': {
//     // '-webkit-transform': 'rotate(330deg)',
//     //     '-ms-transform': 'rotate(330deg)',
//             transform: 'rotate(330deg)'
//   },
//   '& > .sk-circle2:before': {
//     animationDelay: '-1.1s'
//   },
//   '& > .sk-circle3:before': {
//     animationDelay: '-1s'
//   },
//   '& > .sk-circle4:before': {
//     animationDelay: '-0.9s'
//   },
//   '& > .sk-circle5:before': {
//     animationDelay: '-0.8s'
//   },
//   '& > .sk-circle6:before': {
//     animationDelay: '-0.7s'
//   },
//   '& > .sk-circle7:before': {
//     animationDelay: '-0.6s'
//   },
//   '& > .sk-circle8:before': {
//     animationDelay: '-0.5s'
//   },
//   '& > .sk-circle9:before': {
//     animationDelay: '-0.4s'
//   },
//   '& >.sk-circle10:before': {
//     animationDelay: '-0.3s'
//   },
//   '& > .sk-circle11:before': {
//     animationDelay: '-0.2s'
//   },
//   '& > .sk-circle12:before': {
//     animationDelay: '-0.1s'
//   }
// })