import * as React from 'react'
import { css } from 'glamor'

import { Colors } from 'types/colors'

interface Props {
  title: string
  showRight: boolean
  rightIcon?: any
  rightTitle?: string
  rightAction?: () => void
}

interface DefaultProps {
  children?: any
}

export const EmptyPanelHeader = ({ children }: DefaultProps) => (
  <div className="panel-header" {...style}>
    {children}
  </div>
)

export const PanelHeader = ({ title, rightAction, rightTitle, rightIcon, showRight }: Props) => (
  <div className="panel-header" {...style}>
    <h1>{title}</h1>
    {showRight && rightAction && rightTitle &&
      <div className="right" onClick={rightAction}>
        {rightTitle}
        {rightIcon}
      </div>}
  </div>
)

const style = css({
  width: '100%',
  alignSelf: 'center',
  height: '75px',
  borderBottom: `1px solid ${Colors.darkViolet}`,
  color: Colors.white,
  padding: '10px 24px !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& > img': {
    width: '25px'
  },

  '& > h1': {
    fontSize: '1.9em'
  },

  '& > .right': {
    cursor: 'pointer',
    color: Colors.lightGray,
    display: 'flex',
    alignItems: 'center'
  },

  '& > .right > .icon': {
    marginLeft: '12px'
  },

  '& > .right > .icon > path': {
    fill: Colors.lightGray
  },

  '& > .right:hover': {
    color: Colors.white
  },

  '& > .right:hover > .icon > path': {
    fill: Colors.white
  },
})
