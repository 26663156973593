import * as React from 'react'
import { css } from 'glamor'

import { Colors } from 'types/colors'
import OptionInfo from './OptionInfo'
import { OptionInstrument, Instrument } from 'types/classes-instrument'
import { Carousel } from 'components/Carousel'

interface Props {
  options: OptionInstrument[]
  onClickOption: (instrument: Instrument) => void
}

export default class OptionsCarousel extends React.PureComponent<Props> {

  get callOptions() {
    return this.props.options.filter(o => o.type === 'CALL_OPTION')  
  }

  get putOptions() {
    return this.props.options.filter(o => o.type === 'PUT_OPTION')  
  }

  section = (title: string, options: OptionInstrument[]) => {
    return (
      <div className="section">
        <div className="header">{title}</div>
        <Carousel 
          id={`${title}-options-carousel`} 
          backgroundColor={Colors.lightViolet}>
          {options.map((o, key) => (
            <OptionInfo
              key={key}
              option={o}
              onClick={this.props.onClickOption}
            />))
          }
        </Carousel>
      </div>
    )
  }

  render() {
    const calls = this.callOptions
    const puts = this.putOptions
    return (
      <div {...style} >
        {calls.length > 0 && this.section('CALLS', calls)}
        {puts.length > 0 && this.section('PUTS', puts)}
      </div>
    );
  }
}

const style = css({
  padding: '20px 0',
  display: 'flex',
  flexDirection: 'column',
  
  '& > .section': {
    width: '100%',
    marginBottom: '20px',
  },

  '& > .section > .header': {
    width: '100%',
    paddingLeft: '30px',
    color: Colors.lightGray
  },

  '& > .section > .carousel-container': {
    margin: '0 30px',
    display: 'flex',
    overflow: 'auto'
  },
})
