import * as React from 'react'
import { css } from 'glamor'
import * as moment from 'moment'

import { formatSize } from 'types/classes-instrument'
import PositionDataInfo from './PositionDataInfo'

interface Props {
  isFuture: boolean
  position: DetailedPositionType
  formatter: Intl.NumberFormat
}

export default class PositionDataCarousel extends React.PureComponent<Props> {

  get settlementDate() {
    if (this.props.position.lastSettlementDate) {
      return moment(this.props.position.lastSettlementDate).format('DD/MM')
    }
    return null
  }

  get buySize() {
    if (this.props.position.buySize) {
      return formatSize(this.props.position.buySize)
    }
    return null
  }

  get sellSize() {
    if (this.props.position.sellSize) {
      return formatSize(this.props.position.sellSize)
    }
    return null
  }

  get dailyDiff() {
    const { position, formatter } = this.props
    if (position.dailyDiff) {
      return formatter.format(position.dailyDiff)
    }
    return null
  }

  get totalDiff() {
    const { position, formatter } = this.props
    if (position.totalDiff) {
      return formatter.format(position.totalDiff)
    }
    return null
  }

  get marketValue() {
    const { position, formatter, isFuture } = this.props
    if (position.marketValue !== null && !isFuture) {
      return formatter.format(position.marketValue)
    }
    return null
  }

  get sharesName() {
    switch (this.props.position.contractType) {
      case 'STOCK': 
      case 'CEDEAR': 
        return 'Acciones'
      case 'BOND': return 'Nominales'
      default: return 'Contratos'
    }
  }

  get buyTitle() {
    switch (this.props.position.contractType) {
      case 'STOCK': 
      case 'CEDEAR': 
        return this.sharesName + ' Compradas'
      default: return this.sharesName + ' Comprados'
    }
  }

  get sellTitle() {
    switch (this.props.position.contractType) {
      case 'STOCK': 
      case 'CEDEAR': 
        return this.sharesName + ' Vendidas'
      default: return this.sharesName + ' Vendidos'
    }
  }

  render() {
    return (
      <div {...style} >
        {this.settlementDate && <PositionDataInfo title="Fecha de Liquidación" value={this.settlementDate}/>}
        {this.buySize && <PositionDataInfo title={this.buyTitle} value={this.buySize}/>}
        {this.sellSize && <PositionDataInfo title={this.sellTitle} value={this.sellSize}/>}
        {this.dailyDiff && <PositionDataInfo title="Ganancia Diaria" value={this.dailyDiff}/>}
        {this.totalDiff && <PositionDataInfo title="Ganancia Total" value={this.totalDiff}/>}
        {this.marketValue && <PositionDataInfo title="Valór de Posición" value={this.marketValue}/>}
      </div>
    );
  }
}

const style = css({
  display: 'flex',
  overflow: 'auto',
  margin: '0 30px',

  '&::-webkit-scrollbar' : {
    display: 'none'
  }
})
