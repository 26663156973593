import * as React from 'react'
import { css } from 'glamor'
import { Dispatch, bindActionCreators, Action } from 'redux'
import { connect } from 'react-redux'
import * as moment from 'moment'

import { emptyAccountReport } from 'types/classes-instrument'
import { Colors } from 'types/colors'
import { AccountReportState } from 'reducers/user'
import { fetchAccountReport } from 'actions/user'
import { Loading } from '../Loading'
import { NetworkError } from '../EmptyState'

import { RootState } from 'reducers'

interface Props {
  account?: PrimaryAccount
  accountReport: AccountReportState
}

interface ActionCreators {
  fetchAccountReport: (account: PrimaryAccount) => any
}

interface State {
  settlement: Settlement
}

const mapStateToProps = ({ user }: RootState) => ({
  account: user.selectedAccount,
  accountReport: user.accountReport
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators({ fetchAccountReport }, dispatch)

class AccountReportModal extends React.Component<Props & ActionCreators, State> {
  state: State = {
    settlement: '0'
  }

  fetchAccountReport = () => {
    const { account, fetchAccountReport } = this.props
    if (account) { fetchAccountReport(account) }
  }

  componentDidMount() {
    this.fetchAccountReport()
  }

  row(title: string, value: number | string, isTitle: boolean = false) {
    const formatter = new Intl.NumberFormat('es-AR', {
      'style': 'currency',
      'currency': 'ARS',
      'useGrouping': true,
      'maximumFractionDigits': 2,
      'minimumFractionDigits': 2
    })
    const className = (isTitle) ? 'row big' : 'row'
    return (
      <div {...rowStyle} className={className}>
        <p className="title">{title}</p>
        {value === null && <p className="value">{formatter.format(0)}</p>}
        {typeof value === 'string' && <p className="value">{value}</p>}
        {typeof value === 'number' && <p className="value">{formatter.format(value)}</p>}
      </div>
    )
  }

  selectSettlement = (settlement: Settlement) => {
    return () => { this.setState({ settlement }) }
  }

  list(data: AccountReportJSON) {
    return <div className="list">
            {this.row('Efectivo Disponible', data.currentCash)}
            {this.row('Otras Garantías', data.collateral)}
            {this.row('Valor Posición', data.portfolio)}
            {this.row('Márgenes Operaciones', -data.margin)}
            {this.row('Márgenes Ordenes', -data.ordersMargin)}
            {this.row('Márgenes p/ Descubierto', -data.uncoveredMargin)}
            {this.row('Disponible Garantías', data.availableToCollateral, true)}
          </div>
  }

  report(selectedSettlement: Settlement, data: AccountReportJSON) {
    const allSettlementsTitles: string[] = ['C.I.', '24hs', '48hs']
    const allSettlements: Settlement[] = ['0', '1', '2']
    const report = data.detailedAccountReports[selectedSettlement] || 
      emptyAccountReport().detailedAccountReports[selectedSettlement]
    return (          
      <div className="list">
        <ul className="tab tab-block">
          {allSettlements.map((settlement, i) => (
            <li key={i} className={(selectedSettlement === settlement) ? 'tab-item active' : 'tab-item'}>
              <a className="settlement" onClick={this.selectSettlement(settlement)}>{allSettlementsTitles[i]}</a>
            </li>))}
        </ul>
        {this.row('Fecha de Liquidación', moment(parseInt(report.settlementDate)).format('DD-MM-YYYY'))}
        {this.row('Diferencias Diarias', data.dailyDiff)}
        {this.row('Crédito', report.availableToOperate.credit)}
        {this.row('Movimientos', report.availableToOperate.movements)}
        {this.row('Movimientos Pendientes', report.availableToOperate.pendingMovements)}
        {this.row('Efectivo Disponible', report.availableToOperate.total, true)}
      </div>
    )
  }

  render() {
    const { accountReport } = this.props
    const data = accountReport.data || emptyAccountReport()
    return (
      <div {...style}>
        <h4 className="title">Reporte de cuenta</h4>
        {(accountReport.status === 'pending' || accountReport.status === 'fetching') && 
            <Loading />}
        {accountReport.status === 'errored' && 
          <NetworkError onRetry={this.fetchAccountReport} />}
        {accountReport.status === 'succeeded' && 
          <div className="report">
            {[this.list(data), this.report(this.state.settlement, data)]}
          </div>
        }
      </div>
    )
  }
}

const rowStyle = css({
  color: Colors.white,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0'
})

const style = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: '24px 0',
  alignSelf: 'center',
  justifyContent: 'center',
  padding: '12px',
  borderRadius: '5pt',
  backgroundColor: Colors.violet,

  '& > .title': {
    padding: '0 0 12px 0',
    color: Colors.lightGray,
    fontSize: '130%'
  },

  '& > .report': {
    display: 'flex',
    width: '100%',
    margin: '0',
    minHeight: '280px',
    justifyContent: 'space-between',

    '@media(max-width: 500px)': {
      flexDirection: 'column'
    },
  },

  '& > .report > .list': {
    padding: '6px 0px',
    width: '49%',
    backgroundColor: Colors.darkViolet,
    borderRadius: '6px',

    '@media(max-width: 500px)': {
      width: '100%',
      marginBottom: '18px',
    },
  },

  '& > .report > .list > .row': {
    height: '35px',
    padding: '12px',
  },

  '& > .report > .list > .big': {
    margin: '12px 9px 9px 9px',
    fontSize: '17px',
    backgroundColor: Colors.blue,
    borderRadius: '5pt',
    color: Colors.white,
    height: '45px'
  },

  '& > .report > .list > .row > .title': {
    color: Colors.lightGray
  },

  '& > .report > .list > .big > .title': {
    color: Colors.darkViolet
  },

  '& > .report > .list > .tab': {
    marginTop: 0,
    marginBottom: '6px',
    height: '30px'
  },

  '& > .report > .list > .tab > .active > a': {
    color: Colors.blue,
    borderBottomColor: Colors.blue,
  },

  '& > .report > .list > .tab > .tab-item > a:hover': {
    color: Colors.blue
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountReportModal)
