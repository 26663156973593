import * as moment from 'moment'
import { PrimaryClient } from '../utils/primary-client'
import { YahooClient } from '../utils/yahoo-client'
import { Instrument, InstrumentsByCategory } from '../types/classes-instrument'
import { listenMarketData } from './watchlist'
import { logout, connectWebsocket } from './user'
import { trackEvent } from '../trackers/index';
import { HermesClient } from 'utils/hermes-client'
import { intraDayTimes } from 'utils/helpers/intraday-times'

export type Action =
  { type: 'REQUEST_INSTRUMENTS' }
  | { type: 'RECEIVE_INSTRUMENTS', instruments: InstrumentsByCategory }
  | { type: 'FAIL_REQUESTING_INSTRUMENTS', error: Error }
  | { type: 'REQUEST_HISTORICAL_DATA', instrument: Instrument, range: GraphRange }
  | { type: 'RECEIVE_HISTORICAL_DATA', symbol: string, historicalOffers: CandleHistoricalOffersData, range: GraphRange }
  | { type: 'FAIL_REQUESTING_HISTORICAL_DATA', symbol: string, range: GraphRange }
  | { type: 'SELECT_INSTRUMENT', instrument: Instrument | null }
  | { type: 'PUSH_HISTORY', instrument: Instrument }
  | { type: 'POP_HISTORY' }
  | { type: 'CLEAR_HISTORY' }

const requestInstruments = (): Action => ({
  type: 'REQUEST_INSTRUMENTS'
})

const receiveInstruments = (instruments: InstrumentsByCategory): Action => ({
  type: 'RECEIVE_INSTRUMENTS',
  instruments
})

const failRequestingInstruments = (error: Error): Action => ({
  type: 'FAIL_REQUESTING_INSTRUMENTS',
  error
})

const requestHistoricalOffers = (instrument: Instrument, range: GraphRange): Action => ({
  type: 'REQUEST_HISTORICAL_DATA',
  instrument,
  range
})

const receiveHistoricalOffers = (symbol: string, historicalOffers: CandleHistoricalOffersData, range: GraphRange): Action => ({
  type: 'RECEIVE_HISTORICAL_DATA',
  symbol,
  historicalOffers,
  range
})

const failRequestingHistoricalOffers = (symbol: string, range: GraphRange): Action => ({
  type: 'FAIL_REQUESTING_HISTORICAL_DATA',
  symbol,
  range
})

const selectInstrument = (instrument: Instrument | null): Action => ({
  type: 'SELECT_INSTRUMENT',
  instrument
})

const pushHistory = (instrument: Instrument): Action => ({
  type: 'PUSH_HISTORY',
  instrument
})

const popHistory = (): Action => ({ type: 'POP_HISTORY' })

export const clearHistorial = () => (dispatch: Function, getState: Function) => {
  dispatch({ type: 'CLEAR_HISTORY' })
  chooseInstrument(null)(dispatch, getState)
}

export const popInstrument = () => (dispatch: Function, getState: Function) => {
  dispatch(popHistory())
  let instrumentHistory = getState().instrumentHistory
  let instrument = instrumentHistory.pop()
  instrument = instrument === undefined ? null : instrument
  chooseInstrument(instrument)(dispatch, getState)
}

export const chooseInstrument = (instrument: Instrument | null) => (dispatch: Function, getState: Function) => {
  dispatch(selectInstrument(instrument))
  if (instrument !== null) {
    dispatch(fetchHistoricalOffers(instrument, '1D'))
    dispatch(listenMarketData(instrument))
    dispatch(pushHistory(instrument))
  }
}

const receiveInstrumentsCallback = (dispatch: Function,  getState: Function, instruments: InstrumentsByCategory) => {
  dispatch(connectWebsocket())
  dispatch(receiveInstruments(instruments))
  trackEvent('application-open')
  // TODO: ELIMINAR CUANDO HAYA HOME
  const rfx20 = instruments.indexInstruments.find(i => i.ticker === 'RFX20')
  if (rfx20 !== undefined) chooseInstrument(rfx20)(dispatch, getState)
}

export const fetchAllInstruments = () => (dispatch: Function, getState: Function) => {
  const broker = getState().user.broker
  let startPromise = Promise.resolve()
  if (!broker) {
    startPromise = PrimaryClient.defaultLogin().then()
  }
  return startPromise.then(_ => HermesClient.getInstruments(broker)).then(instruments => {
    receiveInstrumentsCallback(dispatch, getState, instruments)
  }).catch(e => {
    dispatch(logout())
    defaultLoginAndGetInstruments(false)
  })
}

export const defaultLoginAndGetInstruments = (dispatchRequest: boolean = true) => (dispatch: Function, getState: Function) => {
  if (dispatchRequest) dispatch(requestInstruments())
  PrimaryClient.defaultLogin().then(r => HermesClient.getInstruments()).then(instruments => {
    receiveInstrumentsCallback(dispatch, getState, instruments)
  }).catch(e => dispatch(failRequestingInstruments(e)))
}

export const fetchHistoricalOffers = (instrument: Instrument, range: GraphRange) =>
  (dispatch: Function, getState: Function) => {
    dispatch(requestHistoricalOffers(instrument, range))
    var promise: Promise<CandleHistoricalOffersData>
    if (instrument.type === 'STOCK' && (range === '1D' || range === '5D')) {
      promise = YahooClient.getHistoricalMarketData(instrument, range)
    } else {
      promise = HermesClient.getPrices(instrument, range)
    }
    return promise
      .then(offersData => completeIntradayDatetimes(offersData, range, instrument.segmentSubtype?.closeTime))
      .then(offersData => addLastPrice(offersData, range, getState().marketData.all[instrument.symbol]))
      .then(offersData => dispatch(receiveHistoricalOffers(instrument.symbol, offersData, range)))
      .catch(o => dispatch(failRequestingHistoricalOffers(instrument.symbol, range)))
}

const completeIntradayDatetimes = (offersData: CandleHistoricalOffersData, range: GraphRange, closeTime?: string) => {
  let datetimeData = offersData.datetimeData
  if (closeTime && range === '1D' && datetimeData.length > 0) {
    const lastTime = datetimeData[datetimeData.length - 1]
    const lastIndex = intraDayTimes.indexOf(lastTime)
    const closeIndex = intraDayTimes.indexOf(closeTime) || intraDayTimes.length - 1
    const latestTimes = intraDayTimes.slice(lastIndex + 1, closeIndex + 1)
    offersData.datetimeData = datetimeData.concat(latestTimes)

    const lastPriceDatetimeIndex = offersData.priceData.length - 1
    let nowTime = moment().format('HH:mm')
    let nowIndex = offersData.datetimeData.indexOf(nowTime) || offersData.datetimeData.length - 1
    nowIndex = nowIndex < 0 ? offersData.datetimeData.length - 1 : nowIndex
    const timeToCloseCount = nowIndex - lastPriceDatetimeIndex
    const lastPrice = offersData.priceData[lastPriceDatetimeIndex]
    for (let i = 0; i < timeToCloseCount; i++) {
      offersData.priceData.push(lastPrice)
    }
  }
  return offersData
}

const addLastPrice = (offersData: CandleHistoricalOffersData, range: GraphRange, marketData?: MarketData) => {
  const lastPrice = marketData?.IV || marketData?.LA?.price
  if (marketData && lastPrice && range !== '5D') {
    let formatter = 'D MMM'
    if (range === '1D') formatter = 'HH:mm'
    // if (range === '5D') formatter = 'ddd HH:mm'
    const lastDatetime = moment(marketData.LA?.date).format(formatter)
    let lastIndex = offersData.datetimeData.indexOf(lastDatetime)
    if (lastIndex >= 0 && offersData.priceData[lastIndex]) {
      offersData.priceData.pop()
      offersData.priceData.push(lastPrice)
    } else {
      offersData.priceData.push(lastPrice)
      if (range === '1M' || range === '3M' || range === '6M' || range === '1A') {
        offersData.datetimeData.push(lastDatetime)
        const size = marketData.TV || marketData.NV
        if (size) {
          const close = marketData.OP || 0
          const color = lastPrice >= close ? 'rgba(63,180,152,0.45)' : 'rgba(255,64,25,0.45)'
          offersData.sizeData.push({ x: offersData.sizeData.length, y: size, color })
          if (range === '1M' || range === '3M') {
            offersData.digestedCandleData.sizeData.push({ x: offersData.digestedCandleData.sizeData.length, y: size, color })
          }
        }
        const candle = {
          h: marketData.HI || lastPrice,
          l: marketData.LO || lastPrice,
          o: marketData.OP || lastPrice,
          c: marketData.OP || lastPrice,
          v: size || 0,
          timestamp: marketData.LA?.date,
        }
        offersData.candleData.push(candle)
      }
    }
  }
  return offersData
}
