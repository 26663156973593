import * as React from 'react'
import { css } from 'glamor'

import { PanelHeader } from './PanelHeader'
import { Panel } from './Panel'

interface Props {
  title: string
  showRight?: boolean
  rightIcon?: any
  rightAction?: () => void
  rightTitle?: string
}

export class DetailsPanel extends React.PureComponent<Props> {
  render() {
    const { title, rightTitle, rightAction, rightIcon, showRight = false } = this.props
    return (
      <Panel>
        <PanelHeader 
          title={title} 
          rightTitle={rightTitle} 
          rightIcon={rightIcon} 
          rightAction={rightAction} 
          showRight={showRight} />
        <div className="panel-body" {...style}>
          <div className="content">
            {this.props.children}
          </div>
        </div>
      </Panel>
    );
  }
}

const style = css({
  padding: '0 !important',

  '& > .content': {
    padding: '0 10%',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',

    '@media(max-width: 700px)': {
      padding: '0 18px',
    },
  }
})
