import { Action } from '../actions/orders'
import { Instrument } from '../types/classes-instrument'

export type NewOrderRequestState = RequestStatus<OrderResponse | {}>
export type AllOrdersState = RequestStatus<PrimaryOrderJSON[] | null>
export type CancelOrderState = RequestStatus<OrderResponse | {}>
export interface NewOrderState {
  order: NewOrderRequestState
  side: OrderSide
  instrument?: Instrument
  modalShown: boolean
  offer?: OfferJSON
}

export interface State {
  new: NewOrderState
  all: AllOrdersState
  cancel: CancelOrderState
}

const initialAllOrdersState: AllOrdersState = {
  status: 'pending',
  error: null,
  data: null
}

const initialNewOrderRequestState: NewOrderRequestState = {
  status: 'pending',
  error: null,
  data: {}
}

const initialCancelOrderState: CancelOrderState = {
  status: 'pending',
  error: null,
  data: {}
}

const initialNewOrderState: NewOrderState = {
  order: initialNewOrderRequestState,
  side: 'BUY',
  modalShown: false,
  offer: undefined
}

const initialState: State = {
  new: initialNewOrderState,
  all: initialAllOrdersState,
  cancel: initialCancelOrderState
}

export const orders = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'REQUEST_ALL_ORDERS':
      return {
        ...state,
        all: {
          status: 'fetching',
          error: null,
          data: []
        }
      }
    case 'RECEIVE_ALL_ORDERS':
      return {
        ...state,
        all: {
          status: 'succeeded',
          error: null,
          data: action.orders
        }
      }
    case 'FAIL_REQUESTING_ALL_ORDERS':
      return {
        ...state,
        all: {
          status: 'errored',
          error: action.error,
          data: []
        }
      }
    case 'REQUEST_NEW_ORDER':
      return {
        ...state,
        new: {
          ...state.new,
          order: {
            status: 'fetching',
            error: null,
            data: {}
          }
        }
      }
    case 'RECEIVE_NEW_ORDER':
      return {
        ...state,
        new: {
          ...state.new,
          order: {
            status: 'succeeded',
            error: null,
            data: action.orderResponse
          }
        }
      }
    case 'FAIL_REQUESTING_NEW_ORDER':
      return {
        ...state,
        new: {
          ...state.new,
          order: {
            status: 'errored',
            error: action.error,
            data: {}
          }
        }
      }
    case 'OPEN_NEW_ORDER_MODAL':
      return {
        ...state,
        new: {
          ...state.new,
          side: action.side,
          instrument: action.instrument,
          modalShown: true,
          offer: action.offer
        }
      }
    case 'CLOSE_NEW_ORDER_MODAL':
      return {
        ...state,
        new: initialNewOrderState
      }
    case 'REQUEST_CANCEL_ORDER':
      return {
        ...state,
        cancel: {
          status: 'fetching',
          error: null,
          data: []
        }
      }
    case 'RECEIVE_CANCEL_ORDER':
      return {
        ...state,
        cancel: {
          status: 'succeeded',
          error: null,
          data: action.orderResponse
        }
      }
    case 'FAIL_REQUESTING_CANCEL_ORDER':
      return {
        ...state,
        cancel: {
          status: 'errored',
          error: action.error,
          data: []
        }
      }
    default:
      return state
  }
}
