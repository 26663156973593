import * as React from 'react'
import { Dispatch, bindActionCreators, Action } from 'redux'
import { connect } from 'react-redux'

import { DetailsPanel } from '../DetailsPanel'
import { LoginEmptyState, NotAccount } from '../EmptyState'
import MyPositionsModal from './MyPositionsModal'
import AccountReportModal from './AccountReportModal'

import { State as UserState } from 'reducers/user'
import { logout, openLoginModal } from 'actions/user'
import { LogOutIcon } from 'components/Icons'

import { RootState } from 'reducers'

interface Props {
  user: UserState
  openLoginModal: () => void
  logout: () => void
}

const mapStateToProps = ({ user }: RootState) => ({
  user: user
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) => 
  bindActionCreators({ openLoginModal, logout }, dispatch)

class AccountPanel extends React.Component<Props> {
  
  logout = () => {
    this.props.logout()
  }

  render() {
    const { user, openLoginModal } = this.props
    const isLoggedIn = process.env.IS_BROWSER !== undefined && user.loggedIn === true
    const hasAccount = user.selectedAccount !== null
    return (
      <DetailsPanel 
        title="Cuenta" 
        rightTitle="Cerrar sesión"
        rightIcon={<LogOutIcon className="icon" />} 
        rightAction={this.logout} 
        showRight={isLoggedIn} >
        {isLoggedIn && !hasAccount && <NotAccount />}
        {isLoggedIn && hasAccount && <AccountReportModal />}
        {isLoggedIn && hasAccount && <MyPositionsModal />}
        {process.env.IS_BROWSER && !user.loggedIn && <LoginEmptyState onRetry={openLoginModal}/>}
      </DetailsPanel>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPanel)