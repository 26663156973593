import * as React from 'react'
import { css } from 'glamor'
import { Colors } from 'types/colors'

interface Props {
  title: string
  value: string
}

export default class PositionDataInfo extends React.PureComponent<Props> {
  render() {
    return (
      <div {...style} >
        <div className="value">{this.props.value}</div>
        <div className="title">{this.props.title}</div>
      </div>
    );
  }
}

const style = css({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '20px',

  '& > .value': {
    color: Colors.white,
    textAlign: 'left',
    padding: '12px 0 6px 0',
    fontSize: '22px'
  },

  '& > .title': {
    color: Colors.lightGray,
    textAlign: 'left',
    paddingBottom: '12px',
    fontSize: '12px'
  },
})
