export namespace PrimalyticsClient {

  const BASE_URL = 'https://api.store.primary.ventures/stats.php'

  // Orders
  export const createNewOrder = 
    (order: NewOrderJSON, clientId: string, userName: string, brokerId: string): Promise<{}> => {
    const body = new URLSearchParams()
    body.append('tipo', 'orden')
    body.append('login_tipo', '2')
    body.append('broker_id', brokerId)
    body.append('usuario', userName)
    body.append('cuenta', order.account.name)
    body.append('orden_id', clientId)
    body.append('cantidad', order.orderQty)
    body.append('simbolo', order.instrument.symbol)
    body.append('precio', order.price ? order.price : order.rawPrice)
    body.append('operacion', order.side)
    body.append('operacion_tipo', order.ordType)

    const fetchConfig: RequestInit = {
      method: 'POST',
      mode: 'cors',
      redirect: 'error',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Authorization': 'Basic ' + btoa('emilianobivachi@gmail.com:46622cd7680e13e') 
      },
      body: body
    }
    return fetch(BASE_URL, fetchConfig)
      .then<{}>(response => {
        return response.json()
      })
  }
}