import * as React from 'react'
import * as chartjs from 'chart.js'
import { css } from 'glamor'
import { groupBy, values } from 'lodash'

import 'utils/helpers/financial-charts'
import { Candlestick } from 'utils/helpers/Candlestick'
import { Colors } from 'types/colors'
import { defaultNumberFormatter, formatCandleData } from 'types/classes-instrument'
import { assetUrl } from 'utils/assets'
const closeIcon = assetUrl('close.svg')

interface Props {
  charts: ChartProps[]
  datetimeData: string[]
  maxXTicks: number
  maxYTicks: number
  drawXAxis: boolean
  drawGradient: boolean
  drawGridLines: boolean
  removeChart?: (id: string) => () => any
  height?: string
}

function colorize(ctx: any) {
  var v = ctx.dataset.data[ctx.dataIndex];
  return v && v.color ? v.color : Colors.green
}

const drawDatetimeText = (ctx: any, x: number, y: number, w: number, h: number, text: string) => {
  var Paint = {
    RECTANGLE_FILL_STYLE : Colors.blue,
    TEXT_FILL_STYLE : Colors.white,
    VALUE_FONT : '12px Source Sans Pro'
  }  
  if (ctx) {
    // draw rectangular
    ctx.fillStyle = Paint.RECTANGLE_FILL_STYLE
    ctx.fillRect(x, y, w, h)
    // draw text
    ctx.textBaseline = 'middle'
    ctx.font = Paint.VALUE_FONT
    ctx.fillStyle = Paint.TEXT_FILL_STYLE
    const textX = x + w / 2 - ctx.measureText(text).width / 2
    const textY = y + h / 2
    ctx.fillText(text, textX, textY)
  }
}

export const drawLineAndTime = (ctx: any, x: number, topY: number, bottomY: number, time: string, drawTime: boolean) => {
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(x, topY);
  ctx.lineTo(x, bottomY);
  ctx.lineWidth = 1.5;
  ctx.strokeStyle = Colors.blue;
  ctx.stroke();
  ctx.restore();

  if (drawTime) {
    const width = 70
    const height = 33
    drawDatetimeText(ctx, x - width / 2, bottomY, width, height, time)
  }
}

interface State {
  random: number
}

export default class Chart extends React.PureComponent<Props, State> {
  state = {
    random: Math.floor(Math.random() * 100)
  }

  componentDidMount() {
    chartjs.Chart.pluginService.register({
      afterDatasetsDraw: this.drawLinesAndText.bind(this)
    })
  }

  get maxPrice() {
    var maximum = 0
    this.props.charts.forEach(c => c.type !== 'bar' ? maximum = Math.max(maximum, c.max) : maximum)
    return maximum
  }

  get minPrice() {
    var minimum = 999999999
    this.props.charts.forEach(c => c.type !== 'bar' ? minimum = Math.min(minimum, c.min) : minimum)
    return minimum
  }

  get maxSize() {
    var maximum = 0
    this.props.charts.forEach(c => c.type === 'bar' ? maximum = Math.max(maximum, c.max) : maximum)
    return maximum
  }

  get minSize() {
    var minimum = 999999999
    this.props.charts.forEach(c => c.type === 'bar' ? minimum = Math.min(minimum, c.min) : minimum)
    return minimum
  }

  get chartsByTitle() {
    return values(groupBy(this.props.charts, c => c.title))
  }

  updateTooltip = (tooltip: any, data: any) => {
    const { random } = this.state
    if (tooltip.dataPoints && tooltip.dataPoints.length > 0) {
      const index = tooltip.dataPoints[0].index
      this.chartsByTitle.forEach(c => {
        if (c[0].doNotDrawTooltip) { return }
        const valueDiv = document.getElementById(`${random}-tooltip-value-${c[0].id}`)
        const defaultChart = c[0]
        const data = defaultChart.data[index]
        const formatter = defaultChart.valueFormatter ?? defaultNumberFormatter
        if (defaultChart.tooltipFormatter && valueDiv) {
          const values = c.map(i => i.data[index])
          valueDiv.innerText = defaultChart.tooltipFormatter(values)
        } else if (data && valueDiv) {
          valueDiv.innerText = formatCandleData(data, formatter)
        } else if (valueDiv) {
          valueDiv.innerText = "--"
        }
      })
    } else {
      this.props.charts.forEach(c => {
        const valueDiv = document.getElementById(`${random}-tooltip-value-${c.id}`)
        if (valueDiv) valueDiv.innerText = "--"
      })
    }
  }
  
  drawLinesAndText = (chart: any, easing: string) => {
    if (chart.tooltip._active
      && chart.tooltip._active.length
      && chart.config.options.label === this.state.random) {
      let activePoint = chart.tooltip._active[0]
      let x = activePoint.tooltipPosition().x
      const time = chart.chart.config.data.labels[chart.active[0]._index]
      let ctx = chart.ctx
      let topY = chart.chartArea.top
      let bottomY = chart.chartArea.bottom
      drawLineAndTime(ctx, x, topY, bottomY, time, this.props.drawXAxis)
    }
  }

  gradientStartColor(color: string) {
    let a = Math.round(0.35 * 255).toString(16)
    if (a.length == 1) a = "0" + a
    return color + a
  }

  dataset = (data: any, type: string, id: string, color?: string, backgroundColor?: any, borderWith: number = 1.5) => {
    var basic: any = {
      type,
      yAxisID: id,
      data,
      backgroundColor,
      borderColor: color,
      borderWidth: 0,
      fill: backgroundColor === undefined ? false : true,
    }
    if (type === 'candlestick') {
      const candleColors = {
        up: Colors.green,
        down: Colors.red,
        unchanged: '#999',
      }
      basic.borderColor = candleColors
      basic.color = candleColors
      basic.backgroundColor = undefined
      basic.fill = false
    } else if (type === 'line') {
      basic.pointRadius = 0
      basic.lineTension = 0
      basic.borderWidth = borderWith
    } else if (type === 'bar') {
      basic.fill = true
      basic.backgroundColor = undefined
    }
    return basic
  }

  gradientForColor = (canvas: HTMLElement, color: string) => {
    const { drawGradient } = this.props
    var gradient: CanvasGradient | undefined = undefined
    if (drawGradient && canvas instanceof HTMLCanvasElement) {
      gradient = canvas.getContext('2d')!.createLinearGradient(0, 0, 0, 250);
      gradient.addColorStop(0, this.gradientStartColor(color));
      gradient.addColorStop(1, 'rgba(0,0,0,0)');
    }
    return gradient
  }

  datasets = () => (canvas: HTMLElement) => {
    const { charts, datetimeData } = this.props
    var datasets: any = []
    charts.forEach(c => {
      if (c.type === 'annotation') return
      datasets.push(this.dataset(c.data, c.type, c.id, c.color, this.gradientForColor(canvas, c.color), c.borderWidth))
    })
    return {
      labels: datetimeData,
      datasets
    }
  }

  commonChartOptions(): any {
    return {
      layout: { padding: { top: 10, right: 10, left: 20, bottom: 0 } },
      animation: { duration: 0 },
      responsive: true,
      maintainAspectRatio: false,
      label: this.state.random,
      hover: {
        intersect: false,
        mode: 'index',
        animationDuration: 0
      },
      tooltips: {
        intersect: false,
        enabled: false,
        mode: 'index',
        custom: this.updateTooltip.bind(this)
      },
      elements: {
				rectangle: {
					backgroundColor: colorize,
					borderColor: colorize,
          borderWidth: 0
				}
      }
    }
  }

  yAxes(type: string, id: string, drawYAxis: boolean, formatter: Intl.NumberFormat = defaultNumberFormatter, forceLimit: boolean = false): any {
    if (type === 'bar') {
      const max = this.maxSize
      const min = this.minSize
      const limits = forceLimit
        ? { max, min, stepSize: (max - min) / this.props.maxYTicks }
        : { suggestedMax: max, suggestedMin: min }
      return {
        display: false,
        gridLines: { drawBorder: false },
        id,
        source: 'data',
        position: 'right',
        ticks: {
          ...limits,
          maxTicksLimit: this.props.maxYTicks
        }
      }
    }
    const max = this.maxPrice
    const min = this.minPrice
    const limits = forceLimit 
      ? { max, min, stepSize: (max - min) / this.props.maxYTicks }
      : { suggestedMax: max, suggestedMin: min }
    const afterFit = drawYAxis 
      ? { afterFit: (scaleInstance: any) => { scaleInstance.width = 60 } }
      : {}
    return {
      display: drawYAxis,
      type,
      id,
      position: 'right',
      ...afterFit,
      gridLines: {
        display: this.props.drawGridLines,
        drawBorder: false,
        borderDash: [1, 4],
        color: Colors.lightGray,
        zeroLineColor: Colors.lightGray,
        zeroLineBorderDash: [1, 4],
      },
      ticks: {
        callback: (value: any, index: any, values: any) => formatter.format(value),
        maxTicksLimit: this.props.maxYTicks,
        ...limits,
      }
    }
  }

  xAxes(): any {
    return [
      {
        offset: true,
        gridLines: {
          display: this.props.drawGridLines,
          drawTicks: this.props.drawXAxis,
          drawBorder: false,
          offsetGridLines: false,
          drawOnChartArea: true,
          borderDash: [1, 4],
          color: Colors.lightGray
        },
        ticks: {
          max: this.props.maxXTicks,
          min: this.props.maxXTicks,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: this.props.maxXTicks,
          callback: (value: any, index: any, values: any) => this.props.drawXAxis ? value : '',
        }
      }
    ]
  }

  annotation = (chart: ChartProps) => {
    const value = chart.data[0]
    return {
      id: `${chart.id}-${value}`,
      type: 'line',
      mode: 'horizontal',
      scaleID: chart.id,
      value: chart.data[0],
      borderColor: chart.color,
      borderDash: [2, 4],
      borderWidth: 1,
      label: chart.label
    }
  }

  chartOptions(): any {
    var yAxes: any[] = []
    var annotations: any[] = []
    this.props.charts.forEach(c => {
      if (c.type === 'annotation') {
        annotations.push(this.annotation(c))
      } else {
        var type = c.type === 'candlestick' ? 'financialLinear' : 'linear'
        type = c.type === 'bar' ? 'bar' : type
        yAxes.push(this.yAxes(type, c.id, c.drawYAxis, c.valueFormatter, c.forceLimit))
      }
    })
    return {
      ...this.commonChartOptions(),
      scales: {
        type: 'financialLinear',
        yAxes,
        xAxes: this.xAxes()
      },
      annotation: {
        drawTime: 'afterDatasetsDraw',
        annotations,
      }
    }
  }

  tooltipRow = (chart: ChartProps, key: number) => {
    const { removeChart } = this.props
    const { random } = this.state
    if (chart.doNotDrawTooltip) return
    return (
      <div className="row" id={`tooltip-${chart.id}`} key={key}>
        <div className="color" id={`${random}-tooltip-color-${chart.id}`} {...backgroundColorStyle(chart.color)}/>
        <div className="title" id={`${random}-tooltip-title-${chart.id}`}>{chart.title}</div>
        <div className="value" id={`${random}-tooltip-value-${chart.id}`}>--</div>
        {chart.drawRemove && removeChart && <div className="remove" id={`${random}-tooltip-remove-${chart.id}`} onClick={removeChart(chart.id)}>
          <img alt={"remover"} className="icon" src={closeIcon}/>
        </div>}
      </div>
    )
  }

  render() {
    const { height } = this.props
    return (
      <div {...style(height)}>
        <div className="chart-tooltip">
          {this.chartsByTitle.map((c, i) => this.tooltipRow(c[0], i)) }
        </div>
        <Candlestick
          data={this.datasets()}
          legend={{ display: false }}
          options={this.chartOptions()}
          redraw={true}
        />
      </div>
    )
  }
}


const style = (height?: string) => css({
  position: 'relative',
  color: Colors.blue,
  height: height ? height : '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& > .chart-tooltip': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    zIndex: 400,
    position: 'absolute',
    top: '12px',
    left: '12px',

    '& > .row': {
      height: '30px',
      minWidth: '120px',
      marginBottom: '8px',
      backgroundColor: 'rgba(17,19,37)',
      borderRadius: '4pt',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: Colors.white,
      '& > .color': {
        width: '8px',
        height: '100%',
        borderRadius: '4pt 0 0 4pt'
      },
      '& > .title': {
        marginLeft: '8px',
        fontSize: '12px',
        color: Colors.lightGray,
        marginRight: '14px',
      },
      '& > .value': {
        textAlign: 'right',
        marginRight: '8px',
        flexGrow: 2
      },
      '& > .remove': {
        cursor: 'pointer',
        marginLeft: '4px',
        marginRight: '8px',
        '& > img': {
          width: '8px',
          height: '8px',
        }
      },
    }
  },
})

const backgroundColorStyle = (color: string) => {
  return css({
    backgroundColor: color,
  })
}
