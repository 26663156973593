import Bugsnag from '@bugsnag/js'
import * as React from 'react'
import { css } from 'glamor'
import { Dispatch, bindActionCreators, Action } from 'redux'
import { connect } from 'react-redux'

import { State as UserState } from 'reducers/user'
import { NewOrderState } from 'reducers/orders'
// import { UserPanel } from 'components/UserPanel';
import { fetchAllInstruments } from 'actions/instruments'
import { fetchMyInformation } from 'actions/user'
// import NewOrderModal from 'components/NewOrderModal'
// import LoginModal from 'components/LoginModal'
import DesktopDownloadAppBanner from 'components/DesktopDownloadAppBanner'
import DownloadAppBanner from 'components/DownloadAppBanner'
// import { ArrowLeftIcon } from 'components/Icons'
import { trackEvent, setUserProperties } from 'trackers'
// import { Colors } from 'types/colors'
import { RootState } from 'reducers'
import isMobile from 'utils/isMobile'
// import FullScrenChartPanel from 'components/FullScrenChartPanel'
// import AccountsModal from 'components/AccountsModal'

css.global('p,h1,h2,h3,h4,h5',  { margin: 0 })

interface ActionCreators {
  fetchAllInstruments: () => any
  fetchMyInformation: (user?: string, accounts?: PrimaryAccount[], account?: PrimaryAccount) => any
}

interface Props {
  page: string
  user: UserState
  newOrder: NewOrderState
  loginModalShown: boolean
  fullScreenChart: boolean
  accountsModalShown: boolean
  children: any
}

const mapStateToProps = ({ user, orders, app }: RootState) => ({
  user: user,
  loginModalShown: user.loginModalShown,
  fullScreenChart: app.fullScreenChart,
  newOrder: orders.new,
  accountsModalShown: user.accountsModalShown,
})

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators({ fetchAllInstruments, fetchMyInformation }, dispatch)

class App extends React.Component<Props & ActionCreators, {}> {

  componentDidCatch(error: any, info: any) {
    Bugsnag.notify(error, event => {
      event.severity = 'error',
      event.addMetadata('component', { React: info })
    })
  }

  componentDidMount() {
    const { fetchAllInstruments, fetchMyInformation, user } = this.props
    fetchAllInstruments()
    trackEvent('application-open')
    fetchMyInformation(user.name, user.accounts.data, user.selectedAccount)
    setUserProperties({
      'loggedIn': user.loggedIn,
      'user': user.name,
      'brokerName': user.brokerName,
      'brokerId': user.selectedAccount?.brokerId
    })
    window.addEventListener('focus', function() {
      // fetchMyInformation(user.name, user.accounts.data, user.selectedAccount)
    })
  }

  toggleMenu = () => {
    const sidePanel = document.getElementById("side-panel")!
    sidePanel.className = (sidePanel.className === "collapsed") ? "" : "collapsed"
  }

  render() {
    // const { newOrder, loginModalShown, accountsModalShown, page, fullScreenChart } = this.props

    if (isMobile.any()) return <DownloadAppBanner />

    return <DesktopDownloadAppBanner />

    // return (
    //   <div {...style} >
    //     <div id="side-panel">
    //       <UserPanel page={page} />
    //       <div className="menu-btn" onClick={this.toggleMenu}>
    //         <ArrowLeftIcon className="icon"/>
    //       </div>
    //     </div>
    //     {!fullScreenChart && <div className="content">
    //       {this.props.children}
    //     </div>}
    //     {newOrder.modalShown && <NewOrderModal />}
    //     {fullScreenChart && <FullScrenChartPanel />}
    //     {loginModalShown && <LoginModal />}
    //     {accountsModalShown && <AccountsModal />}
    //   </div>
    // );
  }
};

// const style = css({
//   msOverflowStyle: 'none',
//   MozOverflowStyle: 'none',
//   float: 'left',
//   display: 'flex',
//   flexDirection: 'row',

//   '& > #side-panel': {
//     zIndex: 400,
//     width: '200px',
//     position: 'absolute',
//     top: 0,
//     bottom: 0,
//     right: 0,
//     left: 0,
//     display: 'flex',

//     '@media(max-width: 900px)': {
//       zIndex: 1000,
//     }
//   },

//   '& > #side-panel.collapsed': {
//     '@media(max-width: 900px)': {
//       left: '-182px',
//     }
//   },

//   '& > #side-panel > .menu-btn': {
//     display: 'none',
//     width: '18px',
//     height: '100%',
//     cursor: 'pointer',
//     backgroundColor: Colors.middleViolet,
//     borderColor: Colors.darkLineViolet,
//     borderStyle: 'none solid none none',
//     borderWidth: '0 1pt 0 0',

//     '@media(max-width: 900px)': {
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center'
//     },
//   },

//   '& > #side-panel > .menu-btn:hover': {
//     backgroundColor: Colors.darkLineViolet,
//   },

//   '& > #side-panel > .menu-btn > .icon': {
//     width: '8px',
//     height: 'auto'
//   },

//   '& > #side-panel.collapsed > .menu-btn > .icon': {
//     transform: 'scaleX(-1)'
//   },

//   '& > .content': {
//     display: 'flex',
//     position: 'absolute',
//     width: 'calc(100% - 200px)',
//     top: 0,
//     bottom: 0,
//     right: 0,
//     left: '200px',

//     '@media(max-width: 900px)': {
//       left: '18px',
//       width: 'calc(100% - 18px)',
//     },
//   }
// })

export default connect(mapStateToProps, mapDispatchToProps)(App)
